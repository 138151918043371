import { colors } from 'constants/colors';
import styled from 'styled-components';

export const StyledInstrumentSelector = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const InstrumentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  border: 1px solid ${colors['magenta-100']};
  border-radius: 5px;
  padding: 0rem 0.5rem;

  label {
    width: 100%;
  }
`;

export const InstrumentSelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
