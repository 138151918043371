import { MuiThemeProvider, Theme } from '@material-ui/core';
import React from 'react';
import { ThemeProvider } from 'styled-components';

interface CombinedThemeProviderProps {
  theme: Theme;
  children?: React.ReactNode;
}

export function CombinedThemeProvider({
  theme,
  children,
}: CombinedThemeProviderProps) {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  );
}
