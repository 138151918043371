import { colors } from 'constants/colors';
import { css } from 'styled-components';

export const smallCopy = css`
  font-size: 0.75rem;
  font-weight: 300;
  margin-bottom: ${(p) => p.theme.spacing(1.25)}px;
`;

export const heroBaseStyle = css`
  position: relative;
  background-color: ${colors.richBlue};
  border-bottom: 4px ${colors.magenta} solid;
  padding: ${(p) => p.theme.spacing(2.5, 3.75)};
  background-position: center;
  background-size: cover;

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(5)}px;
  }
`;
