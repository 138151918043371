import { FormHelperText } from '@material-ui/core';
import { TextField } from 'components/Form/TextField';
import styled from 'styled-components';

export const RadiosWrapper = styled.div`
  width: 100%;
  display: grid;
  gap: 0.75rem;
  margin: 1rem 0;
`;

interface StyledTextFieldProps {
  $noMargin?: boolean;
  $fullWidth?: boolean;
}

export const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : 'auto')};
  max-width: 400px;
  margin-bottom: ${({ $noMargin }) => ($noMargin ? '0' : '1rem')};
`;

StyledTextField.defaultProps = {
  $noMargin: false,
  $fullWidth: false,
};

export const DetailsFieldLabelWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  gap: 1rem;
`;

export const ReferralInputWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-items: baseline;
  gap: 0.5rem;
  margin-top: 1rem;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    grid-template-columns: 3fr 1fr 1fr;
    gap: 1rem;
    margin-top: 0;
  }
`;

export const RootErrorMessage = styled(FormHelperText)`
  font-size: 1rem;
`;

export const TaxReclaimLabelContent = styled.span`
  font-weight: normal;
`;
