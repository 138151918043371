import { InstrumentSelectorAlignment } from 'components/InstrumentSelector/InstrumentSelector';
import { FontStyle, TextNormal } from 'components/design-system/Text/Text';
import * as format from 'formatting';
import { Instrument } from 'generated/graphql';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { DetailsBox } from '../DetailsBox';
import { Document } from '../Document';
import { ShareClassesBox } from '../ShareClassesBox';
import { DocumentType } from '../Slices/_shared/Types';
import {
  DetailsContainer,
  DocumentsContainer,
  DocumentsWrapper,
  InformationContainer,
  NewTitle,
  Title,
} from '../Styles/FundDetails.style';

interface InformationSliceProps {
  asset: AssetQueryAsset;
  selectedIsin: string;
  informationDocumentLong: string;
  informationDocumentShort: string;
  handleInstrumentChange: (isin: string) => void;
}

export function InformationTab({
  asset,
  selectedIsin,
  informationDocumentLong,
  informationDocumentShort,
  handleInstrumentChange,
}: InformationSliceProps) {
  const currentInstrument = asset?.instruments?.nodes?.find(
    (i) => i.isin === selectedIsin
  );

  const instrumentHasFeDate =
    currentInstrument?.valuationSummary?.lastUpdatedUtc ?? false;

  const documents: Array<DocumentType> = [];

  if (currentInstrument?.kiidDocumentUrl) {
    documents.push({
      title: `${informationDocumentLong} (${informationDocumentShort})`,
      url: currentInstrument?.kiidDocumentUrl,
    });
  }

  if (currentInstrument?.factsheetUrl) {
    documents.push({
      title: 'Factsheet',
      url: currentInstrument?.factsheetUrl,
    });
  }

  const sdrLabel = asset?.tags.nodes.find(
    (tag) => tag.categoryCode === 'SDR' && tag.code !== 'SDROUTOFSCOPE'
  );
  const showSdrDocument = asset?.sdrConsumerFacingDisclosureUrl;

  if (showSdrDocument) {
    documents.push({
      name: 'SDR Disclosure',
      title: `${sdrLabel?.name ?? 'Sustainability'} Consumer Facing Disclosure`,
      url: asset.sdrConsumerFacingDisclosureUrl!,
    });
  }

  return (
    <InformationContainer>
      <Title>Key information</Title>

      {!asset?.isTargetDateFund && (
        <ShareClassesBox
          $align={InstrumentSelectorAlignment.left}
          instruments={asset?.instruments.nodes as Instrument[]}
          value={selectedIsin}
          onChange={handleInstrumentChange}
        />
      )}

      {instrumentHasFeDate && (
        <DetailsContainer>
          <DetailsBox
            asset={asset}
            isin={selectedIsin}
            instrumentType={currentInstrument?.instrumentType || ''}
            kiidDocumentUrl={currentInstrument?.kiidDocumentUrl || ''}
          />

          <TextNormal $noMargin $fontStyle={FontStyle.italic}>
            Data provided by FE fundinfo, retrieved on{' '}
            {format.date(currentInstrument?.valuationSummary?.lastUpdatedUtc)}.
            Ongoing Charge Figures (OCF) and instrument name provided by Seccl
            Custody Limited. Data marked '*' provided by the asset manager.
          </TextNormal>
          <TextNormal $noMargin $fontStyle={FontStyle.italic}>
            Past performance is not a reliable indicator of future results. The
            value of an investment, and the income from it, can fall as well as
            rise. You could get back less than originally invested.
          </TextNormal>
        </DetailsContainer>
      )}
      <DocumentsContainer>
        {documents && (
          <>
            <NewTitle>Documents</NewTitle>

            <DocumentsWrapper>
              {documents.map((document) => {
                return (
                  <Document
                    key={document.url}
                    title={document.title}
                    url={document.url}
                  />
                );
              })}
            </DocumentsWrapper>
            <TextNormal $noMargin $fontStyle={FontStyle.italic}>
              {informationDocumentLong} ({informationDocumentShort}) provided by
              Seccl Custody Limited. Factsheets provided by FE fundinfo.
              {showSdrDocument &&
                ' SDR disclosure provided by the asset manager.'}
            </TextNormal>
          </>
        )}
      </DocumentsContainer>
    </InformationContainer>
  );
}
