import { Alert } from '@material-ui/lab';
import { StyledLink } from 'components/design-system/Link';
import React from 'react';

interface ServerErrorProps {
  isVisible?: boolean;
}

export function ServerError({ isVisible = false }: ServerErrorProps) {
  return isVisible ? (
    <Alert severity="error">
      Sorry, it looks like there was a problem with that request. Please try
      again later, or{' '}
      <StyledLink href="mailto:talktotillit@tillitinvest.com">
        contact support
      </StyledLink>
      .
    </Alert>
  ) : null;
}
