import { Container, Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const DarkHeroContainer = styled(Container)`
  position: relative;
  background-color: ${colors.richBlack};
  padding: 0;
`;

export const DarkIntroContainerInner = styled(Container)`
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${(p) => p.theme.spacing(0, 2.5, 2.5, 2.5)};

  ${(p) => p.theme.breakpoints.up('md')} {
    padding: ${(p) => p.theme.spacing(0, 5, 5, 5)};
  }
`;

export const DarkTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.125rem;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 60px;

  ${(p) => p.theme.breakpoints.up('sm')} {
    width: 80%;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    width: 60%;
  }
`;

export const DarkFundTitle = styled(Typography)`
  font-weight: 300;
  color: ${colors.white};
  font-size: 2.625rem;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    font-size: 3.25rem;
  }
`;
