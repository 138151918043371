import { tillitFetch } from 'api/tillitFetch';
import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { UploadField } from 'components/Form/UploadFieldV2';
import { H5 } from 'components/design-system/Heading/Heading';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  SkipButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { dashboardPath } from 'paths';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as Yup from 'yup';
import { TestFunction } from 'yup';

const PreformattedText = styled(StepIntroductionTypography)`
  white-space: pre-line;
`;

const UploadControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
`;

interface FilePickerStepProps {
  requestId: string;
  title?: string;
  suppressLeadInText?: boolean;
  typeFriendly: string;
  welcomePromptMessage?: string;
  reason?: string;
  guidance?: string | null;
  onComplete?: () => void;
  onCancel?: () => void;
}

type UploadFieldDetails = {
  fileName: string;
  isImage: boolean;
  base64: string | undefined;
};

export function FilePickerStep({
  requestId,
  title,
  typeFriendly,
  welcomePromptMessage,
  reason,
  guidance,
  onComplete,
  onCancel,
}: FilePickerStepProps) {
  const [uploadError, setUploadError] = useState(false);
  const [
    uploadedFileData,
    setUploadedFileData,
  ] = useState<UploadFieldDetails>();
  const history = useHistory();

  const MAX_FILE_SIZE_MB = 4;
  const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;
  const MAX_FILE_SIZE_ERROR = `File size must be less than ${MAX_FILE_SIZE_MB}MB`;
  const FILE_TYPE_ERROR_MESSAGE =
    'Supported file types are JPEG, BMP, PNG, TIFF and PDF';
  // That's how Yup expects it
  // eslint-disable-next-line no-template-curly-in-string
  const REQUIRED_MESSAGE = '${path} is required';

  const allowedFileTypes = [
    'image/jpeg',
    'image/bmp',
    'image/png',
    'image/tiff',
    'application/pdf',
  ];
  const allowedFileTypesStr = allowedFileTypes.join(',');

  const fileSelected: TestFunction<FileList, any> = (value) => {
    return value?.length > 0;
  };
  const maxFileSizeTest: TestFunction<FileList, any> = (value) =>
    value[0]?.size <= MAX_FILE_SIZE;
  const fileTypeTest: TestFunction<FileList, any> = (value) =>
    allowedFileTypes.includes(value[0]?.type);

  const documentUploadSchema = Yup.object().shape({
    file: Yup.mixed<FileList>().when(['$isDirty'], {
      is: (isDirty: boolean) => {
        return isDirty;
      },
      then: (s) =>
        s
          .required()
          .test('selected', REQUIRED_MESSAGE, fileSelected)
          .test('max-size', MAX_FILE_SIZE_ERROR, maxFileSizeTest)
          .test('file-type', FILE_TYPE_ERROR_MESSAGE, fileTypeTest),
    }),
  });

  type DocumentUploadFormValues = Yup.Asserts<typeof documentUploadSchema>;

  const onSubmit = async (data: DocumentUploadFormValues, isDirty: boolean) => {
    if (isDirty) {
      const formData = new FormData();
      formData.append('file', data.file![0]);

      const response = await tillitFetch(
        `/profile/documents/forrequest/${requestId}`,
        {
          method: 'PUT',
          body: formData,
        }
      );

      if (response!.status >= 300) {
        setUploadError(true);
        return;
      } else {
        onComplete?.();
      }
    }
  };

  const defaultWelcomePrompt = `We've asked that you upload your ${typeFriendly.toLowerCase()}, and
  you can do that securely here.`;

  const handleOnCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      history.push(dashboardPath);
    }
  };

  const handleUploadFieldUpdate = useCallback(
    (
      name: string,
      fileName: string,
      isImage: boolean,
      base64: string | undefined,
      shouldClear?: boolean
    ) => {
      if (shouldClear) {
        setUploadedFileData(undefined);
      } else {
        setUploadedFileData({ fileName, isImage, base64 });
      }
    },
    []
  );

  const defaultTitle = `Upload your ${typeFriendly}`;

  return (
    <StepContainer>
      <Form<DocumentUploadFormValues>
        onSubmit={onSubmit}
        schema={documentUploadSchema}
      >
        <StepContent width={StepContentWidth.extraWide}>
          <StepTitle>{title ?? defaultTitle}</StepTitle>
          <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
            <StepIntroductionTypography>
              {welcomePromptMessage ?? defaultWelcomePrompt}
            </StepIntroductionTypography>
            {reason && (
              <>
                <H5>Why are we asking for this?</H5>
                <PreformattedText>{reason}</PreformattedText>
              </>
            )}
            {guidance && (
              <>
                <H5>Guidance</H5>
                <PreformattedText>{guidance}</PreformattedText>
              </>
            )}
          </StepIntroduction>

          <ServerError isVisible={uploadError} />

          <UploadControlContainer>
            <UploadField
              name="file"
              label={`Select ${typeFriendly}`}
              inputProps={{ accept: allowedFileTypesStr }}
              updateParent={handleUploadFieldUpdate}
            />
          </UploadControlContainer>
        </StepContent>
        <StepActions>
          <FormState>
            {({ isSubmitting }) => (
              <>
                <StepButton
                  type="submit"
                  className="magenta"
                  disabled={isSubmitting || !uploadedFileData}
                >
                  Continue
                </StepButton>
                <SkipButton
                  variant="outlined"
                  className="richBlue"
                  disabled={isSubmitting}
                  onClick={handleOnCancel}
                >
                  I'll do this later
                </SkipButton>
              </>
            )}
          </FormState>
        </StepActions>
      </Form>
    </StepContainer>
  );
}
