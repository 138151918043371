import { Option } from 'components/Form/Select';
import { SelectField, SelectFieldProps } from 'components/Form/SelectField';
import { Title } from 'generated/graphql';

interface TitleFieldProps extends Omit<SelectFieldProps, 'options'> {}

export function TitleField(props: TitleFieldProps) {
  const titleOptions: Option[] = [
    { value: Title.Dr, label: 'Dr' },
    { value: Title.Miss, label: 'Miss' },
    { value: Title.Mr, label: 'Mr' },
    { value: Title.Mrs, label: 'Mrs' },
    { value: Title.Ms, label: 'Ms' },
    { value: Title.Professor, label: 'Professor' },
  ];

  return <SelectField {...props} options={titleOptions} />;
}
