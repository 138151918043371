import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, DialogActions, FormControlLabel } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ArrowButton } from 'components/Button/ArrowButton/ArrowButton';
import { Chip } from 'components/design-system/Chip/Chip';
import { H4, H5, TextAlign } from 'components/design-system/Heading/Heading';
import { AmountInput } from 'components/design-system/Inputs/AmountInput/AmountInput';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  SkipButton,
  StepButton,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { Text, TextSmall } from 'components/design-system/Text/Text';
import {
  Pill,
  PillContainer,
  Section,
} from 'components/feature/PortfolioBuilder/AddToBasket/AddToBasketDialog.style';
import { currencyFull } from 'formatting';
import {
  PaymentMethodInterfaceType,
  PaymentMethodKind,
  PaymentMethodStatus,
  WrapperType,
  useRecurringTransactionsQuery,
} from 'generated/graphql';
import { getShortNameForWrapperType } from 'helpers/accountHelpers';
import { pensionTaxReliefHelpers } from 'helpers/pensionTaxReliefHelpers';
import { isOpenBankingPaymentMethod } from 'helpers/type-guards';
import { amount } from 'helpers/yupExtensions';
import { useToggle } from 'hooks/useFeatureToggle';
import { useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import Reference from 'yup/lib/Reference';
import { DepositFormVariant } from './DepositFormVariant';
import { TrueLayerTermsOfService } from './TrueLayerFlow/TrueLayerTermsOfService';
import {
  BufferActions,
  BufferWrapper,
  ProviderLogoContainer,
  StackedButtonsContainer,
} from './styles/Deposit.styles';

type FormValues = {
  amount: number | null;
};

const depositSchema = Yup.object().shape({
  amount: amount()
    .label('Amount')
    .typeError('Please enter a valid number')
    .nullable()
    .required()
    .min(Yup.ref('$minAmount') as Reference<number>)
    .max(Yup.ref('$maxAmount') as Reference<number>),
  autoInvest: Yup.boolean().label('Auto invest'),
});

export interface DepositFormInputs extends Yup.TypeOf<typeof depositSchema> {}
export interface DepositFormValues extends Yup.Asserts<typeof depositSchema> {}

export interface AmountStepProps {
  accountId: string;
  wrapperType: WrapperType;
  cashBalance: number;
  minAmount: number;
  maxAmount: number;
  defaultAmount?: number | null;
  showServerError?: boolean;
  onSubmit: (values: DepositFormValues) => Promise<void> | void;
  paymentMethods: Array<PaymentMethodInterfaceType>;
  variant: DepositFormVariant;
  displayBuffer?: boolean;
  onSkipStep?: () => void;
  isCheckout?: boolean;
}

export function AmountStep({
  accountId,
  wrapperType,
  cashBalance,
  minAmount,
  maxAmount,
  defaultAmount = null,
  showServerError = false,
  onSubmit,
  paymentMethods,
  variant,
  displayBuffer = false,
  onSkipStep,
  isCheckout = false,
}: AmountStepProps) {
  const [showAutoInvestOptionEnabled] = useToggle(
    'global-isa-gia-auto-invest-option'
  );

  const recurringTransactionsQuery = useRecurringTransactionsQuery(
    { accountId },
    { enabled: showAutoInvestOptionEnabled?.enabled ?? false }
  );
  const hasRecurringOrder =
    (recurringTransactionsQuery.data?.recurringTransactions?.orders?.length ??
      0) > 0;

  const buffers = [1, 2, 5, 10];
  const [activeBuffer, setActiveBuffer] = useState<number | null>(null);
  const [bufferVisibility, setBufferVisibility] = useState(displayBuffer);
  const maxDefaultValue =
    defaultAmount && defaultAmount > maxAmount ? maxAmount : defaultAmount;

  const methods = useForm<FormValues>({
    resolver: yupResolver(depositSchema),
    defaultValues: {
      amount: maxDefaultValue,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    context: { minAmount, maxAmount },
  });

  const { setValue, watch } = methods;
  const openBankingPaymentMethod = paymentMethods.find(
    isOpenBankingPaymentMethod
  );

  const openBankingAvailable =
    openBankingPaymentMethod?.status === PaymentMethodStatus.Enabled;

  const handleAddBuffer = (buffer: number) => {
    if (!defaultAmount) return;
    const isActive = buffer === activeBuffer;
    setActiveBuffer(isActive ? null : buffer);
    const newValue = parseFloat(
      (defaultAmount! + (defaultAmount! * buffer) / 100).toFixed(2)
    );
    setValue('amount', isActive ? maxDefaultValue : newValue);
  };

  const handleAmountChange = () => {
    setActiveBuffer(null);
  };

  const getBufferValue = (buffer: number) => {
    const overMaxAmount =
      maxAmount <= maxDefaultValue! + (maxDefaultValue! * buffer) / 100;
    if (buffer === 1 && overMaxAmount) setBufferVisibility(false);
    return overMaxAmount;
  };

  const watchAmount = Number(watch('amount'));

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        {isCheckout ? (
          <>
            <StepTitle>Add cash</StepTitle>
            <StepIntroduction $width={StepIntroductionWidth.xxWide}>
              {bufferVisibility && (
                <StepIntroductionTypography textAlign="center">
                  Based on the cash currently available in your account and the
                  expected value to be raised from the SELL orders, we estimate
                  a shortfall of:
                </StepIntroductionTypography>
              )}
              {variant !== DepositFormVariant.NewAccount && (
                <StepIntroductionTypography>
                  Cash balance: {currencyFull(cashBalance)}
                </StepIntroductionTypography>
              )}
            </StepIntroduction>
          </>
        ) : (
          <>
            {wrapperType && wrapperType !== WrapperType.Unknown && (
              <PillContainer>
                <Pill>{getShortNameForWrapperType(wrapperType)}</Pill>
              </PillContainer>
            )}

            <H4>Add cash</H4>
            {bufferVisibility && (
              <Text $textAlign={TextAlign.center}>
                Based on the cash currently available in your account and the
                expected value to be raised from the SELL orders, we estimate a
                shortfall of:
              </Text>
            )}
            {variant !== DepositFormVariant.NewAccount && (
              <TextSmall>Cash balance: {currencyFull(cashBalance)}</TextSmall>
            )}
          </>
        )}

        <ServerError isVisible={showServerError} />
        <Section>
          <AmountInput
            id="amount"
            name="amount"
            helpText={`Minimum deposit: ${currencyFull(
              minAmount
            )}, Maximum deposit: ${currencyFull(maxAmount)}`}
            onChange={handleAmountChange}
            ref={methods.register}
            isLarge={isCheckout}
            error={methods.errors.amount}
          />
          {variant === DepositFormVariant.Checkout && defaultAmount && (
            <MinimumCashBalanceWarning
              amount={watchAmount}
              warningThreshold={maxDefaultValue!}
            />
          )}
        </Section>
        {!isCheckout &&
          showAutoInvestOptionEnabled?.enabled &&
          hasRecurringOrder &&
          [WrapperType.Gia, WrapperType.Isa].includes(wrapperType) && (
            <FormControlLabel
              control={
                <Checkbox
                  name="autoInvest"
                  value="true"
                  inputRef={methods.register}
                />
              }
              label={
                <>
                  <Text $noMargin>
                    Auto invest this into your regular investment plan
                  </Text>
                  <TextSmall $noMargin>
                    We'll show you the details on the next step
                  </TextSmall>
                </>
              }
            />
          )}

        {wrapperType === WrapperType.Sipp && (
          <>
            {typeof watchAmount === 'number' && watchAmount > 0 ? (
              <TextSmall $noMargin>
                We'll automatically claim basic rate tax relief of{' '}
                {currencyFull(pensionTaxReliefHelpers(watchAmount))} for this
                cash deposit.
              </TextSmall>
            ) : (
              <TextSmall $noMargin>
                We'll automatically claim basic rate tax relief for this cash
                deposit.
              </TextSmall>
            )}
          </>
        )}

        {bufferVisibility && (
          <BufferWrapper>
            <BufferActions>
              {buffers.map((buffer) => (
                <Chip
                  key={buffer}
                  onClick={() => handleAddBuffer(buffer)}
                  disabled={getBufferValue(buffer)}
                  active={activeBuffer === buffer}
                >
                  +{buffer}%
                </Chip>
              ))}
            </BufferActions>
            <H5 $textAlign={TextAlign.center} style={{ fontStyle: 'italic' }}>
              Want to add a buffer?
            </H5>
            <Text>
              If there is a significant change in the price of any of the funds
              in your order, then there may be a shortfall of cash, which would
              prevent the BUY orders from going ahead. To reduce the risk of
              this happening, you can add a buffer to your deposit.
            </Text>
          </BufferWrapper>
        )}

        {openBankingAvailable && <AmountWarning amount={watchAmount} />}

        <DialogActions disableSpacing>
          {openBankingPaymentMethod?.everSucceeded && (
            <>
              <TrueLayerTermsOfService />
              {openBankingPaymentMethod?.providerLogoUrl && (
                <ProviderLogoContainer>
                  <img
                    src={openBankingPaymentMethod.providerLogoUrl}
                    alt="Bank logo"
                  />
                </ProviderLogoContainer>
              )}
            </>
          )}

          <Actions
            openBankingAvailable={openBankingAvailable}
            bankName={
              (openBankingPaymentMethod?.everSucceeded &&
                openBankingPaymentMethod?.providerDisplayName) ||
              'my bank'
            }
            variant={variant}
            onSkipStep={onSkipStep}
            onSubmit={onSubmit}
          />
        </DialogActions>
      </form>
    </FormProvider>
  );
}

interface ActionsProps {
  bankName?: string;
  openBankingAvailable: boolean;
  onSubmit: (data: DepositFormValues, paymentMethod: PaymentMethodKind) => void;
  variant: DepositFormVariant;
  onSkipStep?: () => void;
}

function Actions({
  bankName,
  openBankingAvailable,
  onSubmit,
  variant,
  onSkipStep,
}: ActionsProps) {
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useFormContext<DepositFormValues>();

  const handleClick = (paymentMethod: PaymentMethodKind) => {
    handleSubmit((data) => onSubmit(data, paymentMethod))();
  };

  return (
    <StackedButtonsContainer>
      {openBankingAvailable ? (
        <>
          <StepButton
            className="magenta"
            disabled={isSubmitting}
            onClick={() => handleClick(PaymentMethodKind.OpenBanking)}
          >
            Pay with {bankName}
          </StepButton>
          <ArrowButton
            disabled={isSubmitting}
            onClick={() => handleClick(PaymentMethodKind.ManualBankTransfer)}
          >
            Make a manual transfer instead
          </ArrowButton>
        </>
      ) : (
        <StepButton
          className="magenta"
          disabled={isSubmitting}
          value={PaymentMethodKind.ManualBankTransfer}
          onClick={() => handleClick(PaymentMethodKind.ManualBankTransfer)}
        >
          Make a manual transfer
        </StepButton>
      )}
      {variant === DepositFormVariant.NewAccount && onSkipStep && (
        <>
          <SkipButton
            variant="outlined"
            className="richBlue"
            disabled={isSubmitting}
            onClick={() => onSkipStep()}
          >
            Skip
          </SkipButton>
        </>
      )}
    </StackedButtonsContainer>
  );
}

interface MinimumCashBalanceWarningProps {
  warningThreshold: number;
  amount: number | null;
}

function MinimumCashBalanceWarning({
  warningThreshold,
  amount,
}: MinimumCashBalanceWarningProps) {
  return amount && amount < warningThreshold ? (
    <Alert severity="warning">
      The amount entered will not cover the order plus the minimum cash balance.
    </Alert>
  ) : null;
}

interface AmountWarningProps {
  amount: number | null;
}

function AmountWarning({ amount }: AmountWarningProps) {
  const openBankingThreshold = 10000;

  return amount && amount > openBankingThreshold ? (
    <Alert severity="warning">
      We recommend making transfers over{' '}
      {currencyFull(openBankingThreshold, false)} using a manual transfer due to
      some OpenBanking limits.
    </Alert>
  ) : null;
}
