import { Card, CardProps } from 'components/design-system/Card/Card';
import { colors } from 'constants/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

type CardActionOptionProps = CardProps & {
  $isActive: boolean;
};

export const CardActionOption = styled(Card)<CardActionOptionProps>`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  ${({ $isActive }) =>
    $isActive && {
      background: colors['magenta-25'],
      borderColor: colors['magenta-200'],
    }}
`;
