import styled from 'styled-components';

export const SdrWrapper = styled.div`
  margin: 1rem 0 0.5rem;
`;

export const SdrImg = styled.img`
  max-height: 2rem;
  filter: grayscale(0.5);
`;
