import { CashTransactions } from 'components/CashTransaction/CashTransaction';
import { InfoPopover } from 'components/Popover/InfoPopover/InfoPopover';
import { QueryState } from 'components/QueryState';
import {
  ColumnDefinition,
  ResponsiveTable,
} from 'components/Tables/ResponsiveTable/ResponsiveTable';
import * as format from 'formatting';
import {
  CashTransactionStatus,
  CashTransactionSubType,
  CashTransactionsQuery,
  useCashTransactionsQuery,
} from 'generated/graphql';
import { orderBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { AccountQueryAccount } from 'types/graphqlTypes';
import {
  AmountCell,
  TransactionsContainer,
} from './CashTransactionTable.style';

type Transaction = Exclude<
  CashTransactionsQuery['cashTransactionsByAccount'],
  null | undefined
>[number];

export interface PureCashTransactionTableProps {
  data: Array<Transaction>;
  dark?: boolean;
}

export function PureCashTransactionTable({
  data,
  dark = false,
}: PureCashTransactionTableProps) {
  const columns = useMemo(() => {
    return [
      {
        name: '',
        key: 'status',
        renderValue: (value: CashTransactionStatus) =>
          value === CashTransactionStatus.Pending && (
            <InfoPopover color="primary" size="small" content="In progress" />
          ),
      },
      {
        name: 'Type',
        key: 'description',
        align: 'left',
        renderValue: (value: string, data: Transaction) =>
          data?.transactionSubType === CashTransactionSubType.Dividend
            ? `${value} - ${data.linkedAssetName}`
            : value,
      },
      {
        name: 'Amount',
        key: 'amount',
        align: 'right',
        renderValue: (value: number) => (
          <AmountCell>{format.currencyFull(value)}</AmountCell>
        ),
      },
      {
        name: 'Date',
        key: 'transactionDate',
        align: 'right',
        renderValue: (value: string) => format.date(value),
      },
    ] as Array<ColumnDefinition<Transaction>>;
  }, []);

  const mobileComponent = useCallback(
    (row: Transaction) => (
      <CashTransactions
        transactionLinkedAsset={row.linkedAssetName || undefined}
        transactionDescription={row.description}
        transactionAmount={row.amount}
        transactionDate={row.transactionDate}
        transactionStatus={row.status}
      />
    ),
    []
  );

  return (
    <TransactionsContainer>
      <ResponsiveTable
        columns={columns}
        data={data}
        noDataMessage="You have no cash transactions."
        renderMobile={mobileComponent}
        dark={dark}
      />
    </TransactionsContainer>
  );
}

export interface CashTransactionTableProps {
  dark?: boolean;
  account: AccountQueryAccount;
}

export function CashTransactionTable({
  dark = false,
  account,
}: CashTransactionTableProps) {
  const cashTransactionQuery = useCashTransactionsQuery(
    {
      id: account?.id!,
    },
    { enabled: !!account?.id }
  );

  const cashTransactions = useMemo(() => {
    if (cashTransactionQuery.data?.cashTransactionsByAccount) {
      return orderBy(
        cashTransactionQuery.data?.cashTransactionsByAccount,
        (t) => new Date(t.transactionDate || t.createdDate),
        'desc'
      );
    } else {
      return [];
    }
  }, [cashTransactionQuery.data?.cashTransactionsByAccount]);

  return (
    <QueryState {...cashTransactionQuery}>
      {() => <PureCashTransactionTable data={cashTransactions} dark={dark} />}
    </QueryState>
  );
}
