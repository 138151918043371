import useIsSippAgeRestricted from 'components/Dashboard/Accounts/Account/hooks/useIsSippAgeRestricted';
import { QueryState } from 'components/QueryState';
import { ConfirmReferralStep } from 'components/feature/signIn/steps/ConfirmReferralStep/ConfirmReferralStep';
import { GaEventNames, GaProperties } from 'constants/gaConstants';
import { AnimatePresence } from 'framer-motion';
import {
  Eligibility,
  IllustrationQuery,
  UserProfileQuery,
  WrapperType,
  useAccountEligibilitiesQuery,
  useIllustrationQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import { useGetEmployerCode } from 'hooks/useGetEmployerCode';
import { dashboardPensionPath } from 'paths';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce } from 'usehooks-ts';
import { NationalInsuranceNumberStep } from '../openAccount/steps/NationalInsuranceNumberStep';
import { Wrapper } from './OpenSippAsSecondAccountForm.style';
import { AboutYourOtherPensionsStep } from './steps/AboutYourOtherPensionsStep/AboutYourOtherPensionsStep';
import { AutoEscalationStep } from './steps/AutoEscalationStep/AutoEscalationStep';
import { BeneficiariesStep } from './steps/BeneficiariesStep/BeneficiariesStep';
import { CannotOpenStep } from './steps/CannotOpenStep/CannotOpenStep';
import { DeclarationStep } from './steps/DeclarationStep';
import { EmployerContributionStep } from './steps/EmployerContributionStep/EmployerContributionStep';
import { FundingChoicesStep } from './steps/FundingChoices/FundingChoicesStep';
import { FundingChoicesLegacyStep } from './steps/FundingChoicesLegacy/FundingChoicesLegacy';
import { IllustrationStep } from './steps/IllustrationStep/IllustrationStep';
import { IntroductionStep } from './steps/IntroductionStep';
import { MoreAboutYouStep } from './steps/MoreAboutYouStep/MoreAboutYouStep';
import { NextStepsStep } from './steps/NextSteps/NextSteps';
import { ReviewYourDecision } from './steps/ReviewYourDecision/ReviewYourDecision';
import { SuccessStep } from './steps/SuccessStep/SuccessStep';
import { WhatYourInvestedInStep } from './steps/WhatYourInvestedIn/WhatYourInvestedInStep';
import { YourEmployerStep } from './steps/YourEmployerStep/YourEmployerStep';

enum OpenPensionFormSteps {
  ReferralCode = 'ReferralCode',
  Introduction = 'Introduction',
  NationalInsuranceNumber = 'NationalInsuranceNumber',
  Declaration = 'Declaration',
  MoreAboutYou = 'MoreAboutYou',
  Beneficiaries = 'Beneficiaries',
  AboutYourOtherPensions = 'AboutYourOtherPensions',
  YourEmployer = 'YourEmployer',
  AutoEscalation = 'AutoEscalation',
  EmployerContribution = 'EmployerContribution',
  FundingChoices = 'FundingChoices',
  Illustration = 'Illustration',
  ReviewYourDecision = 'ReviewYourDecision',
  WhatYouAreInvestedInStep = 'WhatYouAreInvestedInStep',
  CannotOpen = 'CannotOpen',
  NextSteps = 'NextSteps',
  SuccessStep = 'SuccessStep',
}

const fullStepSequence = [
  OpenPensionFormSteps.ReferralCode,
  OpenPensionFormSteps.Introduction,
  OpenPensionFormSteps.NationalInsuranceNumber,
  OpenPensionFormSteps.MoreAboutYou,
  OpenPensionFormSteps.AboutYourOtherPensions,
  OpenPensionFormSteps.EmployerContribution,
  OpenPensionFormSteps.YourEmployer,
  OpenPensionFormSteps.FundingChoices,
  OpenPensionFormSteps.Illustration,
  OpenPensionFormSteps.ReviewYourDecision,
  OpenPensionFormSteps.WhatYouAreInvestedInStep,
  OpenPensionFormSteps.AutoEscalation,
  OpenPensionFormSteps.Beneficiaries,
  OpenPensionFormSteps.Declaration,
  OpenPensionFormSteps.NextSteps,
  OpenPensionFormSteps.SuccessStep,
];

type UserProfile = NonNullable<UserProfileQuery['userProfile']>;

function useSelectFlow(userProfile: UserProfile) {
  const ninoProvided = userProfile.nationalInsuranceNumberTokenised;
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const hasReferralCode = params.has('referralCode');

  const [autoEscalationToggle] = useToggle('global-auto-escalation');
  const [defaultFundOptOutToggle] = useToggle('global-default-fund-opt-out');
  const [improvedContributionsToggle] = useToggle(
    'global-improved-contributions'
  );
  const [actionToggle] = useToggle('global-actions');
  const hasEmployer = (userProfile?.employments || []).length > 0;

  const [flow] = useState(
    fullStepSequence.filter((step) => {
      if (step === OpenPensionFormSteps.NationalInsuranceNumber) {
        return !ninoProvided;
      }

      if (step === OpenPensionFormSteps.ReferralCode) {
        return hasReferralCode;
      }

      if (step === OpenPensionFormSteps.EmployerContribution) {
        return improvedContributionsToggle?.enabled;
      }

      if (step === OpenPensionFormSteps.YourEmployer) {
        return !hasEmployer;
      }

      if (step === OpenPensionFormSteps.AutoEscalation) {
        return (
          autoEscalationToggle?.enabled && (hasEmployer || hasReferralCode)
        );
      }

      if (step === OpenPensionFormSteps.WhatYouAreInvestedInStep) {
        return defaultFundOptOutToggle?.enabled;
      }

      if (step === OpenPensionFormSteps.NextSteps) {
        return !actionToggle?.enabled;
      }

      if (step === OpenPensionFormSteps.SuccessStep) {
        return actionToggle?.enabled;
      }

      return true;
    })
  );

  return flow;
}

export function OpenSippAsSecondAccountForm() {
  const userProfileQuery = useUserProfileQuery();
  const accountEligibilitiesQuery = useAccountEligibilitiesQuery();

  const sippEligibility = accountEligibilitiesQuery.data?.accountEligibilities?.find(
    (acc) => acc?.wrapperType === WrapperType.Sipp
  );
  const isSippAgeRestricted = useIsSippAgeRestricted({
    canOpenSipp:
      sippEligibility?.eligibility === Eligibility.CannotOpen ? false : true,
  });

  return (
    <Wrapper isTallForm={true}>
      <QueryState {...userProfileQuery}>
        {({ data }) => (
          <QueryState {...accountEligibilitiesQuery}>
            {() => (
              <FormSteps
                userProfile={data?.userProfile!}
                canOpen={
                  sippEligibility?.eligibility !== Eligibility.CannotOpen &&
                  !isSippAgeRestricted
                }
              />
            )}
          </QueryState>
        )}
      </QueryState>
    </Wrapper>
  );
}

interface FormStepsProps {
  userProfile: UserProfile;
  canOpen: boolean;
}

function FormSteps({ userProfile, canOpen }: FormStepsProps) {
  const history = useHistory();
  const { referralCode } = useGetEmployerCode();
  const [autoEscalationToggle] = useToggle('global-auto-escalation');
  const [stepSequence, setStepSequence] = useState(useSelectFlow(userProfile));
  const [activeStep, setActiveStep] = useState<
    OpenPensionFormSteps | undefined
  >(!canOpen ? OpenPensionFormSteps.CannotOpen : undefined);
  const [improvedContributionsToggle] = useToggle(
    'global-improved-contributions'
  );

  const hasEmployer = (userProfile?.employments || []).length > 0;

  const illustrationQuery = useIllustrationQuery(undefined, {
    enabled: userProfile ? true : false,
    onSuccess: (data: IllustrationQuery) => {
      if (activeStep !== undefined) {
        return;
      }

      const illustrationAccepted =
        data.userProfile?.pensionDetails?.illustrationAccepted ?? false;

      setActiveStep(
        illustrationAccepted
          ? OpenPensionFormSteps.ReviewYourDecision
          : stepSequence[0]
      );
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const handleGoBack = () => {
    const currentStepIndex = stepSequence.indexOf(activeStep!);
    if (currentStepIndex > 0) {
      const previousStep = stepSequence[currentStepIndex - 1];
      setActiveStep(previousStep);
    } else {
      history.goBack();
    }
  };

  useEffectOnce(() => {
    trackGa({
      event: GaEventNames.multiStepFormStart,
      [GaProperties.form]: 'OpenPensionForm',
    });
  });

  const handleProceed = () => {
    const currentStepIndex = stepSequence.indexOf(activeStep!);
    if (currentStepIndex < stepSequence.length - 1) {
      trackGa({
        event: GaEventNames.multiStepFormStep,
        [GaProperties.form]: 'OpenPensionForm',
        [GaProperties.formStep]: activeStep,
      });
      const nextStep = stepSequence[currentStepIndex + 1];
      setActiveStep(nextStep);
    } else {
      trackGa({
        event: GaEventNames.multiStepFormFinish,
        [GaProperties.form]: 'OpenPensionForm',
      });
      history.replace(dashboardPensionPath);
    }
  };

  const navigationHandlers = {
    onProceed: handleProceed,
    onGoBack: handleGoBack,
  };

  return (
    <QueryState {...illustrationQuery}>
      {() => (
        <AnimatePresence>
          {activeStep === OpenPensionFormSteps.CannotOpen && (
            <CannotOpenStep {...navigationHandlers} />
          )}
          {activeStep === OpenPensionFormSteps.ReferralCode && referralCode && (
            <ConfirmReferralStep
              referralCode={referralCode}
              {...navigationHandlers}
            />
          )}
          {activeStep === OpenPensionFormSteps.Introduction && (
            <IntroductionStep {...navigationHandlers} />
          )}
          {activeStep === OpenPensionFormSteps.NationalInsuranceNumber && (
            <NationalInsuranceNumberStep
              {...navigationHandlers}
              source="secondAccountStep"
              canSkip={false}
              userProfile={userProfile}
            />
          )}
          {activeStep === OpenPensionFormSteps.MoreAboutYou && (
            <MoreAboutYouStep {...navigationHandlers} />
          )}
          {activeStep === OpenPensionFormSteps.AboutYourOtherPensions && (
            <AboutYourOtherPensionsStep {...navigationHandlers} />
          )}
          {activeStep === OpenPensionFormSteps.EmployerContribution && (
            <EmployerContributionStep
              {...navigationHandlers}
              hasEmployer={hasEmployer}
            />
          )}
          {activeStep === OpenPensionFormSteps.YourEmployer && (
            <YourEmployerStep
              onGoBack={handleGoBack}
              onProceed={(employeeAdded: boolean = false) => {
                // the step needs to be incepted in the correct order and
                // it is not guaranteed that will be after this current step
                // TODO: find a better pattern for this (have a unified logic from the use effect)
                // or we could also do it just in time
                let newStepsSequence: OpenPensionFormSteps[] = [];
                const insertIndex =
                  stepSequence.indexOf(
                    OpenPensionFormSteps.WhatYouAreInvestedInStep
                  ) + 1;

                if (employeeAdded) {
                  if (autoEscalationToggle?.enabled) {
                    newStepsSequence = [
                      ...stepSequence.slice(0, insertIndex),
                      OpenPensionFormSteps.AutoEscalation,
                      ...stepSequence.slice(insertIndex, stepSequence.length),
                    ];
                  }

                  newStepsSequence = newStepsSequence.filter((step) => {
                    if (step === OpenPensionFormSteps.YourEmployer) {
                      return false;
                    }
                    return true;
                  });

                  setStepSequence(newStepsSequence);
                }

                handleProceed();
              }}
            />
          )}
          {improvedContributionsToggle?.enabled &&
            activeStep === OpenPensionFormSteps.FundingChoices && (
              <FundingChoicesStep
                onProceed={handleProceed}
                onGoBack={() => {
                  setActiveStep(OpenPensionFormSteps.EmployerContribution);
                }}
                hasEmployer={hasEmployer}
              />
            )}
          {!improvedContributionsToggle?.enabled &&
            activeStep === OpenPensionFormSteps.FundingChoices && (
              <FundingChoicesLegacyStep
                onGoBack={handleGoBack}
                onProceed={(employeeAdded: boolean = false) => {
                  // the step needs to be incepted in the correct order and
                  // it is not guaranteed that will be after this current step
                  // TODO: find a better pattern for this (have a unified logic from the use effect)
                  // or we could also do it just in time
                  const insertIndex =
                    stepSequence.indexOf(
                      OpenPensionFormSteps.WhatYouAreInvestedInStep
                    ) + 1;

                  if (employeeAdded && autoEscalationToggle?.enabled) {
                    const newStepsSequence = [
                      ...stepSequence.slice(0, insertIndex),
                      OpenPensionFormSteps.AutoEscalation,
                      ...stepSequence.slice(insertIndex, stepSequence.length),
                    ];
                    setStepSequence(newStepsSequence);
                  }
                  handleProceed();
                }}
              />
            )}
          {activeStep === OpenPensionFormSteps.Illustration && (
            <IllustrationStep {...navigationHandlers} />
          )}
          {activeStep === OpenPensionFormSteps.ReviewYourDecision && (
            <ReviewYourDecision
              {...navigationHandlers}
              illustrationDownloadUrl={
                illustrationQuery.data?.illustration?.downloadUrl ?? undefined
              }
              onStartAgain={() => {
                setActiveStep(OpenPensionFormSteps.MoreAboutYou);
              }}
            />
          )}
          {activeStep === OpenPensionFormSteps.AutoEscalation && (
            <AutoEscalationStep {...navigationHandlers} />
          )}
          {activeStep === OpenPensionFormSteps.WhatYouAreInvestedInStep && (
            <WhatYourInvestedInStep {...navigationHandlers} />
          )}
          {activeStep === OpenPensionFormSteps.Beneficiaries && (
            <BeneficiariesStep {...navigationHandlers} />
          )}
          {activeStep === OpenPensionFormSteps.Declaration && (
            <DeclarationStep
              includeTransferInDeclarations={false}
              variant="open-second-account"
              {...navigationHandlers}
            />
          )}
          {activeStep === OpenPensionFormSteps.NextSteps && <NextStepsStep />}
          {activeStep === OpenPensionFormSteps.SuccessStep && <SuccessStep />}
        </AnimatePresence>
      )}
    </QueryState>
  );
}
