import { CustomButtonV2 } from 'components/design-system/Button/CustomButtonV2';
import { H4 } from 'components/design-system/Heading/Heading';
import {
  Text,
  TextNormal,
  TextSmall,
} from 'components/design-system/Text/Text';
import type { LocalRecurringDeposits } from 'components/feature/autoSaveInvest/AutoSaveInvestContext';
import { colors } from 'constants/colors';
import * as format from 'formatting';
import { currencyFull, day } from 'formatting';
import styled from 'styled-components';
import { RecurringTransactionsQueryRecurringLatestEmployerContribution } from 'types/graphqlTypes';

export interface RegularDepositsSummaryProps {
  recurringTransactionsDeposits: LocalRecurringDeposits[];
  latestEmployerContribution?: RecurringTransactionsQueryRecurringLatestEmployerContribution | null;
  onManageDeposits?: () => void;
}

const ManageDepositWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
    gap: 0;
  }
`;

export const Section = styled.div`
  padding: 0.5rem 0;
  border-top: 1px solid ${colors.midGrey};
  border-bottom: 1px solid ${colors.midGrey};

  & + & {
    border-top: none;
  }
`;

const RegularDepositContainer = styled.div`
  margin-bottom: 1rem;
`;

export const RegularDepositsSummary = ({
  recurringTransactionsDeposits,
  latestEmployerContribution,
  onManageDeposits,
}: RegularDepositsSummaryProps) => {
  const deposit = recurringTransactionsDeposits[0];

  return (
    <>
      <H4>Regular deposits</H4>
      {recurringTransactionsDeposits.length === 0 &&
        !latestEmployerContribution && (
          <Text $noMargin>You do not have any regular deposits set up.</Text>
        )}
      <RegularDepositContainer>
        {latestEmployerContribution && (
          <Section>
            <TextNormal $noMargin>
              Employer contributions -{' '}
              <em>
                <strong>
                  {format.currencyFull(latestEmployerContribution.amount)}
                </strong>{' '}
                per month
              </em>
            </TextNormal>
            <TextSmall $noMargin>
              This is based on what your employer told us they intend to pay
              into your account
            </TextSmall>
          </Section>
        )}

        {recurringTransactionsDeposits?.length > 0 && (
          <Section>
            <TextNormal $noMargin>
              Regular deposit -{' '}
              <em>
                <strong>{currencyFull(deposit.amount!)}</strong> on the{' '}
                {day(deposit.paymentDate!)} of the month
                {!deposit.autoInvest && ' to be held as cash'}
              </em>{' '}
              {deposit.isDeleted && '(remove)'}
              {deposit.isNew && '(new)'}
              {deposit.isEdited && !deposit.isNew && '(changed)'}
            </TextNormal>
            {deposit.isDeleted && (
              <TextSmall $noMargin>
                You'll need to cancel your standing order with your bank
              </TextSmall>
            )}
            {deposit.isNew && (
              <TextSmall $noMargin>
                You'll need to setup a standing order with your bank, we'll give
                you the details you need on the next screen
              </TextSmall>
            )}
            {deposit.isEdited && !deposit.isNew && (
              <TextSmall $noMargin>
                You'll need to change your standing order with your bank, we'll
                give you the details you need on the next screen
              </TextSmall>
            )}
            {!deposit.isNew && !deposit.isDeleted && !deposit.isEdited && (
              <TextSmall $noMargin>
                You should have a standing order for this deposit setup with
                your bank.
              </TextSmall>
            )}
          </Section>
        )}
      </RegularDepositContainer>
      <ManageDepositWrapper>
        {onManageDeposits && (
          <CustomButtonV2
            $color="light"
            $isWide={false}
            $size="small"
            onClick={onManageDeposits}
          >
            Manage deposits
          </CustomButtonV2>
        )}
      </ManageDepositWrapper>
    </>
  );
};
