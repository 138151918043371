import { Container, ContainerProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface PageContainerProps extends ContainerProps {
  noBottomPadding?: boolean | undefined;
}

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1, 7),
    },
    '&.no-bot-pad': {
      paddingBottom: '0',
    },
  },
}));

export function PageContainer(props: PageContainerProps) {
  const classes = useStyles();
  const { noBottomPadding, ...rest } = props;
  return (
    <Container
      maxWidth="sm"
      className={clsx(classes.root, noBottomPadding && 'no-bot-pad')}
      {...rest}
    />
  );
}
