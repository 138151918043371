import { SmallCopy } from 'components/Copy/SmallCopy';
import React from 'react';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { ContentSection } from '../../ContentSection';
import {
  NoContent,
  SubTitle,
  SustainabilityContainer,
} from '../../Styles/FundDetails.style';
import { SdrInfo } from '../_shared/SdrInfo/SdrInfo';

interface SustainabilitySliceProps {
  asset: AssetQueryAsset;
  canSeeTillitView: boolean;
}

export function SustainabilitySlice({
  asset,
  canSeeTillitView,
}: SustainabilitySliceProps) {
  return (
    <SustainabilityContainer>
      {!asset?.sustainability?.nodes.length && (
        <>
          <SubTitle>Sustainability</SubTitle>
          <NoContent>Sustainability is not yet available</NoContent>
        </>
      )}
      {asset?.sustainability?.nodes.map((sustainability, i) => (
        <React.Fragment key={i}>
          <ContentSection
            title="Sustainability"
            type={sustainability.type || ''}
            content={sustainability.renderedContent || ''}
            hideContent={!canSeeTillitView}
            longHiddenContent={true}
            featuredTitle={true}
          />
          <SdrInfo asset={asset} />
          <SmallCopy
            $size={16}
            $fontStyle="italic"
            copy="Source: The asset manager."
          />
        </React.Fragment>
      ))}
    </SustainabilityContainer>
  );
}
