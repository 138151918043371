import { ExclusivePill } from 'components/design-system/Pill/Pill';
import { colors } from 'constants/colors';
import { GaEventNames } from 'constants/gaConstants';
import { trackGa } from 'helpers/track';
import { FaVideo } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
`;

interface IconProps {
  $isActiveIcon: boolean;
}

const IconNew = styled(NavLink)<IconProps>`
  color: ${colors.magenta};
  background: ${colors['magenta-50']};
  height: 30px;
  width: 55px;
  border-radius: 18px;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.typography.fontFamily};
  font-weight: 400;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $isActiveIcon }) =>
    $isActiveIcon && `border: 1px solid ${colors.magenta}`};

  &:hover {
    border: 1px solid ${colors.magenta};
  }
`;

const IconVideo = styled(NavLink)<IconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 55px;
  border-radius: 18px;
  border: 1px solid
    ${({ $isActiveIcon }) =>
      $isActiveIcon ? colors.magenta : colors['magenta-50']};

  &:hover {
    border: 1px solid ${colors.magenta};
  }

  svg {
    height: 1rem;
  }
`;

const IconEmployerExclusive = styled(NavLink)<IconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: auto;
  border-radius: 18px;
  text-decoration: none;
  box-sizing: content-box; // Otherwise you lose the border at the top and bottom
  & > * {
    cursor: pointer;
  }

  border: 1px solid
    ${({ $isActiveIcon }) =>
      $isActiveIcon ? colors.magenta : colors['magenta-50']};

  &:hover {
    border: 1px solid ${colors.magenta};
  }
`;

const useFundsFilter = (iconTagName: string) => {
  const location = useLocation();
  const path = location.pathname;
  const searchParams = new URLSearchParams(location.search);
  const tags = searchParams.get('tags');
  const search = searchParams.get('search');
  const activeTags = tags ? tags.split(',') : [];
  const isActive = activeTags.includes(iconTagName);
  const toggleAssetClass = isActive
    ? [...activeTags.filter((tag) => tag !== iconTagName)]
    : [...activeTags, iconTagName];

  const newSearchParams = new URLSearchParams();
  if (search) {
    newSearchParams.set('search', search);
  }
  if (toggleAssetClass.length > 0) {
    newSearchParams.set('tags', toggleAssetClass.join(','));
  }

  return {
    activeTags,
    isActive,
    href:
      newSearchParams.has('search') || newSearchParams.has('tags')
        ? `${path}?${newSearchParams.toString()}`
        : path,
  };
};

export interface IconFiltersProps {
  showEmployerExclusive: boolean;
}

export const IconFilters = ({ showEmployerExclusive }: IconFiltersProps) => {
  const trackFilters = (code: Array<string>, tags: Array<string>) => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: `fund filter icon - ${code}`,
      item_id: tags.join(', '),
      filter_group: 'icons',
    });
  };

  // eslint-disable-next-line
  const { href: newHref, isActive: newIsActive, activeTags } = useFundsFilter(
    '+ISNEW'
  );
  const { href: videoHref, isActive: videoIsActive } = useFundsFilter(
    '+HASFULLVIDEO'
  );
  const {
    href: employerExclusiveHref,
    isActive: employerExclusiveIsActive,
  } = useFundsFilter('+EEXCLUSIVE');

  return (
    <IconsWrapper>
      <IconNew
        $isActiveIcon={newIsActive}
        to={newHref}
        onClick={() => {
          trackFilters(['ISNEW'], activeTags);
        }}
      >
        New!
      </IconNew>
      <IconVideo
        $isActiveIcon={videoIsActive}
        to={videoHref}
        onClick={() => {
          trackFilters(['HASFULLVIDEO'], activeTags);
        }}
      >
        <FaVideo color={colors.magenta} size={'1.5rem'} />
      </IconVideo>
      {showEmployerExclusive && (
        <IconEmployerExclusive
          $isActiveIcon={employerExclusiveIsActive}
          to={employerExclusiveHref}
          onClick={() => {
            trackFilters(['EEXCLUSIVE'], activeTags);
          }}
        >
          <ExclusivePill $canHover={false}>Employer Exclusive</ExclusivePill>
        </IconEmployerExclusive>
      )}
    </IconsWrapper>
  );
};
