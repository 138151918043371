import { useMediaQuery, useTheme } from '@material-ui/core';
import { CustomSwipeableDrawer } from 'components/design-system/SwipeableDrawer/SwipeableDrawer';
import {
  FontWeight,
  Text,
  TextNormal,
} from 'components/design-system/Text/Text';
import { useAuth } from 'context/AuthContext';
import { AnimatePresence } from 'framer-motion';
import {
  AccountStatus,
  PortfolioRebalancingStatus,
  usePortfolioRebalancingsSummaryQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import { FaShoppingBasket } from 'react-icons/fa';
import styled from 'styled-components';
import { useAutoSaveInvestState } from '../../autoSaveInvest/AutoSaveInvestContext';
import { FooterIcon, FooterSection } from '../ModeFooter.styles';
import { useMode } from '../useMode';
import { ResumeBasketsList } from './ResumeBasketsList';

export const ResumeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
    margin: 0;
    text-align: left;
    width: auto;
    max-width: none;
    align-items: center;
  }
`;

const MobileContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const FooterIconLayout = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.5rem;
  align-items: center;
`;

export const ResumeFooter = () => {
  const [, setMode] = useMode();
  const { signedIn } = useAuth();
  const theme = useTheme();

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [mobileOpenState, setMobileOpenState] = useState(false);

  const userProfileQuery = useUserProfileQuery(undefined, {
    enabled: signedIn,
  });
  const activeAccountsId = userProfileQuery.data?.clientSummary?.accounts
    .filter((account) => {
      return account.status === AccountStatus.Active;
    })
    .map((account) => account.id);

  const { getBasketsSummary } = useAutoSaveInvestState();
  const basketsSummary = getBasketsSummary();

  const portfolioRebalancingsSummaryQuery = usePortfolioRebalancingsSummaryQuery(
    {
      filter: { active: true },
    },
    {
      enabled: signedIn,
      onSuccess: (data) => {
        const draftPortfolioRebalancings = data?.portfolioRebalancings.filter(
          (rebalancings) =>
            rebalancings.status === PortfolioRebalancingStatus.Draft
        );

        if (
          draftPortfolioRebalancings.length === 0 &&
          basketsSummary?.length === 0
        ) {
          setMode(null);
        }
      },
    }
  );

  const draftPortfolioRebalancings = portfolioRebalancingsSummaryQuery.data?.portfolioRebalancings.filter(
    (rebalancings) =>
      rebalancings.status === PortfolioRebalancingStatus.Draft &&
      rebalancings.buyOrders.length + rebalancings.sellOrders.length > 0 &&
      activeAccountsId &&
      activeAccountsId.includes(rebalancings.accountId)
  );

  useEffect(() => {
    if (
      portfolioRebalancingsSummaryQuery.isLoading ||
      draftPortfolioRebalancings === undefined
    ) {
      return;
    }

    if (
      draftPortfolioRebalancings.length === 0 &&
      basketsSummary?.length === 0
    ) {
      setMode(null);
    }
  }, [
    portfolioRebalancingsSummaryQuery.isLoading,
    draftPortfolioRebalancings,
    draftPortfolioRebalancings?.length,
    basketsSummary?.length,
    setMode,
  ]);

  if (!signedIn || portfolioRebalancingsSummaryQuery.isLoading) {
    return null;
  }

  if (!isMdUp && !mobileOpenState) {
    return (
      <FooterIcon
        key="icon"
        onClick={() => {
          setMobileOpenState(true);
        }}
      >
        <FooterIconLayout>
          <FaShoppingBasket size="1.5rem" />
          <Text $noMargin $isDarkBg $fontWeight={FontWeight.normal}>
            Resume
          </Text>
        </FooterIconLayout>
      </FooterIcon>
    );
  }

  const content = (
    <ResumeContainer>
      <div>
        <TextNormal $noMargin $fontWeight={FontWeight.normal}>
          Your unfinished orders
        </TextNormal>
      </div>

      <ResumeBasketsList
        draftPortfolioRebalancings={draftPortfolioRebalancings}
        basketSummaries={basketsSummary}
        variant="footer"
      />
    </ResumeContainer>
  );

  if (isMdUp) {
    return (
      <AnimatePresence>
        <FooterSection key="section">{content}</FooterSection>
      </AnimatePresence>
    );
  } else {
    return (
      <CustomSwipeableDrawer
        height="short"
        onClose={() => {
          setMobileOpenState(false);
        }}
        backgroundColor="purple"
      >
        <MobileContentWrapper>{content}</MobileContentWrapper>
      </CustomSwipeableDrawer>
    );
  }
};
