import {
  AccountsQueryAccountPosition,
  AssetQueryAsset,
} from 'types/graphqlTypes';

interface matchesHeldAssetProps {
  isin?: string;
  asset: AssetQueryAsset;
  positions: AccountsQueryAccountPosition[];
}

/**
 * @typedef {Object} matchesHeldAssetProps
 * @property {string} [isin] - The selected isin
 * @property {AssetQueryAsset} asset - The asset object for the selected isin
 * @property {AccountsQueryAccountPosition[]} positions - Array of account positions
 */

/**
 * Custom helper to check if a user holds any position in a specific asset, including the provided isin
 * @param {matchesHeldAssetProps} props - The properties object
 * @param {string} [props.isin] - The selected isin
 * @param {AssetQueryAsset} props.asset - The asset to check
 * @param {AccountsQueryAccountPosition[]} props.positions - Array of account positions to check against
 * @returns {Object} An object containing position matching results
 * @returns {boolean} .userHoldsPosition - True if user holds a position matching the ISIN
 * @returns {AccountsQueryAccountPosition[]} .heldPositionsByAsset - Array of positions matching the asset ID
 * @returns {boolean} .userHoldsPositionAndAsset - True if user holds both the position and asset
 */
export function matchesHeldAsset({
  isin,
  asset,
  positions,
}: matchesHeldAssetProps) {
  const userHoldsPosition =
    positions && positions.length > 0
      ? positions.some((position) => position.isin === isin)
      : false;
  const heldPositionsByAsset =
    positions && positions.length > 0
      ? positions.filter(
          (position) => position.instrument?.assetId === asset.id
        )
      : [];

  return {
    userHoldsPosition,
    heldPositionsByAsset,
    userHoldsPositionAndAsset:
      userHoldsPosition && heldPositionsByAsset.length > 0,
  };
}
