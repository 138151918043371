import { FundDetails } from 'components/FundDetails/FundDetails';
import { KeyValueRow } from 'components/KeyValueRow/KeyValueRow';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { FontSize } from 'components/design-system/Text/Text';
import * as format from 'formatting';
import {
  RecognisedChargeType,
  TradeIllustrationCostLineType,
} from 'generated/graphql';
import {
  MiFIDEstimatedTillitFee,
  MiFIDIncidentalCosts,
  MiFIDOngoingCosts,
  MiFIDTransactionCosts,
  PtmLevy,
  StampDuty,
  ZeroCommission,
} from 'strings/tooltips';

interface ChargeRowTooltipProps {
  amountFormatted: string;
  charge: TradeIllustrationCostLineType;
}

function ChargeRowTooltip({ amountFormatted, charge }: ChargeRowTooltipProps) {
  const chargeType = charge.chargeType;

  return charge.chargeType ? (
    <>
      {chargeType === RecognisedChargeType.Ocf && (
        <MiFIDOngoingCosts amountFormatted={amountFormatted} />
      )}
      {chargeType === RecognisedChargeType.Platform && (
        <MiFIDEstimatedTillitFee amountFormatted={amountFormatted} />
      )}
      {chargeType === RecognisedChargeType.Dealing && <ZeroCommission />}
      {chargeType === RecognisedChargeType.StampDuty && (
        <StampDuty
          amountFormatted={amountFormatted}
          stampDutyProportion={0.005}
        />
      )}
      {chargeType === RecognisedChargeType.PtmLevy && (
        <PtmLevy levyAmount={1.5} threshold={10_000} />
      )}
      {chargeType === RecognisedChargeType.Transaction && (
        <MiFIDTransactionCosts
          amountFormatted={amountFormatted}
          isNegative={charge.amount < 0}
        />
      )}
      {chargeType === RecognisedChargeType.Incidental && (
        <MiFIDIncidentalCosts amountFormatted={amountFormatted} />
      )}
    </>
  ) : null;
}

function getGaEventName(charge: TradeIllustrationCostLineType) {
  const gaEventNameLookup: Partial<Record<RecognisedChargeType, string>> = {
    [RecognisedChargeType.Ocf]: 'Estimated fund fee',
    [RecognisedChargeType.Platform]: 'Estimated tillit fee',
    [RecognisedChargeType.Dealing]: 'No commission',
    [RecognisedChargeType.StampDuty]: 'Stamp duty',
    [RecognisedChargeType.PtmLevy]: 'PTM levy',
  };

  return charge.chargeType
    ? gaEventNameLookup[charge.chargeType]
    : charge.description;
}

type Variant = 'compact' | 'normal';

export interface ChargeRowProps {
  charge: TradeIllustrationCostLineType;
  oneOff?: boolean;
  trackPopoverClick: (label: string) => void;
  amountEntered: number;
  showTooltip?: boolean;
  variant?: Variant;
}

export function ChargeRow({
  charge,
  oneOff,
  trackPopoverClick,
  amountEntered,
  showTooltip = false,
  variant = 'compact',
}: ChargeRowProps) {
  const label =
    charge.chargeProportion !== null
      ? `${charge.description} (${format.percent(charge.chargeProportion!)}${
          oneOff ? '' : ' pa'
        })`
      : charge.description;
  const amountFormatted = format.currencyFull(amountEntered);
  const gaEventName = getGaEventName(charge);

  return (
    <>
      {variant === 'normal' ? (
        <FundDetails
          label={
            <>
              {label}
              {showTooltip && (
                <>
                  {' '}
                  <InfoPopoverV2
                    $width="wide"
                    placement={'top-end'}
                    size="small"
                    onClick={() =>
                      gaEventName && trackPopoverClick(gaEventName)
                    }
                    content={
                      <ChargeRowTooltip
                        amountFormatted={amountFormatted}
                        charge={charge}
                      />
                    }
                  />
                </>
              )}
            </>
          }
          value={format.currencyFull(charge.amount)}
          valueAlign="right"
          variant="outlined"
        />
      ) : (
        <KeyValueRow
          label={
            <>
              {label}
              {showTooltip && (
                <>
                  {' '}
                  <InfoPopoverV2
                    size="small"
                    $width="wide"
                    placement={'top-end'}
                    onClick={() =>
                      gaEventName && trackPopoverClick(gaEventName)
                    }
                    content={
                      <ChargeRowTooltip
                        amountFormatted={amountFormatted}
                        charge={charge}
                      />
                    }
                  />
                </>
              )}
            </>
          }
          value={format.currencyFull(charge.amount)}
          noMargin
          fontSize={FontSize.small}
        />
      )}
    </>
  );
}

export interface TotalChargeRowProps {
  finalAmountBeforeCharges: number | string;
  totalCharges: number | string;
  finalAmountAfterCharges: number | string;
  variant?: Variant;
}

export function TotalChargeRow({
  finalAmountBeforeCharges,
  totalCharges,
  finalAmountAfterCharges,
  variant = 'compact',
}: TotalChargeRowProps) {
  return (
    <>
      {variant === 'normal' ? (
        <>
          <FundDetails
            label={'What you might get back with no charges'}
            value={format.currencyFull(finalAmountBeforeCharges!)}
            valueAlign="right"
            variant="outlined"
          />
          <FundDetails
            label={'Total estimated charges'}
            value={format.currencyFull(totalCharges)}
            valueAlign="right"
            variant="outlined"
          />
          <FundDetails
            label={`What you might get back with charges applied`}
            value={format.currencyFull(finalAmountAfterCharges)}
            valueAlign="right"
            variant="outlined"
          />
        </>
      ) : (
        <>
          <KeyValueRow
            label={`What you might get back with no charges`}
            value={format.currencyFull(finalAmountBeforeCharges!)}
            noMargin
            fontSize={FontSize.small}
          />
          <KeyValueRow
            label={`Total estimated charges`}
            value={format.currencyFull(totalCharges!)}
            noMargin
            fontSize={FontSize.small}
          />
          <KeyValueRow
            label={`What you might get back with charges applied`}
            value={format.currencyFull(finalAmountAfterCharges!)}
            noMargin
            fontSize={FontSize.small}
          />
        </>
      )}
    </>
  );
}
