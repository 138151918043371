import { Typography } from '@material-ui/core';
import { colors } from 'constants/colors';
import Styled from 'styled-components';

export const TeamContainer = Styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap:40px;
  align-items: stretch;
  margin: 0;
  
  ${(p) => p.theme.breakpoints.up('sm')} {
    gap:70px;
  }
`;

type Single = {
  selectedColour: string;
};

export const TeamSingle = Styled.div<Single>`
  width: 290px;
  max-width: 341px;
  box-shadow: -30px -30px 60px #FAFBFF, 0px 20px 30px rgba(166, 171, 189, 0.5);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;

  ${(p) => p.theme.breakpoints.up('sm')} {
    width: 290px;
  } 
`;

export const TeamHeading = Styled.div`
  position: relative;
  text-align: center;
  /* margin-left: ${(p) => p.theme.spacing(4.25)}px;
  margin-bottom: ${(p) => p.theme.spacing(1.25)}px; */
  &:after {
    content: '';
    position: absolute;
    width: 32px;
    height: 1px;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: ${colors.magenta};
    opacity: 0.3;
  }
`;

export const TeamSingleName = Styled(Typography).attrs({ variant: 'h3' })`
  color: ${colors.darkBlue};
  font-size: 1.1875rem;
  font-weight: 400;
`;
export const TeamSingleJobTitle = Styled(Typography).attrs({ variant: 'h3' })`
  color: ${colors.richBlack};
  font-size: 1.0625rem;
  font-weight: 300;

  font-style: italic;
`;
