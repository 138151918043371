import { Typography } from '@material-ui/core';
import { LinkCustomButton } from 'components/Button/CustomButton';
import { PageContainer } from 'components/PageContainer';
import { QueryState } from 'components/QueryState';
import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/ResponsiveDialog';
import { GaEventNames } from 'constants/gaConstants';
import {
  AccountStatus,
  useAccountsQuery,
  WrapperType,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { dashboardPath, generateStartTransferPath } from 'paths';
import { useIntercom } from 'react-use-intercom';
import { ActionContainer, DialogTitle } from './TransferAccountType.styles';

export interface TransferAccountTypeButtonsV2Props {
  onClose?: () => void;
}

export function TransferAccountTypeButtonsV2({
  onClose,
}: TransferAccountTypeButtonsV2Props) {
  const { showNewMessages } = useIntercom();
  const accountsQuery = useAccountsQuery();

  const handleTransferISA = async () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: 'Start Transfer - ISA',
    });
  };

  const handleTransferGIA = async () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: 'Start Transfer - GIA',
    });
  };

  const handleTransferPension = async () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: 'Start Transfer - Pension',
    });
    showNewMessages(
      'I would like to transfer an account to my TILLIT Pension.'
    );
    onClose?.();
  };

  return (
    <>
      <LinkCustomButton
        className="purple"
        fullWidth
        style={{
          whiteSpace: 'break-spaces',
        }}
        onClick={handleTransferISA}
        to={generateStartTransferPath({ accountType: 'isa' })}
      >
        Stocks &amp; Shares ISA
      </LinkCustomButton>
      <LinkCustomButton
        className="purple"
        fullWidth
        style={{
          whiteSpace: 'break-spaces',
        }}
        onClick={handleTransferGIA}
        to={generateStartTransferPath({ accountType: 'gia' })}
      >
        General investment account
      </LinkCustomButton>
      <QueryState {...accountsQuery}>
        {(data) =>
          data?.data?.accounts?.find(
            (x) =>
              x.wrapperType === WrapperType.Sipp &&
              x.status === AccountStatus.Active
          ) ? (
            <LinkCustomButton
              className="purple"
              fullWidth
              style={{
                whiteSpace: 'break-spaces',
              }}
              onClick={handleTransferGIA}
              to={generateStartTransferPath({ accountType: 'pension' })}
            >
              Pension
            </LinkCustomButton>
          ) : (
            <LinkCustomButton
              className="purple"
              fullWidth
              style={{
                whiteSpace: 'break-spaces',
              }}
              onClick={handleTransferPension}
              to={dashboardPath}
            >
              Pension
            </LinkCustomButton>
          )
        }
      </QueryState>
    </>
  );
}

export interface TransferAccountTypeProps extends ResponsiveDialogProps {}

export function TransferAccountType({
  onClose,
  ...props
}: TransferAccountTypeProps) {
  return (
    <PageContainer>
      <ResponsiveDialog {...props} onClose={onClose}>
        <DialogTitle variant="subtitle1" align="center">
          Pick your account type
        </DialogTitle>
        <Typography align="center">
          Before you can transfer your account, please select the account type
          you would like to transfer to TILLIT.
        </Typography>
        <ActionContainer>
          <TransferAccountTypeButtonsV2 onClose={onClose} />
        </ActionContainer>
      </ResponsiveDialog>
    </PageContainer>
  );
}
