import { Asset } from 'generated/graphql';
import { PartialDeep } from 'type-fest';
import { shouldNeverDisplayTag } from './tagPolicies';

interface TagProps {
  title: string;
  code: string;
  content: JSX.Element | string | null;
  categoryCode?: string;
  isImportant: boolean;
}

type deriveMissingTagsArgs = {
  asset: PartialDeep<Asset>;
  searchTags: TagProps[];
  countSameCatAsMissing?: boolean;
};

export function deriveMissingTags({
  searchTags,
  asset,
  countSameCatAsMissing = false,
}: deriveMissingTagsArgs) {
  const assetTags =
    asset?.tags?.nodes?.map((tag) => {
      return {
        ...tag,
        isActive: (searchTags || []).some(
          (searchTag) => searchTag.code === tag?.code
        ),
      };
    }) || [];

  /**
   * Get a list of tags that have been searched for that this fund doesn't have.
   */
  return (
    searchTags &&
    searchTags.filter((searchTag) => {
      if (shouldNeverDisplayTag(searchTag.code)) {
        return false;
      }

      // First does the fund have the tag
      const fundHasTag = assetTags?.some((tag) => searchTag.code === tag?.code);
      if (fundHasTag) {
        return false;
      }

      // Second, if the fund doesn't have the tag, but is in the other category then show it in
      // the missing tags list.
      if (searchTag.categoryCode === 'OTHER') {
        return true;
      }

      // Third, determine if tags in the missing list is in the same category and
      // use countSameCatAsMissing to switch result. (IE. show 'Missing: UK'
      // if the fund matches on Global and countSameCatAsMissing is true).
      const fundHasMatchedTagInCategory = assetTags.some((tag) => {
        return tag?.isActive && tag.categoryCode === searchTag.categoryCode;
      });
      return countSameCatAsMissing
        ? fundHasMatchedTagInCategory
        : !fundHasMatchedTagInCategory;
    })
  );
}
