import { SwipeableDrawer, makeStyles } from '@material-ui/core';
import {
  H5,
  TextAlign as HTextAlign,
} from 'components/design-system/Heading/Heading';
import { colors } from 'constants/colors';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { useEffectOnce } from 'usehooks-ts';

const heightLookup = {
  normal: '80vh',
  short: '45vh',
  teaser: '5rem',
};
interface DrawerContentsWrapperProps {
  $height: keyof typeof heightLookup;
}
const DrawerContentsWrapper = styled.div<DrawerContentsWrapperProps>`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    z-index: 1;
    width: 100%;
    height: ${({ $height }) => heightLookup[$height]};
    padding: ${({ $height }) =>
      $height === 'teaser' ? '0.75rem 1rem 1.25rem' : '2rem 1rem 1rem'};
    overflow-y: scroll;
    transition: height 0.3s ease;
  }
`;

const bgColorLookup = {
  white: colors['white'],
  purple: colors['purple-50'],
  richBlue: colors['richBlue'],
};

interface StyledSwipeableDrawerProps {
  $backgroundColor?: keyof typeof bgColorLookup;
}
const StyledSwipeableDrawer = styled(
  SwipeableDrawer
)<StyledSwipeableDrawerProps>`
  width: 100%;
  .MuiDrawer-paper {
    width: 100%;
    border-radius: 10px 10px 0 0;
    background-color: ${({ $backgroundColor }) =>
      $backgroundColor ? bgColorLookup[$backgroundColor] : colors['white']};
  }
`;

const MobileSwipeHandle = styled.div`
  width: 3rem;
  height: 6px;
  background-color: ${colors['grey-600']};
  border-radius: 3px;
  margin: 0.5rem auto 1rem;
  left: 0;
  right: 0;
  top: 8px;
  z-index: 2;
`;

interface CustomSwipeableDrawerProps {
  onClose: () => void;
  title?: string;
  height?: keyof typeof heightLookup;
  children: ReactNode;
  backgroundColor?: 'white' | 'purple' | 'richBlue';
  open?: boolean;
}

export const CustomSwipeableDrawer = ({
  onClose,
  title,
  height = 'normal',
  children,
  backgroundColor,
  open = true,
}: CustomSwipeableDrawerProps) => {
  const useStyles = makeStyles({
    bottomDrawer: {
      transform: 'translateY(100%)',
    },
  });
  const classes = useStyles();

  const handleOpen = () => {
    setTimeout(() => {
      const drawer = document.querySelectorAll<HTMLElement>(
        '.MuiDrawer-paper'
      )[0];
      if (drawer) {
        drawer.style.transform = 'translateY(0%)';
        drawer.style.transition = 'transform 0.3s ease';
        document.body.style.position = 'relative';
        document.body.style.height = '100%';
      }
    }, 300);
  };

  const handleClose = () => {
    const drawer = document.querySelectorAll<HTMLElement>(
      '.MuiDrawer-paper'
    )[0];
    if (drawer) {
      drawer.style.transform = 'translateY(100%)';
      drawer.style.transition = 'transform 0.3s ease';
      document.body.style.position = '';
      document.body.style.height = '';
    }
    setTimeout(() => {
      onClose();
    }, 300);
  };

  useEffectOnce(() => {
    handleOpen();
  });

  return (
    <StyledSwipeableDrawer
      anchor="bottom"
      open={open}
      onOpen={() => handleOpen()}
      onClose={() => handleClose()}
      classes={{ paper: classes.bottomDrawer }}
      $backgroundColor={backgroundColor}
      ModalProps={{
        disableScrollLock: height === 'teaser',
        hideBackdrop: height === 'teaser',
      }}
    >
      <MobileSwipeHandle />
      <DrawerContentsWrapper $height={height}>
        {title && <H5 $textAlign={HTextAlign.center}>{title}</H5>}
        {children}
      </DrawerContentsWrapper>
    </StyledSwipeableDrawer>
  );
};
