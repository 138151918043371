import { H1 } from 'components/design-system/Heading/Heading';
import { TextSmall } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import styled, { css } from 'styled-components';

interface InstrumentDetailsBaseProps {
  $highlighted: boolean;
  $compact: boolean;
}

interface InstrumentDetailsWrapperProps extends InstrumentDetailsBaseProps {}

export const InstrumentDetailsWrapper = styled.div<InstrumentDetailsWrapperProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: ${({ $compact }) =>
    $compact ? '0rem 0.5rem' : '0.625rem 1rem 0.625rem 0.5rem'};
  background-color: ${({ $highlighted }) =>
    $highlighted ? colors['magenta-25'] : 'transparent'};
  cursor: pointer;
`;

interface InstrumentSelectorWrapperProps extends InstrumentDetailsBaseProps {}

export const InstrumentSelectorWrapper = styled.div<InstrumentSelectorWrapperProps>`
  border: 1px solid ${colors['magenta-100']};
  border-radius: 5px;
  background-color: ${({ $highlighted }) =>
    $highlighted ? colors['magenta-25'] : 'transparent'};
`;

interface InstrumentDetailsColumnProps {
  $align?: 'left' | 'center' | 'right';
  invertOrder?: boolean;
}

export const InstrumentDetailsColumn = styled.div<InstrumentDetailsColumnProps>`
  max-width: 18rem;
  text-align: ${({ $align }) => ($align ? $align : 'inherit')};
  order: ${({ invertOrder }) => (invertOrder ? 1 : 0)};
`;

export const InstrumentDetailWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  gap: 0.5rem;
`;

export const InstrumentName = styled(H1)`
  font-size: 0.875rem;
  font-weight: 300;
  margin: 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 1rem;
  }
`;

export const InstrumentExtraDetails = styled(TextSmall)`
  color: rgba(0, 0, 0, 0.6);
  margin: 0%;
`;

interface StyledRadioGroupProps {
  $compact: boolean;
  $isWide: boolean;
}

export const StyledRadioGroup = styled.div<StyledRadioGroupProps>`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: ${({ $compact }) => ($compact ? 'flex' : 'grid')};
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    flex-direction: row;

    ${({ $compact, $isWide }) =>
      $compact &&
      css`
      text-align: : left;
      justify-content: ${$isWide ? 'flex-start' : 'center'};
    `}
  }
`;

interface StyledRadioWrapperProps {
  $border: boolean;
}

export const StyledRadioWrapper = styled.div<StyledRadioWrapperProps>`
  display: flex;
  align-items: center;
  border: ${({ $border }) =>
    $border ? `1px solid ${colors['magenta-100']}` : 'none'};
  border-radius: 5px;
  background-color: ${colors.white};
  cursor: pointer;

  label {
    width: 100%;
    > div {
      ${({ $border }) =>
        !$border &&
        css`
          padding-left: 0;
        `}
    }
  }

  > span:nth-child(2) {
    width: 100%;
    > div {
      padding-left: 0;
    }
  }
`;
