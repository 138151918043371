import {
  LinkStepButton,
  StepActions,
  StepContainer,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { dashboardPath } from 'paths';

interface SuccessStepProps {
  typeFriendly: string;
  ctaLabel?: string;
  ctaUrl?: string;
}

export function SuccessStep({
  typeFriendly,
  ctaLabel,
  ctaUrl,
}: SuccessStepProps) {
  return (
    <StepContainer>
      <StepTitle>Your document has been received!</StepTitle>
      <StepText>
        <StepIntroductionTypography>
          We've got your {typeFriendly.toLowerCase()} and will be in touch as
          soon as we have an update.
        </StepIntroductionTypography>
      </StepText>

      <StepActions>
        <LinkStepButton
          to={ctaUrl ?? dashboardPath}
          className="magenta"
          fullWidth
        >
          {ctaLabel ?? 'My Dashboard'}
        </LinkStepButton>
      </StepActions>
    </StepContainer>
  );
}
