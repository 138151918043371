import { EmployerType } from 'generated/graphql';

import { FontWeight, TextSmall } from 'components/design-system/Text/Text';
import { BsClipboard2Check } from 'react-icons/bs';
import { ComplianceTextContainer } from './PolicyPreTradeText.styles';

interface PolicyPreTradeTextProps {
  employer: Pick<EmployerType, 'complianceConfiguration'>;
}

export function PolicyPreTradeText({ employer }: PolicyPreTradeTextProps) {
  if (!employer || !employer.complianceConfiguration?.policyPreTradeText) {
    return null;
  }

  return (
    <ComplianceTextContainer>
      <BsClipboard2Check />
      <TextSmall $fontWeight={FontWeight.medium} $noMargin>
        {employer.complianceConfiguration?.policyPreTradeText}
      </TextSmall>
    </ComplianceTextContainer>
  );
}
