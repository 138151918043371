import { useMediaQuery, useTheme } from '@material-ui/core';
import { SinglePill } from 'components/Pills';
import { CircularProgress } from 'components/Progress/CircularProgress/CircularProgress';
import { GaEventNames } from 'constants/gaConstants';
import * as format from 'formatting';
import { trackGa } from 'helpers/track';
import { RecommendedMinCashBalance } from 'strings/tooltips';
import { AccountQueryAccount } from 'types/graphqlTypes';
import {
  DataContainerGraph,
  Info,
  Link,
  PillGroupContainer,
  PillLabel,
  PillValue,
} from './PillGroup.style';

interface PillGroupProps {
  account: AccountQueryAccount;
}

export function PillGroup({ account }: PillGroupProps) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  const availableCash = account.valuationSummary!.uninvestedCash || 0;
  const pendingOrderValue = account.pendingBuyValue ?? 0;
  const recommendedCash = account.recommendedCashBalance || 0;

  const handleClickTracking = () => {
    trackGa({
      event: GaEventNames.popover,
      content_type: 'min cash popover',
    });
  };

  return (
    <PillGroupContainer>
      {pendingOrderValue > 0 && (
        <SinglePill>
          <PillValue>{format.currencyFull(pendingOrderValue || '0')}</PillValue>
          <PillLabel>Pending Orders</PillLabel>
        </SinglePill>
      )}

      <SinglePill>
        <PillValue>{format.currencyFull(availableCash)}</PillValue>
        <PillLabel>Available cash</PillLabel>
      </SinglePill>

      <SinglePill>
        <DataContainerGraph>
          {matches && (
            <CircularProgress max={recommendedCash} value={availableCash} />
          )}
          {!matches && (
            <PillValue>{format.currencyFull(recommendedCash, false)}</PillValue>
          )}
          <PillLabel>
            Recommended Min cash balance
            <Info
              content={
                <>
                  {RecommendedMinCashBalance}
                  <p>
                    You can find out more{' '}
                    <Link
                      href="https://tillitinvest.com/faqs#what-is-the-recommended-minimum-cash-balance-answer"
                      rel="noreferrer"
                      target="_blank"
                    >
                      here
                    </Link>
                    .
                  </p>
                </>
              }
              onClick={handleClickTracking}
            />
          </PillLabel>
        </DataContainerGraph>
      </SinglePill>
    </PillGroupContainer>
  );
}
