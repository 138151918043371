import { Toast, ToastType } from 'components/Toast/Toast';
import React, { ReactNode, createContext, useContext, useState } from 'react';

interface ContextProps {
  isOpen: boolean;
  toastMessage: string;
  autoHideDuration?: number;
  openToast: (
    message: string,
    type?: ToastType,
    autoHideDuration?: number
  ) => void;
  closeToast: () => void;
}

const ToastContext = createContext<ContextProps>({
  isOpen: false,
  toastMessage: '',
  openToast: () => {},
  closeToast: () => {},
});

interface ToastProviderProps {
  children: ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastType, setToastType] = useState<ToastType>(ToastType.Success);
  const [autoHideDuration, setAutoHideDuration] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const openToast = (
    message: string,
    toastType: ToastType = ToastType.Success,
    autoHideDuration: number = 3000
  ) => {
    setToastMessage(message);
    setToastType(toastType);
    setAutoHideDuration(autoHideDuration);
    setIsOpen(true);
  };

  const closeToast = () => {
    setToastMessage('');
    setIsOpen(false);
  };

  const value: ContextProps = {
    isOpen,
    toastMessage,
    openToast,
    closeToast,
  };

  return (
    <ToastContext.Provider value={value}>
      {children}
      {isOpen && (
        <Toast
          open={isOpen}
          onClose={closeToast}
          message={toastMessage}
          autoHideDuration={autoHideDuration}
          type={toastType}
        />
      )}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
