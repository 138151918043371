import {
  AccordionSummary,
  AccordionSummaryProps,
  Accordion as MuiAccordion,
  makeStyles,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';

interface AccordionProps {
  children: NonNullable<React.ReactNode>;
  noSeparator?: boolean;
  onChange?: () => void;
  expanded?: boolean;
}

const useStyles = makeStyles((_) => ({
  root: {
    '& .MuiAccordionSummary-root': {
      fontWeight: 'bold',
      color: '#4a03bd',
      justifyContent: 'flex-start',
      '& .MuiAccordionSummary-content': {
        flexGrow: 0,
      },
      '& .MuiAccordionSummary-expandIcon': {
        color: 'inherit',
      },
    },
  },
  noSeparator: {
    '&:before': {
      display: 'none',
    },
  },
}));

function isAccordionSummary(
  child: React.ReactChild | React.ReactFragment | React.ReactPortal
): child is React.DetailedReactHTMLElement<AccordionSummaryProps, HTMLElement> {
  return React.isValidElement(child) && child.type === AccordionSummary;
}

export function Accordion({
  noSeparator,
  children,
  onChange,
  expanded: expandedProp,
}: AccordionProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const classNames = useMemo(
    () => [classes.root, noSeparator && classes.noSeparator].join(' '),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [noSeparator]
  );

  const handleChange = (event: React.ChangeEvent<{}>, expanded: boolean) => {
    setExpanded(expandedProp || expanded);
    if (expanded && onChange) {
      onChange();
    }
  };

  useEffect(() => {
    if (expandedProp) {
      setExpanded(true);
    }
  }, [expandedProp]);

  return (
    <MuiAccordion
      square
      elevation={0}
      className={classNames}
      onChange={handleChange}
      expanded={expanded}
    >
      {React.Children.toArray(children).map((child) => {
        if (isAccordionSummary(child)) {
          return React.cloneElement<AccordionSummaryProps>(child, {
            IconButtonProps: {
              disableRipple: true,
            },
            expandIcon: <HiChevronDown size={30} />,
            ...child.props,
          });
        }
        return child;
      })}
    </MuiAccordion>
  );
}
