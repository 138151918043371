import { useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import { GaEventNames } from 'constants/gaConstants';
import { useAuth } from 'context/AuthContext';
import { trackGa } from 'helpers/track';
import * as paths from 'paths';
import { useEffect, useRef, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Nav, StyledNavItem, StyledSubNav } from './Navigation.styles';

enum NavMenus {
  accounts = 'Accounts',
  universe = 'Universe',
  insights = 'Insights',
  about = 'About',
}

interface NavItemProps {
  label: NavMenus;
}

interface SubNavItemProps {
  label: string;
  url: string;
  isNew?: boolean;
  isSoon?: boolean;
  active: boolean;
  isExternal: boolean;
}

interface SubNavProps {
  open: boolean;
  items: SubNavItemProps[];
  navParentLabel: NavMenus;
}

function NavItem({ label }: NavItemProps) {
  return (
    <StyledNavItem>
      {label} <HiChevronDown />
    </StyledNavItem>
  );
}

function SubNav({ open = false, items, navParentLabel }: SubNavProps) {
  const subNavRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState('0');

  useEffect(() => {
    setMaxHeight(open ? subNavRef.current!['scrollHeight'] + 'px' : '0');
  }, [open]);

  return (
    <StyledSubNav
      ref={subNavRef}
      className={open ? 'open' : ''}
      style={{ maxHeight: maxHeight }}
    >
      {items
        .filter((navItem) => navItem.active)
        .map((navItem) => {
          if (navItem.isExternal) {
            return (
              <li key={navItem.label}>
                <a
                  className={clsx(
                    navItem.isNew && 'new',
                    navItem.isSoon && 'soon'
                  )}
                  href={navItem.url}
                  onClick={(e) => {
                    trackGa({
                      event: GaEventNames.navigationClick,
                      navParent: navParentLabel,
                      link_url: navItem.url,
                      link_text: navItem.label,
                      eventCallback: () => {
                        window.location.href = navItem.url;
                      },
                      eventTimeout: 350,
                    });
                  }}
                >
                  {navItem.label}
                </a>
              </li>
            );
          } else {
            return (
              <li key={navItem.label}>
                <Link
                  className={clsx(
                    navItem.isNew && 'new',
                    navItem.isSoon && 'soon'
                  )}
                  to={navItem.url}
                  onClick={() => {
                    trackGa({
                      event: GaEventNames.navigationClick,
                      navParent: navParentLabel,
                      link_url: navItem.url,
                      link_text: navItem.label,
                    });
                  }}
                >
                  {navItem.label}
                </Link>
              </li>
            );
          }
        })}
    </StyledSubNav>
  );
}

interface MenuProps {
  label: NavMenus;
  menu: SubNavItemProps[];
  open: boolean;
  onEnter: (menu: NavMenus) => void;
  onClick: (menu: NavMenus) => void;
  onLeave: () => void;
}

function Menu({ label, menu, open, onEnter, onClick, onLeave }: MenuProps) {
  return (
    <li
      onMouseEnter={() => onEnter(label)}
      onMouseLeave={onLeave}
      onClick={() => onClick(label)}
    >
      <NavItem label={label} />
      <SubNav navParentLabel={label} items={menu} open={open} />
    </li>
  );
}

export interface NavigationProps {
  extraClass: string;
}

export function Navigation({ extraClass }: NavigationProps) {
  const [navOpen, setNavOpen] = useState<NavMenus | false>(false);
  const theme = useTheme();
  const aboveLaptop = useMediaQuery(theme.breakpoints.up('md'));
  const { signedIn } = useAuth();
  let timeout: number = 0;

  const accountsMenu: Array<SubNavItemProps> = [
    {
      label: 'Pension',
      url: paths.tillitSIPPProductPagePath,
      isSoon: false,
      active: true,
      isExternal: true,
    },
    {
      label: 'Stocks & Shares ISA',
      url: paths.tillitISAProductPagePath,
      active: true,
      isExternal: true,
    },
    {
      label: 'General Investment Account',
      url: paths.tillitGIAProductPagePath,
      active: true,
      isExternal: true,
    },
    {
      label: signedIn ? 'Transfer to TILLIT' : 'Transfer Concierge',
      url: signedIn ? paths.dashboardTransfersPath : paths.transfersWebPage,
      active: true,
      isExternal: !signedIn,
    },
    {
      label: 'Fees',
      url: paths.tillitFeesPath,
      active: true,
      isExternal: true,
    },
  ];

  const universeMenu: Array<SubNavItemProps> = [
    {
      label: 'The TILLIT Universe',
      url: paths.hexagonPagePath,
      active: true,
      isExternal: false,
    },
    {
      label: 'The Dark Universe',
      url: paths.tillitDarkUniversePath,
      active: true,
      isExternal: true,
    },
    {
      label: 'How does it work?',
      url: '',
      active: false,
      isExternal: true,
    },
    {
      label: 'Investment Committee',
      url: paths.tillitUniversePath,
      active: true,
      isExternal: true,
    },
  ];

  const insightsMenu: Array<SubNavItemProps> = [
    {
      label: 'The Insights',
      url: paths.tillitInsightsPath,
      active: true,
      isExternal: true,
    },
    {
      label: 'The pension gender gap',
      url: paths.tillitPensionGenderGapPath,
      active: true,
      isExternal: true,
    },
    {
      label: 'Jargon Flashcards',
      url: paths.tillitJargonPath,
      active: true,
      isExternal: true,
    },
  ];

  const aboutMenu: Array<SubNavItemProps> = [
    {
      label: 'About us',
      url: paths.tillitAboutUsPath,
      active: true,
      isExternal: true,
    },
    {
      label: 'TILLIT in the news',
      url: paths.tillitInTheNews,
      active: true,
      isExternal: true,
    },
    {
      label: 'Frequently Answered Questions',
      url: paths.tillitFAQs,
      active: true,
      isExternal: true,
    },
  ];

  const handleOnHover = (menu: NavMenus) => {
    if (!aboveLaptop) return null;
    setNavOpen(menu);
    clearTimeout(timeout);
  };

  const handleOnLeave = () => {
    if (!aboveLaptop) return null;
    timeout = setTimeout(() => {
      setNavOpen(false);
    }, 500);
  };

  const handleClick = (menu: NavMenus) => {
    if (aboveLaptop) return null;
    navOpen === menu ? setNavOpen(false) : setNavOpen(menu);
  };

  return (
    <Nav className={extraClass}>
      <Menu
        label={NavMenus.accounts}
        menu={accountsMenu}
        onEnter={handleOnHover}
        onClick={handleClick}
        onLeave={handleOnLeave}
        open={navOpen === NavMenus.accounts}
      />
      <Menu
        label={NavMenus.universe}
        menu={universeMenu}
        onEnter={handleOnHover}
        onClick={handleClick}
        onLeave={handleOnLeave}
        open={navOpen === NavMenus.universe}
      />
      <Menu
        label={NavMenus.insights}
        menu={insightsMenu}
        onEnter={handleOnHover}
        onClick={handleClick}
        onLeave={handleOnLeave}
        open={navOpen === NavMenus.insights}
      />
      <Menu
        label={NavMenus.about}
        menu={aboutMenu}
        onEnter={handleOnHover}
        onClick={handleClick}
        onLeave={handleOnLeave}
        open={navOpen === NavMenus.about}
      />
      <li>
        <a href={paths.tillitContactPath}>Contact</a>
      </li>
    </Nav>
  );
}
