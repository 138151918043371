import _ from 'lodash';
import {
  AccountQueryAccountPosition,
  AccountsQueryAccountPosition,
} from 'types/graphqlTypes';

export function sortPositions(
  positions: Array<AccountsQueryAccountPosition | AccountQueryAccountPosition>
) {
  return _.orderBy(
    positions,
    [
      (p) => isDarkUniversePosition(p),
      (p) => p.allocation!,
      (p) => p.currentValue,
      (p) => p.instrument?.asset?.name,
    ],
    ['asc', 'desc', 'desc', 'asc']
  );
}

export function isDarkUniversePosition(
  position: AccountsQueryAccountPosition | AccountQueryAccountPosition
) {
  return position?.instrument?.asset?.isDarkUniverse ?? false;
}
