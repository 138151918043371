import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from 'components/Form/TextField';
import { QueryState } from 'components/QueryState';
import { InfoPopoverV2 } from 'components/design-system/InfoPopoverV2/InfoPopoverV2';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import {
  GoBackButton,
  StepActions,
  StepButton,
  StepContainer,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { CUserIntendedRetirementAge } from 'components/feature/FundDetails/hooks/useTargetDateInfo';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  EmploymentStatus,
  MaritalStatus,
  Title,
  useUpdateUserProfileMutation,
  useUserProfileOnboardingQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import type { UserProfileOnboardingQueryUserProfile } from 'types/graphqlTypes';
import * as Yup from 'yup';
import { WarningContainer } from '../_shared/steps.styles';
import { EmploymentStatusField } from './EmploymentStatusField';
import { MaritalStatusField } from './MaritalStatusField';
import { TitleField } from './TitleField';

function useInvalidateMutatedQueries() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(useUserProfileQuery.getKey());
    queryClient.invalidateQueries(useUserProfileOnboardingQuery.getKey());
  };
}

const moreAboutYouFormSchema = Yup.object({
  title: Yup.mixed<Title>()
    .label('Title')
    .when('$requireTitle', {
      is: true,
      then: Yup.mixed<Title>()
        .not([Title.Unknown])
        .required('Please select a title.'),
    }),

  maritalStatus: Yup.mixed<MaritalStatus>()
    .not([MaritalStatus.Unknown])
    .required('Please select your marital status'),
  employmentStatus: Yup.mixed<EmploymentStatus>()
    .not([EmploymentStatus.Unknown])
    .required('Please select your employment status'),
  retirementAge: Yup.number()
    .min(55, 'Your retirement age must be 55 or above')
    .max(75, 'Your retirement age must be 75 or below')
    .required(
      'Please enter your desired retirement age between the age of 55 and 75'
    )
    .typeError(
      'Please enter your desired retirement age between the age of 55 and 75'
    ),
});

function getUserAgeAndBuffer(dob: Date) {
  const date = new Date();
  const dif = date.getFullYear() - dob.getFullYear();
  const buffer = 3;
  return {
    age: dif,
    buffer,
  };
}

type MoreAboutYouStepFormValues = Yup.InferType<typeof moreAboutYouFormSchema>;

interface MoreAboutYouStepProps {
  source?: 'openAccountStep';
  onProceed: () => void;
  onGoBack: () => void;
}

export function MoreAboutYouStep(props: MoreAboutYouStepProps) {
  const moreAboutYouStepQuery = useUserProfileOnboardingQuery();

  return (
    <QueryState {...moreAboutYouStepQuery}>
      {(queryResult) => (
        <MoreAboutYouStepForm
          {...props}
          data={queryResult.data?.userProfile!}
        />
      )}
    </QueryState>
  );
}

interface MoreAboutYouStepFormProps extends MoreAboutYouStepProps {
  data: UserProfileOnboardingQueryUserProfile;
}

function MoreAboutYouStepForm({
  source,
  onProceed,
  onGoBack,
  data,
}: MoreAboutYouStepFormProps) {
  const {
    mutateAsync: updateUserProfile,
    isError,
    reset,
  } = useUpdateUserProfileMutation();
  const [warning, setWarning] = useState<string | null>(null);
  const invalidateQueries = useInvalidateMutatedQueries();
  const dob = new Date(data.dateOfBirth);
  const userAgeInfo = getUserAgeAndBuffer(dob);

  const hasEmployer = (data?.employments || []).length > 0;

  const defaultValues = {
    title: data.title ?? null,
    maritalStatus: data.maritalStatus ?? null,
    employmentStatus:
      data.employmentStatus ?? hasEmployer ? EmploymentStatus.Employed : null,
    retirementAge: data.pensionDetails?.retirementAge
      ? data.pensionDetails?.retirementAge
      : userAgeInfo.age + userAgeInfo.buffer > 67
      ? userAgeInfo.age + userAgeInfo.buffer
      : 67,
  };

  const requireTitle = !data.title;

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(moreAboutYouFormSchema),
    context: { requireTitle },
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    register,
  } = methods;

  const handleRetirementAgeChange = (retirementAge: number) => {
    if (userAgeInfo.age + userAgeInfo.buffer > retirementAge) {
      setWarning(
        `You are too close to your intended retirement age for us to provide you with an illustration. 
        Please type in a retirement age that is above ${
          userAgeInfo.age + userAgeInfo.buffer
        } to continue.`
      );
      return;
    } else {
      setWarning(null);
    }
  };

  const onSubmit: SubmitHandler<MoreAboutYouStepFormValues> = async (data) => {
    reset();
    handleRetirementAgeChange(data.retirementAge);

    try {
      if (isDirty) {
        await updateUserProfile({
          input: {
            title: data.title,
            maritalStatus: data.maritalStatus,
            employmentStatus: data.employmentStatus,
            pension: {
              retirementAge: data.retirementAge,
            },
          },
        });
        invalidateQueries();
        Cookies.remove(CUserIntendedRetirementAge);
      }

      if (source === 'openAccountStep') {
        trackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.moreAboutYouPension,
        });
      }

      onProceed();
    } catch {
      // error handled by state
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <StepContainer>
          <StepTitle>More about you</StepTitle>
          <StepIntroduction $width={StepIntroductionWidth.normal}>
            <StepIntroductionTypography>
              We need some extra information to create a pension product
              illustration, helping you decide if the TILLIT Pension is right
              for you.
            </StepIntroductionTypography>
          </StepIntroduction>

          <StepFormContainer>
            {!data.title && (
              <TitleField id="title" name="title" label="Title" />
            )}

            <MaritalStatusField name="maritalStatus" label="Marital Status" />
            {hasEmployer ? (
              <input name="employmentStatus" ref={register} type="hidden" />
            ) : (
              <EmploymentStatusField
                name="employmentStatus"
                label="Employment Status"
              />
            )}

            <TextField
              id="retirementAge"
              name="retirementAge"
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
              ) =>
                handleRetirementAgeChange(parseInt(event.currentTarget.value))
              }
              label={
                <>
                  Intended retirement age
                  <InfoPopoverV2
                    content="You cannot take retirement benefits before you're 55, and the current state pension age is 66 (rising to 67 from 2028)"
                    size="small"
                    placement={'top'}
                    openOn="hover"
                  />
                </>
              }
            />

            {warning !== null && <WarningContainer>{warning}</WarningContainer>}

            <ServerError isVisible={isError} />
          </StepFormContainer>

          <StepActions>
            <StepButton
              type="submit"
              className="magenta"
              disabled={isSubmitting || warning !== null}
            >
              Continue
            </StepButton>
            <GoBackButton onClick={onGoBack} disabled={isSubmitting} />
          </StepActions>
        </StepContainer>
      </FormProvider>
    </form>
  );
}
