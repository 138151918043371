import {
  OpenBankingPaymentMethod,
  PaymentMethodInterfaceType,
} from 'generated/graphql';

export function isOpenBankingPaymentMethod(
  paymentMethod: PaymentMethodInterfaceType
): paymentMethod is OpenBankingPaymentMethod {
  return (
    (paymentMethod as OpenBankingPaymentMethod).__typename ===
    'OpenBankingPaymentMethod'
  );
}
