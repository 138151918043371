import { colors } from 'constants/colors';
import { transparentize } from 'polished';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { SearchIconCustomFont } from './fonts/customFonts';
import { typography } from './fonts/typography';

interface BodyOverflowProps {
  overflowHidden?: boolean;
}

export const BodyOverflow = createGlobalStyle<BodyOverflowProps>`
  body {
    overflow-y: ${(p) => (p.overflowHidden ? 'hidden' : 'auto')};
  }
`;

const GlobalStyle = createGlobalStyle`
  :root {
    --main-logo-color: white;
    --main-header-color: white;
  }

  ${normalize}
  ${typography}
  ${SearchIconCustomFont}

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    font-family: ${(p) => p.theme.typography.bodyFontFamily}, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.4;
  }

  ::selection,
  input::-webkit-datetime-edit-day-field:focus,
  input::-webkit-datetime-edit-month-field:focus,
  input::-webkit-datetime-edit-year-field:focus  {
    background: ${transparentize(0.9, colors.magenta)};
    color: ${colors.mauve};
  }

  .dark-background *::selection {
    background: ${transparentize(0.6, colors.magenta)};
    color: ${colors.white};
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${(p) => p.theme.typography.headingFontFamily}, serif;
  }

  iframe[name="intercom-banner-frame"] {
    top: 80px !important;

    @media screen and (min-width: 600px) {
      top: 100px !important;
    }
  }

  body.banner--open {

    ${({ theme }) => theme.breakpoints.up('md')} {
      .app--header {
        transition: transform 0.4s ease-in-out 0.2s, opacity 0.25s ease-in-out 0s;
        transform: translateY(50px);
      }
    }

    ${(p) => p.theme.breakpoints.down('md')} {

      &.scroll-down {
        .intercom-lightweight-app-launcher,
        .scroll-to-top {
          bottom: 20px;
          transition: bottom 0.4s linear 0.2s;
        }
      }
      .intercom-lightweight-app-launcher,
      .scroll-to-top {
        bottom: 70px;
        transition: bottom 0.4s linear 0.2s;
      }
    }
  }


`;

export default GlobalStyle;
