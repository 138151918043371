import { QueryState } from 'components/QueryState';
import { useUserProfileQuery } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { NotFound } from 'pages/NotFound/NotFound';
import {
  actionsAccountPath,
  actionsAddCashPath,
  actionsFundAccountPath,
  actionsPath,
  actionsSetupMfa,
  actionsTransferPath,
  generateActionsAccountPath,
} from 'paths';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ActionsQueryAction } from 'types/graphqlTypes';
import { SetupMfaMethodForm } from '../setupMfa/SetupMfaMethodForm';
import { ActionsListStep } from './steps/ActionsList';
import { AddCashStep } from './steps/AddCashStep/AddCashStep';
import { FundAccount } from './steps/FundAccount/FundAccount';
import { TransferStep } from './steps/TransferStep/TransferStep';

export const Actions = () => {
  const userProfile = useUserProfileQuery();
  const history = useHistory();

  const { search } = useLocation();

  const onProceed = (action: ActionsQueryAction) => {
    const query = new URLSearchParams(search);
    const redirectToAccountActionsList =
      query.has('source') && query.get('source') === 'account';

    if (redirectToAccountActionsList) {
      const account = userProfile.data?.clientSummary?.accounts.find(
        (account) => account.id === action.accountId
      );
      history.push(
        generateActionsAccountPath({
          accountId: account?.id!,
          wrapperType: getPathSegmentForWrapperType(account?.wrapperType!),
        })
      );
    } else {
      history.push(actionsPath);
    }
  };

  return (
    <QueryState {...userProfile}>
      {() => (
        <Switch>
          <Route path={actionsAddCashPath}>
            <AddCashStep onProceed={onProceed} />
          </Route>
          <Route path={actionsSetupMfa}>
            <SetupMfaMethodForm
              proceedBtnText="Continue"
              onProceed={() => {
                history.push(actionsPath);
              }}
            />
          </Route>
          <Route path={actionsTransferPath}>
            <TransferStep onProceed={onProceed} />
          </Route>
          <Route path={actionsFundAccountPath}>
            <FundAccount onProceed={onProceed} />
          </Route>
          <Route path={[actionsPath, actionsAccountPath]} exact>
            <ActionsListStep />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      )}
    </QueryState>
  );
};
