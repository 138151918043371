import { Option } from 'components/Form/Select';
import { SelectField, SelectFieldProps } from 'components/Form/SelectField';
import { MaritalStatus } from 'generated/graphql';

interface MaritalStatusFieldProps extends Omit<SelectFieldProps, 'options'> {}

export function MaritalStatusField(props: MaritalStatusFieldProps) {
  const maritalStatusOptions: Option[] = [
    { value: MaritalStatus.Divorced, label: 'Divorced' },
    { value: MaritalStatus.Married, label: 'Married' },
    { value: MaritalStatus.Single, label: 'Single' },
    { value: MaritalStatus.Widowed, label: 'Widowed' },
  ];

  return <SelectField {...props} options={maritalStatusOptions} />;
}
