import { HeaderShim } from 'components/design-system/Header';
import { ExclusivePill } from 'components/design-system/Pill/Pill';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { DarkActionsBox } from './DarkActionsBox';
import {
  DarkFundTitle,
  DarkHeroContainer,
  DarkIntroContainerInner,
  DarkTitleContainer,
} from './DarkHeader.styles';

interface DarkHeaderProps {
  title: string;
  isin: string;
  assetId: string;
  userHolds: boolean;
  signedIn: boolean;
  asset: AssetQueryAsset;
  handleInstrumentChange: (isin: string) => void;
}

export function DarkHeader({
  title,
  isin,
  assetId,
  userHolds,
  signedIn,
  asset,
  handleInstrumentChange,
}: DarkHeaderProps) {
  const customUniverseISINs = asset.instruments?.nodes?.filter(
    (instrument) => instrument.isCustomUniverse
  );
  const isCustomUniverse = customUniverseISINs.length
    ? customUniverseISINs.length >= asset.instruments?.nodes?.length
      ? 'all'
      : 'some'
    : 'none';

  return (
    <DarkHeroContainer maxWidth={false} className="dark-background">
      <HeaderShim />
      <DarkIntroContainerInner maxWidth={'xl'}>
        <DarkTitleContainer>
          <DarkFundTitle variant="h1">{title}</DarkFundTitle>
          {['all', 'some'].includes(isCustomUniverse) && (
            <ExclusivePill $canHover={false}>Employer Exclusive</ExclusivePill>
          )}
        </DarkTitleContainer>

        <DarkActionsBox
          userHolds={userHolds}
          authIndicator={signedIn}
          isin={isin}
          assetId={assetId}
          title={title}
          isInHeader={true}
          handleInstrumentChange={handleInstrumentChange}
          asset={asset}
        />
      </DarkIntroContainerInner>
    </DarkHeroContainer>
  );
}
