import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { TextField } from 'components/Form/TextField';
import EasyQRCode from 'easyqrcodejs';
import styled from 'styled-components';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

import { Alert } from '@material-ui/lab';
import { CopyToClipboardButton } from 'components/Button/CopyToClipboardButton';
import { ServerError } from 'components/design-system/ServerError/ServerError';
import { colors } from 'constants/colors';
import {
  useCompleteTotpMfaSetupMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { useEffect, useRef } from 'react';
import * as Yup from 'yup';

const totpMfaCodeFormSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .label('Code')
    .required()
    // eslint-disable-next-line no-template-curly-in-string
    .matches(/^\d{6}$/, '${path} must be 6 digits'),
});

type TotpMfaCodeFormValues = Yup.Asserts<typeof totpMfaCodeFormSchema>;

interface TotpQrCodeStepProps {
  totpSecret: string;
  onProceed?: (switchedFromSms: boolean) => void;
}

const StepActionsVerificationCode = styled(StepActions)`
  gap: 0.75rem;
`;

interface QRCodeProps {
  value: string;
  size?: number;
}

const QrCodeContainer = styled.div`
  margin-bottom: 0.5rem;
`;

const QrCodeSecretContainer = styled.div`
  width: auto;
`;

const QrCodeSecret = styled.span`
  font-family: monospace;
  background: ${colors.lightGrey};
  font-size: 16px;
  word-wrap: break-word;
`;

function QRCode({ value, size = 192 }: QRCodeProps) {
  const qrContainer = useRef<HTMLDivElement>(null);
  const qrCode = useRef<EasyQRCode | null>(null);

  const logoSize = Math.round(size * 0.39);

  useEffect(() => {
    qrCode.current?.clear();

    qrCode.current = new EasyQRCode(qrContainer.current, {
      text: value,
      correctLevel: EasyQRCode.CorrectLevel.H,
      width: size,
      height: size,
    });
  }, [value, size, logoSize]);

  return <QrCodeContainer ref={qrContainer} />;
}

export const TotpQrCodeStep = ({
  totpSecret,
  onProceed,
}: TotpQrCodeStepProps) => {
  const userProfileQuery = useUserProfileQuery(undefined);

  const username =
    userProfileQuery.data?.userProfile?.emailAddresses?.find(
      (x) => x?.isVerified
    )?.address || '';

  const qrCodeContent = `otpauth://totp/${encodeURIComponent(
    username
  )}?secret=${encodeURIComponent(totpSecret)}&issuer=TILLIT`;

  const {
    mutateAsync,
    isError,
    error,
  } = useCompleteTotpMfaSetupMutation<any>();

  const onSubmit = async (data: TotpMfaCodeFormValues) => {
    const result = await mutateAsync({
      input: {
        code: data.verificationCode,
      },
    });

    onProceed?.(result.completeTotpMfaSetup.switchedFromSms);
  };

  return (
    <StepContainer>
      <Form<TotpMfaCodeFormValues>
        onSubmit={onSubmit}
        schema={totpMfaCodeFormSchema}
      >
        <StepContent>
          <StepTitle>Pair your authenticator app</StepTitle>
          <StepIntroduction>
            <StepIntroductionTypography>
              Scan the QR code below with your authenticator app, then enter the
              code that shows.
            </StepIntroductionTypography>
            <QRCode value={qrCodeContent} />
            <QrCodeSecretContainer>
              Or copy and paste the secret code into your app:{' '}
              <QrCodeSecret>{totpSecret}</QrCodeSecret>
              <CopyToClipboardButton text={totpSecret} />
            </QrCodeSecretContainer>
          </StepIntroduction>
          {isError ? (
            error?.[0]?.extensions?.code === 'INVALID_MFA_CODE' ||
            error?.[0]?.extensions?.code === 'EXPIRED_MFA_CODE' ? (
              <Alert severity="error">{error[0].message}</Alert>
            ) : (
              <ServerError isVisible={true} />
            )
          ) : null}{' '}
          <StepFormContainer>
            <TextField
              name="verificationCode"
              label="Verification code"
              inputProps={{ inputMode: 'decimal' }}
              autoFocus
            />
          </StepFormContainer>
        </StepContent>
        <StepActionsVerificationCode>
          <FormState>
            {({ isSubmitting }) => (
              <>
                <StepButton
                  type="submit"
                  className="magenta"
                  disabled={isSubmitting}
                >
                  Continue
                </StepButton>
              </>
            )}
          </FormState>
        </StepActionsVerificationCode>
      </Form>
    </StepContainer>
  );
};
