import { ButtonsWrapper } from 'components/ButtonsWrapper';
import { LinkCustomButton } from 'components/design-system/Button/CustomButtonV2';
import { StyledLink } from 'components/design-system/Link';
import { QueryState } from 'components/QueryState';
import { GaEventNames } from 'constants/gaConstants';
import {
  AccountStatus,
  TransfersQuery,
  TransferStatus,
  useAccountsQuery,
  useTransfersQuery,
  WrapperType,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { dashboardTransfersPath, generateStartTransferPath } from 'paths';
import { useIntercom } from 'react-use-intercom';
import ActiveTransferCard from './ActiveTransferCard';
import CompletedTransferList from './CompletedTransferList';
import {
  AccountsElsewhere,
  AccountsElsewhereText,
  AccountsElsewhereTitle,
  ActiveTransfersListWrapper,
  Title,
  TransfersDetails,
} from './TransfersSection.styles';

type TransferType = TransfersQuery['transfers'][number];

const TransferStatusOpen = [
  TransferStatus.Created,
  TransferStatus.InProgress,
  TransferStatus.Problem,
  TransferStatus.Unknown, //todo - check this
];

const TransferStatusComplete = [
  TransferStatus.Cancelled,
  TransferStatus.Completed,
];

interface ActiveTransfersListProps {
  transfers: TransferType[];
}

function ActiveTransfersList({ transfers }: ActiveTransfersListProps) {
  return (
    <ActiveTransfersListWrapper>
      {transfers.map((transfer) => (
        <ActiveTransferCard key={transfer.id} transfer={transfer} />
      ))}
    </ActiveTransfersListWrapper>
  );
}

export default function TransfersSection() {
  const transfersState = useTransfersQuery();
  const accountsQuery = useAccountsQuery();
  const { showNewMessages } = useIntercom();

  const handleTransferPension = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'dashboard',
      item_id: 'Start Transfer - Pension',
    });

    showNewMessages(
      'I would like to transfer another pension into to my TILLIT Pension.'
    );
  };

  return (
    <TransfersDetails>
      {/* <TransfersDetails> */}
      <Title variant="h2" align="center">
        Transfers
      </Title>
      <QueryState {...transfersState}>
        {({ data }) =>
          data?.transfers && (
            <>
              <ActiveTransfersList
                transfers={data?.transfers.filter((transfer) =>
                  TransferStatusOpen.includes(transfer.status)
                )}
              />
              <CompletedTransferList
                transfers={data?.transfers.filter((transfer) =>
                  TransferStatusComplete.includes(transfer.status)
                )}
              />
            </>
          )
        }
      </QueryState>

      <AccountsElsewhere>
        <AccountsElsewhereTitle variant="h3" align="center">
          Have accounts elsewhere?
        </AccountsElsewhereTitle>
        <AccountsElsewhereText align="center">
          You can transfer your Pension, Stocks &amp; Shares ISA and GIA from
          another platform to TILLIT in just a few clicks. Select the account
          you want to transfer to get started or find out more{' '}
          <StyledLink href="https://tillitinvest.com/transfers" target="_blank">
            here
          </StyledLink>
          .
        </AccountsElsewhereText>
        <ButtonsWrapper $isHorizontal={true}>
          <LinkCustomButton
            to={generateStartTransferPath({ accountType: 'isa' })}
            $color="purple"
          >
            Stocks &amp; Shares ISA
          </LinkCustomButton>
          <LinkCustomButton
            to={generateStartTransferPath({ accountType: 'gia' })}
            $color="purple"
          >
            General investment account
          </LinkCustomButton>
          <QueryState {...accountsQuery}>
            {(data) =>
              data?.data?.accounts?.find(
                (x) =>
                  x.wrapperType === WrapperType.Sipp &&
                  x.status === AccountStatus.Active
              ) ? (
                <LinkCustomButton
                  to={generateStartTransferPath({ accountType: 'pension' })}
                  $color="purple"
                >
                  Pension
                </LinkCustomButton>
              ) : (
                <LinkCustomButton
                  $color="purple"
                  onClick={handleTransferPension}
                  to={dashboardTransfersPath}
                >
                  Pension
                </LinkCustomButton>
              )
            }
          </QueryState>
        </ButtonsWrapper>
      </AccountsElsewhere>
    </TransfersDetails>
  );
}
