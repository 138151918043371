import React from 'react';
import {
  BlurredCopy,
  ContentWrapper,
  CopyContent,
  Title,
} from './Styles/ContentSection.style';

interface ContentSectionProps {
  title: string | null;
  content: string | JSX.Element;
  type: string;
  hideContent?: boolean;
  featured?: boolean;
  featuredTitle?: boolean;
  longHiddenContent?: boolean;
}

function HiddenContentSection({
  longHiddenContent = false,
}: Pick<ContentSectionProps, 'longHiddenContent'>) {
  //spell-checker: disable
  const getFakeCopy = (longHiddenContent: boolean) => {
    if (longHiddenContent) {
      return (
        <>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Optio,
            aperiam, dicta voluptatibus molestias eaque officia facere,
            consequatur cumque quis unde dolores expedita sed tempora animi ex
            vero itaque veniam aliquam. adipisicing elit. Optio, aperiam, dicta
            voluptatibus molestias eaque officia.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Optio,
            aperiam, dicta voluptatibus molestias eaque officia facere.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Optio,
            aperiam, dicta voluptatibus molestias eaque officia facere,
            consequatur cumque quis unde dolores expedita sed tempora animi ex
            vero itaque veniam aliquam.
          </p>
        </>
      );
    } else {
      return (
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Optio,
          aperiam, dicta voluptatibus molestias eaque officia facere,
          consequatur cumque quis unde dolores expedita sed tempora animi ex
          vero itaque veniam aliquam.
        </p>
      );
    }
  };

  //spell-checker: enable
  return (
    <CopyContent>
      <BlurredCopy>{getFakeCopy(longHiddenContent)}</BlurredCopy>
    </CopyContent>
  );
}

function ShowContentSection({
  content,
  type,
}: Pick<ContentSectionProps, 'content' | 'type'>) {
  if (type === 'TEXT_HTML') {
    return (
      <CopyContent
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></CopyContent>
    );
  } else if (type === 'JSX') {
    return <CopyContent>{content}</CopyContent>;
  } else {
    return (
      <CopyContent>
        <p>{content}</p>
      </CopyContent>
    );
  }
}

export function ContentSection({
  title,
  content,
  type,
  hideContent = false,
  featured = false,
  featuredTitle = false,
  longHiddenContent = false,
}: ContentSectionProps) {
  return (
    <ContentWrapper $featured={featured}>
      {title && (
        <Title variant="h3" $featured={featuredTitle}>
          {title}
        </Title>
      )}
      {hideContent ? (
        <HiddenContentSection longHiddenContent={longHiddenContent} />
      ) : (
        <ShowContentSection content={content} type={type} />
      )}
    </ContentWrapper>
  );
}
