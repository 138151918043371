import { assetClassColors, colors } from 'constants/colors';
import styled, { css } from 'styled-components';

interface HeadingProps {
  $noMargin?: boolean;
  $textAlign?: TextAlign;
}

export enum TextAlign {
  center = 'CENTER',
  left = 'LEFT',
}
const textAlignLookup = {
  [TextAlign.center]: 'center',
  [TextAlign.left]: 'left',
};

const baseHeadingCss = css<HeadingProps>`
  color: ${({ $isDarkBg }) => ($isDarkBg ? colors.white : colors.richBlue)};
  font-family: 'GT Super', serif;
  font-weight: 300;
  a {
    color: ${colors.richBlue};
    text-decoration: none;
  }
  a:visited {
    color: ${colors.richBlue};
  }
  a:hover {
    color: ${colors.magentaSafe};
  }
`;

const underline = css<HeadingProps>`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    ${(p) =>
      p.$textAlign && p.$textAlign === TextAlign.center
        ? 'left: calc(50% - 20px);'
        : 'left: 0;'}

    width: 40px;
    height: 1px;
    background-color: ${colors.magenta};
  }
`;

export type assetClasses = keyof typeof assetClassColors;
interface HeadingProps {
  assetClass?: assetClasses;
  darkUniverse?: boolean;
  $isDarkBg?: boolean;
}

const assetColouring = css<HeadingProps>`
  ${({ assetClass, darkUniverse }) =>
    darkUniverse
      ? `color: ${colors.white};`
      : assetClass
      ? `color: ${assetClassColors[assetClass]};`
      : ''}
  a, a:visited, a:hover, a:focus {
    ${({ assetClass, darkUniverse }) =>
      darkUniverse
        ? `color: ${colors.white};`
        : assetClass
        ? `color: ${assetClassColors[assetClass]};`
        : ''}
  }
`;

export const H1Xl = styled.h1<HeadingProps>`
  padding: 0 0 1rem 0;
  font-size: 3.25rem;
  ${(p) => (p.$noMargin ? 'margin: 0;' : 'margin: 0 0 1.5rem 0;')}
  ${baseHeadingCss}
  ${underline}
  ${assetColouring}
  ${(p) =>
    p.$textAlign ? `text-align: ${textAlignLookup[p.$textAlign]}` : ''};
`;

export const H1Large = styled.h1<HeadingProps>`
  padding: 0 0 1rem 0;
  ${(p) => (p.$noMargin ? 'margin: 0;' : 'margin: 0 0 1.5rem 0;')}
  font-size: 2.5rem;
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 3rem;
  }
  ${baseHeadingCss}
  ${underline}
  ${assetColouring}
  ${(p) =>
    p.$textAlign ? `text-align: ${textAlignLookup[p.$textAlign]}` : ''};
`;

export const H1 = styled.h1<HeadingProps>`
  ${(p) => (p.$noMargin ? 'margin: 0;' : 'margin: 0 0 0.75rem 0;')}
  font-size: 2rem;
  ${baseHeadingCss}
  ${assetColouring}
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 2.5rem;
  }
  ${(p) =>
    p.$textAlign ? `text-align: ${textAlignLookup[p.$textAlign]}` : ''};
`;

export const H2 = styled.h2<HeadingProps>`
  ${(p) => (p.$noMargin ? 'margin: 0;' : 'margin: 0 0 0.5rem 0;')}
  font-size: 2.5rem;
  ${baseHeadingCss}
  ${assetColouring}
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 2.5rem;
  }
  ${(p) =>
    p.$textAlign ? `text-align: ${textAlignLookup[p.$textAlign]}` : ''};
`;

export const H3 = styled.h3<HeadingProps>`
  ${(p) => (p.$noMargin ? 'margin: 0;' : 'margin: 0 0 0.5rem 0;')}
  ${baseHeadingCss}
  ${assetColouring}

  font-size: 2rem;
  ${(p) =>
    p.$textAlign ? `text-align: ${textAlignLookup[p.$textAlign]}` : ''};
`;

export const H3Bold = styled.h3<HeadingProps>`
  ${(p) => (p.$noMargin ? 'margin: 0;' : 'margin: 0 0 0.5rem 0;')}
  ${baseHeadingCss}
  font-weight: 400;
  font-size: 2rem;
  ${assetColouring}
  ${(p) =>
    p.$textAlign ? `text-align: ${textAlignLookup[p.$textAlign]}` : ''};
`;

export const H4 = styled.h4<HeadingProps>`
  margin: 0.5rem 0;
  ${(p) => (p.$noMargin ? 'margin: 0;' : 'margin: 0 0 0.5rem 0;')}
  ${baseHeadingCss}
  ${assetColouring}
  font-size: 1.75rem;
  ${(p) =>
    p.$textAlign ? `text-align: ${textAlignLookup[p.$textAlign]}` : ''};
`;

export const H5 = styled.h5<HeadingProps>`
  ${(p) => (p.$noMargin ? 'margin: 0;' : 'margin: 0 0 0.5rem 0;')}
  ${baseHeadingCss}
  ${assetColouring}
  font-size: 1.5rem;
  ${(p) =>
    p.$textAlign ? `text-align: ${textAlignLookup[p.$textAlign]}` : ''};
`;

export const H6 = styled.h6<HeadingProps>`
  ${(p) => (p.$noMargin ? 'margin: 0;' : 'margin: 0 0 0.5rem 0;')}
  ${baseHeadingCss}
  ${assetColouring}
  font-size: 1.25rem;
  ${(p) =>
    p.$textAlign ? `text-align: ${textAlignLookup[p.$textAlign]}` : ''};
`;

export const H6Underline = styled.h6<HeadingProps>`
  ${(p) => (p.$noMargin ? 'margin: 0;' : 'margin: 0 0 0.5rem 0;')}
  ${baseHeadingCss}
  ${assetColouring}
  ${underline}
  font-size: 1.25rem;
  padding-bottom: 0.75rem;
  ${(p) =>
    p.$textAlign ? `text-align: ${textAlignLookup[p.$textAlign]}` : ''};
`;

export const H6Bold = styled.h6<HeadingProps>`
  ${(p) => (p.$noMargin ? 'margin: 0;' : 'margin: 0 0 0.5rem 0;')}
  ${baseHeadingCss}
  ${assetColouring}
  font-size: 1.25rem;
  font-weight: 400;
  ${(p) =>
    p.$textAlign ? `text-align: ${textAlignLookup[p.$textAlign]}` : ''};
`;
