import Kfd24 from 'assets/ISA_KFD_2024.pdf';
import { AffirmationKind } from 'generated/graphql';

export const useIsaRuleset = () => {
  const affirmationsRequired = [AffirmationKind.ReadAndUnderstoodIsaKfd];

  return {
    affirmationsRequired,
    kfd: Kfd24,
    declarationPdf:
      'https://d1ur1kq4xhe5pz.cloudfront.net/images/ISA-Declaration-2024-2025.pdf',
  };
};
