import { LocationKeyedSwitch } from 'components/LocationKeyedSwitch';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  CashDepositTransferDetails,
  CashPaymentByIdQuery,
  PaymentMethodInterfaceType,
  PaymentMethodKind,
  PaymentMethodStatus,
  PaymentStatus,
  WrapperType,
} from 'generated/graphql';

import { QueryState } from 'components/QueryState';
import { createPopup } from 'helpers/createPopup';
import { trackGa, trackGaProperties } from 'helpers/track';
import { isOpenBankingPaymentMethod } from 'helpers/type-guards';
import { createMemoryHistory } from 'history';
import { useToggle } from 'hooks/useFeatureToggle';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Route, Router } from 'react-router-dom';
import { AmountStep, DepositFormValues } from './AmountStep';
import { AutoInvestStep, proceedValues } from './AutoInvestStep';
import { DepositFormVariant } from './DepositFormVariant';
import { ManualTransferRoutes } from './ManualTransferFlow/ManualTransferRoutes';
import { TrueLayerRoutes } from './TrueLayerFlow/TrueLayerRoutes';

export interface DepositWizardProps {
  wrapperType: WrapperType;
  cashBalance: number;
  accountId: string;
  minAmount: number;
  maxAmount: number;
  onCreateDeposit: (
    amount: number,
    paymentMethod: PaymentMethodKind,
    popup?: Window,
    autoInvest?: boolean
  ) => Promise<void> | void;
  transferDetails: CashDepositTransferDetails;
  onDepositAction: (deferred: boolean) => void;
  onClose: (amount?: number) => void;
  paymentMethods: Array<PaymentMethodInterfaceType>;
  onPopupClosed: () => void;
  cashPayment: CashPaymentByIdQuery['cashPaymentById'];
  onContactSupport: () => void;
  onDashboard: () => void;
  onBrowseFunds: () => void;
  redirectUri: string | null;
  onScrollToTop: () => void;
  timedOut: boolean;
  variant: DepositFormVariant;
  isPolling?: boolean;
  displayBuffer?: boolean;
  onSkipStep?: () => void;
  proceedOnComplete?: boolean;
  defaultValue?: number | null;
  isCheckout?: boolean;
  urlPaymentId: string | undefined | null;
}

export function DepositWizard({
  wrapperType,
  cashBalance,
  accountId,
  minAmount,
  maxAmount,
  onCreateDeposit,
  transferDetails,
  onDepositAction,
  onClose,
  paymentMethods,
  onPopupClosed,
  cashPayment,
  onContactSupport,
  onDashboard,
  onBrowseFunds,
  redirectUri,
  onScrollToTop,
  timedOut,
  displayBuffer = false,
  variant,
  isPolling = false,
  urlPaymentId,
  onSkipStep,
  proceedOnComplete = false,
  defaultValue,
  isCheckout = false,
}: DepositWizardProps) {
  const [depositAmount, setDepositAmount] = useState<null | number>(null);
  const [
    storedPaymentMethod,
    setStoredPaymentMethod,
  ] = useState<null | PaymentMethodKind>(null);
  const [submitFailed, setSubmitFailed] = useState(false);
  const trueLayerWindow = useRef<Window | null>(null);
  const [waitingForCallback, setWaitingForCallback] = useState(false);

  /**
   * Only track GA events for onboarding if the user is creating a new account
   */
  const onboardingTrackGa = useCallback(
    (properties: trackGaProperties) => {
      if (variant === DepositFormVariant.NewAccount) {
        trackGa(properties);
      }
    },
    [variant]
  );

  const history = useMemo(
    () =>
      createMemoryHistory({
        initialEntries: !urlPaymentId
          ? ['/amount']
          : ['/truelayer/waiting-for-payment'],
      }),
    [urlPaymentId]
  );

  const openBankingPaymentMethod = paymentMethods.find(
    isOpenBankingPaymentMethod
  );

  const openPopup = useCallback(() => {
    trueLayerWindow.current = createPopup(() => {
      if (window.location.host === trueLayerWindow.current?.location.host) {
        onPopupClosed();
        trueLayerWindow.current = null;
        setWaitingForCallback(false);
      }
    });
  }, [onPopupClosed]);

  const [redirectFlowFeature, togglesQuery] = useToggle(
    'global-truelayer-redirect-flow'
  );

  const initiateTrueLayerPayment = useCallback(
    async (
      amount?: number,
      afterPopup?: () => Promise<void> | void,
      autoInvest?: boolean
    ) => {
      if (trueLayerWindow.current && !trueLayerWindow.current.closed) {
        trueLayerWindow.current.focus();
      } else {
        try {
          submitFailed && setSubmitFailed(false);

          if (!redirectFlowFeature?.enabled) {
            openPopup();

            setWaitingForCallback(true);
          }

          await afterPopup?.();

          await onCreateDeposit(
            amount ?? 0,
            PaymentMethodKind.OpenBanking,
            trueLayerWindow.current!,
            autoInvest || false
          );

          if (!redirectFlowFeature?.enabled) {
            history.push('/truelayer/new');
          }
        } catch {
          setSubmitFailed(true);
          setWaitingForCallback(false);
          trueLayerWindow.current?.close();
          trueLayerWindow.current = null;
        }
      }
    },
    [
      history,
      onCreateDeposit,
      openPopup,
      redirectFlowFeature?.enabled,
      submitFailed,
    ]
  );

  const handleDepositCash = useCallback(
    async (autoInvest?: boolean) => {
      if (storedPaymentMethod === PaymentMethodKind.OpenBanking) {
        if (
          openBankingPaymentMethod?.status ===
          PaymentMethodStatus.TemporarilyDisabled
        ) {
          history.push('/truelayer/temporarilyUnavailable');
        } else if (
          !openBankingPaymentMethod?.everSucceeded ||
          !openBankingPaymentMethod.providerId
        ) {
          history.push('/truelayer/selectProvider');
        } else {
          await initiateTrueLayerPayment(depositAmount!, undefined, autoInvest);
        }
      } else {
        try {
          await onCreateDeposit(
            depositAmount!,
            PaymentMethodKind.ManualBankTransfer,
            undefined,
            autoInvest || false
          );
          history.push('/manual/summary');
        } catch {
          setSubmitFailed(true);
        }
      }
    },
    [
      depositAmount,
      history,
      initiateTrueLayerPayment,
      onCreateDeposit,
      openBankingPaymentMethod,
      storedPaymentMethod,
    ]
  );

  const handleStoreAmountSubmit = useCallback(
    async (values: DepositFormValues, paymentMethod?: PaymentMethodKind) => {
      setDepositAmount(values.amount);
      setStoredPaymentMethod(paymentMethod!);
      if (submitFailed) {
        setSubmitFailed(false);
      }
      history.push('/amount-options');
    },
    [history, submitFailed]
  );

  const handleAmountSubmit = useCallback(
    async (values: DepositFormValues, paymentMethod?: PaymentMethodKind) => {
      setDepositAmount(values.amount);
      setStoredPaymentMethod(paymentMethod!);

      if (submitFailed) {
        setSubmitFailed(false);
      }

      if (values.autoInvest) {
        history.push('/amount-options');
      } else if (paymentMethod === PaymentMethodKind.OpenBanking) {
        if (
          openBankingPaymentMethod?.status ===
          PaymentMethodStatus.TemporarilyDisabled
        ) {
          history.push('/truelayer/temporarilyUnavailable');
        } else if (
          !openBankingPaymentMethod?.everSucceeded ||
          !openBankingPaymentMethod.providerId
        ) {
          history.push('/truelayer/selectProvider');
        } else {
          await initiateTrueLayerPayment(values.amount);
        }
      } else {
        try {
          await onCreateDeposit(
            values.amount,
            PaymentMethodKind.ManualBankTransfer
          );

          history.push('/manual/summary');
        } catch {
          setSubmitFailed(true);
        }
      }
    },
    [
      history,
      onCreateDeposit,
      openBankingPaymentMethod,
      submitFailed,
      initiateTrueLayerPayment,
    ]
  );

  useEffect(() => {
    if (redirectUri && trueLayerWindow.current) {
      trueLayerWindow.current.location.href = redirectUri;
    }
  }, [redirectUri]);

  useEffect(() => {
    switch (cashPayment?.status) {
      case PaymentStatus.Transferring:
        trueLayerWindow.current?.close();
        if (isPolling) {
          if (timedOut) {
            history.push('/truelayer/error-waiting-for-payment');
          } else {
            history.push('/truelayer/waiting-for-payment');
          }
        } else {
          if (timedOut) {
            history.push('/truelayer/transferring');
            onboardingTrackGa({
              event: GaEventNames.onboarding,
              onboardingStep: OnboardingStepNames.addCash,
              addCashStatus: 'transferring',
            });
          }
        }
        break;
      case PaymentStatus.Completed:
        trueLayerWindow.current?.close();
        history.push('/truelayer/completed');
        setDepositAmount(cashPayment.amount);
        onboardingTrackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.addCash,
          addCashStatus: 'completed',
        });
        break;
      case PaymentStatus.Cancelled:
        trueLayerWindow.current?.close();
        history.push('/truelayer/cancelled');
        setDepositAmount(cashPayment.amount);
        onboardingTrackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.addCash,
          addCashStatus: 'cancelled',
        });
        break;
      case PaymentStatus.Failed:
        trueLayerWindow.current?.close();
        history.push('/truelayer/failed');
        setDepositAmount(cashPayment.amount);
        onboardingTrackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.addCash,
          addCashStatus: 'failed',
        });
        break;
    }
  }, [
    cashPayment?.status,
    cashPayment?.amount,
    history,
    timedOut,
    onboardingTrackGa,
    isPolling,
    onClose,
  ]);

  const handleDepositAction = (deferred: boolean) => {
    onDepositAction(deferred);
    history.push('/manual/acknowledgment');
  };

  const handleTryAgain = () => {
    setWaitingForCallback(false);
    history.go(1 - history.length);
  };

  const handleRetryPopup = () => {
    if (trueLayerWindow.current && !trueLayerWindow.current.closed) {
      trueLayerWindow.current.focus();
    } else {
      openPopup();
      if (trueLayerWindow.current && redirectUri) {
        trueLayerWindow.current.location.href = redirectUri;
      }
    }
  };

  const handlePayManually = async () => {
    try {
      setWaitingForCallback(false);
      trueLayerWindow.current?.close();
      trueLayerWindow.current = null;
      submitFailed && setSubmitFailed(false);

      await onCreateDeposit(
        depositAmount!,
        PaymentMethodKind.ManualBankTransfer
      );

      history.push('/manual/summary');
    } catch {
      setSubmitFailed(true);

      history.push('/amount');
    }
  };

  const handleOptionsProceed = async (data: proceedValues) => {
    if (data.option === 'keepCash') {
      handleDepositCash();
    } else {
      handleDepositCash(true);
    }
  };

  const handleGoBack = () => {
    history.push('/amount');
  };

  const handleProviderSelected = async (
    manual: boolean,
    afterPopup?: () => Promise<void> | void
  ) => {
    if (manual) {
      await afterPopup?.();
      history.push('/manual/summary');
    } else {
      initiateTrueLayerPayment(depositAmount!, afterPopup);
    }
  };

  useEffect(() => {
    if (submitFailed) {
      onScrollToTop();
    }
  }, [onScrollToTop, submitFailed]);

  return (
    <QueryState {...togglesQuery}>
      {() => (
        <Router history={history}>
          <LocationKeyedSwitch>
            <Route path="/amount">
              <AmountStep
                accountId={accountId}
                wrapperType={wrapperType}
                defaultAmount={defaultValue || depositAmount}
                cashBalance={cashBalance}
                minAmount={minAmount}
                maxAmount={maxAmount}
                onSubmit={
                  wrapperType === WrapperType.Sipp && !isCheckout
                    ? handleStoreAmountSubmit
                    : handleAmountSubmit
                }
                showServerError={submitFailed}
                paymentMethods={paymentMethods}
                variant={variant}
                displayBuffer={displayBuffer}
                onSkipStep={() => {
                  onboardingTrackGa({
                    event: GaEventNames.onboarding,
                    onboardingStep: OnboardingStepNames.addCash,
                    addCashStatus: 'skipped',
                  });
                  onSkipStep?.();
                }}
                isCheckout={isCheckout}
              />
            </Route>
            <Route path="/amount-options">
              <AutoInvestStep
                wrapperType={wrapperType}
                accountId={accountId}
                amount={depositAmount!}
                onProceed={handleOptionsProceed}
                onBack={handleGoBack}
              />
            </Route>
            <Route path="/manual">
              <ManualTransferRoutes
                amount={depositAmount ?? 0}
                transferDetails={transferDetails}
                onConfirmed={() => handleDepositAction(false)}
                onDeferred={() => handleDepositAction(true)}
                onBrowseFunds={onBrowseFunds}
                onDashboard={onDashboard}
                onClose={() => onClose(depositAmount!)}
              />
            </Route>
            <Route path="/truelayer">
              <TrueLayerRoutes
                amount={depositAmount ?? 0}
                transferDetails={transferDetails}
                onClose={() => onClose(depositAmount!)}
                onContactSupport={onContactSupport}
                onTryAgain={handleTryAgain}
                onBrowseFunds={onBrowseFunds}
                onDashboard={onDashboard}
                onRetryPopup={handleRetryPopup}
                waitingForCallback={waitingForCallback}
                onPayManually={handlePayManually}
                onTryLater={() => onClose(depositAmount!)}
                onProviderSelected={handleProviderSelected}
                showServerError={submitFailed}
                onScrollToTop={onScrollToTop}
                proceedOnComplete={proceedOnComplete}
                autoInvested={cashPayment?.autoInvest || false}
              />
            </Route>
          </LocationKeyedSwitch>
        </Router>
      )}
    </QueryState>
  );
}
