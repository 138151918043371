import { QueryState } from 'components/QueryState';
import {
  StepActions,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroductionTypography,
  StepText,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { TextAlign, TextNormal } from 'components/design-system/Text/Text';
import { ServerError } from 'components/feature/openAccount/steps/_shared/ServerError';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  useUpdateUserProfileMutation,
  useUserProfileOnboardingQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { useQueryClient } from 'react-query';
import { FormActions } from '../_shared/FormActions';
import {
  EscalationCard,
  EscalationControl,
  EscalationHeading,
  EscalationPercentage,
  OptionsContainer,
  StepIntroductionEmTypography,
} from './AutoEscalationStepStyles';

interface AutoEscalationStepProps {
  onProceed: () => void;
  onGoBack: () => void;
  source?: 'openAccountStep';
}

interface AutoEscalationStepFormProps extends AutoEscalationStepProps {
  annualEscalationPercentage: number;
}

function AutoEscalationStepForm({
  onProceed,
  onGoBack,
  annualEscalationPercentage,
  source,
}: AutoEscalationStepFormProps) {
  const [speed, setSpeed] = useState(annualEscalationPercentage);
  const queryClient = useQueryClient();

  const { mutateAsync, isError, isLoading } = useUpdateUserProfileMutation();

  const onStepProceed = async () => {
    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.pensionIntroduction,
    });
    try {
      const annualEscalationProportion = speed / 100;
      await mutateAsync({
        input: {
          pension: {
            autoEscalation: {
              annualEscalationProportion,
              durationYears: annualEscalationProportion === 0 ? null : 3,
              startDate:
                annualEscalationProportion === 0
                  ? null
                  : new Date().toISOString(),
            },
          },
        },
      });

      queryClient.invalidateQueries(useUserProfileOnboardingQuery.getKey());

      if (source === 'openAccountStep') {
        trackGa({
          event: GaEventNames.onboarding,
          onboardingStep: OnboardingStepNames.autoEscalation,
        });
      }

      onProceed();
    } catch {
      // handled with state
    }
  };

  const handleIncreaseSpeed = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (speed < 3) {
      setSpeed(speed + 1);
    }
  };

  const handleDecreaseSpeed = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (speed > 0) {
      setSpeed(speed - 1);
    }
  };

  return (
    <StepContainer>
      <StepTitle>Auto-escalation</StepTitle>
      <StepContent width={StepContentWidth.normal}>
        <StepText>
          <StepIntroductionTypography>
            Auto-escalation is a way of saving more in the future by setting up
            an automatic increase over the next three years today.
          </StepIntroductionTypography>

          <StepIntroductionEmTypography>
            Example: If you're contributing 5% of your salary right now, in 12
            months we'll automatically increase it to 6%, then 7%, and then 8%.
          </StepIntroductionEmTypography>
        </StepText>
      </StepContent>
      <StepContent width={StepContentWidth.extraWide}>
        <OptionsContainer>
          <EscalationControl
            onClick={handleDecreaseSpeed}
            disabled={speed < 1 || isLoading}
          >
            <AiOutlineMinus />
          </EscalationControl>
          <EscalationCard>
            <EscalationHeading $noMargin>Increase by</EscalationHeading>
            <EscalationPercentage $noMargin>{speed}%</EscalationPercentage>
            <TextNormal $noMargin $textAlign={TextAlign.center}>
              {speed === 0
                ? 'We will not increase your contributions'
                : 'every year, for the next 3 years'}
            </TextNormal>
          </EscalationCard>
          <EscalationControl
            onClick={handleIncreaseSpeed}
            disabled={speed > 2 || isLoading}
          >
            <AiOutlinePlus />
          </EscalationControl>
        </OptionsContainer>
      </StepContent>
      <StepContent width={StepContentWidth.normal}>
        <StepText>
          <StepIntroductionTypography>
            You can change your auto-escalation rate at any time by getting in
            touch with us.
          </StepIntroductionTypography>
        </StepText>
      </StepContent>
      <ServerError isVisible={isError} />
      <StepActions>
        <FormActions
          onProceed={onStepProceed}
          onGoBack={onGoBack}
          disabled={isLoading}
        />
      </StepActions>
    </StepContainer>
  );
}

export function AutoEscalationStep({
  onProceed,
  onGoBack,
  source,
}: AutoEscalationStepProps) {
  const userProfileOnboardingQuery = useUserProfileOnboardingQuery();

  const autoEscalationDetails =
    userProfileOnboardingQuery.data?.userProfile?.pensionDetails
      ?.autoEscalation;

  const annualEscalationPercentage =
    (autoEscalationDetails?.annualEscalationProportion ?? 0.01) * 100;

  return (
    <QueryState {...userProfileOnboardingQuery}>
      {() => (
        <AutoEscalationStepForm
          onProceed={onProceed}
          onGoBack={onGoBack}
          source={source}
          annualEscalationPercentage={annualEscalationPercentage}
        />
      )}
    </QueryState>
  );
}
