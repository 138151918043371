import sustainabilityFocusSrc from 'assets/sdr-logos/focus.png';
import sustainabilityImpactSrc from 'assets/sdr-logos/impact.png';
import sustainabilityImproversSrc from 'assets/sdr-logos/improvers.png';
import sustainabilityMixedSrc from 'assets/sdr-logos/mixed-goals.png';
import { StyledA } from 'components/design-system/Link';
import { TextSmall } from 'components/design-system/Text/Text';
import { fcaSustainableInvestmentLabelsGreenwashing } from 'paths';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { AssetQueryAsset, AssetQueryAssetTag } from 'types/graphqlTypes';
import { SdrImg, SdrWrapper } from './SdrInfo.styles';

interface SdrImgProps {
  tag: AssetQueryAssetTag;
}
const SdrTag = ({ tag }: SdrImgProps) => {
  const lookup: { [key: string]: string } = {
    SDRFOCUS: sustainabilityFocusSrc,
    SDRIMPACT: sustainabilityImpactSrc,
    SDRIMPROVERS: sustainabilityImproversSrc,
    SDRMIXED: sustainabilityMixedSrc,
  };

  if (!lookup[tag.code]) {
    return null;
  }

  return <SdrImg src={lookup[tag.code]} />;
};

interface SdrInfoProps {
  asset: AssetQueryAsset;
}

export function SdrInfo({ asset }: SdrInfoProps) {
  const sdrTags = asset.tags.nodes.filter(
    ({ categoryCode, code }) =>
      categoryCode === 'SDR' && code !== 'SDROUTOFSCOPE'
  );

  const isOutOfSdrScope = asset.instruments.nodes.some(
    (instrument) => !instrument.isin.startsWith('GB')
  );

  return (
    <SdrWrapper>
      {isOutOfSdrScope && (
        <TextSmall $noMargin>
          This overseas fund is not subject to the{' '}
          <StyledA
            href={fcaSustainableInvestmentLabelsGreenwashing}
            target="_blank"
          >
            UK's Sustainability disclosure and labelling regime{' '}
            <FaExternalLinkAlt />
          </StyledA>{' '}
          and its requirements.
        </TextSmall>
      )}
      {sdrTags.map((tag) => (
        <SdrTag tag={tag} />
      ))}
    </SdrWrapper>
  );
}
