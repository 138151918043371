import {
  ResponsiveDialog,
  ResponsiveDialogProps,
} from 'components/Dialogs/ResponsiveDialog';
import {
  ConfirmReferralStep,
  returnData,
} from 'components/feature/signIn/steps/ConfirmReferralStep/ConfirmReferralStep';
import {
  useUserProfileOnboardingQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { useState } from 'react';
import { useQueryClient } from 'react-query';

interface ReferralConfirmationDialogProps {
  open: ResponsiveDialogProps['open'];
  referralCode: string;
  closeDialog: () => void;
  onProceed?: (employerName?: string) => void;
}

export function ReferralConfirmationDialog({
  open,
  referralCode,
  closeDialog,
  onProceed,
}: ReferralConfirmationDialogProps) {
  const queryClient = useQueryClient();

  const handleProceed = async (data: string) => {
    onProceed?.(data);
    await Promise.all([
      queryClient.invalidateQueries(useUserProfileQuery.getKey()),
      queryClient.invalidateQueries(useUserProfileOnboardingQuery.getKey()),
    ]);
    closeDialog();
  };

  const handleCloseDialog = () => {
    closeDialog();
  };

  return (
    <ResponsiveDialog open={open} onClose={handleCloseDialog}>
      <ConfirmReferralStep
        mode="dialog"
        referralCode={referralCode}
        onProceed={(data: returnData) => handleProceed(data.employerName || '')}
        onBack={handleCloseDialog}
      />
    </ResponsiveDialog>
  );
}

export const useDialog = () => {
  const [open, setOpen] = useState(false);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return { open, openDialog, closeDialog };
};
