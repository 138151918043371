import { QueryState } from 'components/QueryState';
import { StartTransferForm } from 'components/feature/Transfers/startTransferForm';
import { useActionsQuery, useUserProfileQuery } from 'generated/graphql';
import { useParams } from 'react-router-dom';
import { ActionsQueryAction } from 'types/graphqlTypes';

export interface TransferStepProps {
  onProceed: (action: ActionsQueryAction) => void;
}

export function TransferStep({ onProceed }: TransferStepProps) {
  const userProfile = useUserProfileQuery();

  const { actionId } = useParams<{ actionId: string }>();
  const actionsQuery = useActionsQuery();
  const startTransferAction = actionsQuery.data?.actions?.find(
    ({ id }) => id === actionId
  );

  const accounts = userProfile.data?.clientSummary?.accounts ?? [];
  const account = startTransferAction?.accountId
    ? accounts.find(({ id }) => id === startTransferAction.accountId)
    : undefined;

  return (
    <QueryState {...userProfile}>
      {() => (
        <QueryState {...actionsQuery}>
          {() => {
            if (!account) {
              return;
            }
            return (
              <StartTransferForm
                wrapperType={account.wrapperType}
                onProceed={() => {
                  onProceed(startTransferAction!);
                }}
                source="openAccountStep"
                defaultValue={startTransferAction?.amount ?? undefined}
              />
            );
          }}
        </QueryState>
      )}
    </QueryState>
  );
}
