import { ActionType, WrapperType } from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import {
  actionsSetupMfa,
  autoSaveInvestBasePath,
  generateActionsTypePath,
  generateAutoSaveInvestPathPath,
  generateAutoSaveInvestSubPath,
} from 'paths';

interface PathProps {
  wrapperType?: WrapperType;
  actionId: string;
}

interface TextProps {
  wrapperType?: WrapperType;
}

export const actionSpecLookUp: Partial<
  {
    [key in ActionType]: {
      title: string | ((props: TextProps) => string);
      description: string | ((props: TextProps) => string);
      prompt: string | ((props: TextProps) => string);
      path?: string | ((props: PathProps) => string);
    };
  }
> = {
  // [ActionType.AddressUpload]: {
  //   title: 'Address Upload',
  //   description: 'We need you to upload some documents to verify your address.',
  //   prompt: 'Verify your address',
  // },
  [ActionType.AddCash]: {
    title: 'Add cash',
    description: 'Easily deposit cash via your banking app.',
    prompt: 'Add Cash',
    path: ({ actionId }) =>
      generateActionsTypePath({ actionType: 'add-cash', actionId }),
  },
  [ActionType.FundAccount]: {
    title: 'Fund account',
    description:
      'Now that your account is ready, how would you like to funds it?',
    prompt: 'fund account',
    path: ({ actionId }) =>
      generateActionsTypePath({ actionType: 'fund-account', actionId }),
  },
  [ActionType.PickInvestments]: {
    title: 'Pick your investments',
    description:
      'During onboarding you indicated that you would like to pick your own investments.',
    prompt: 'Pick your investments',
    path: ({ wrapperType }) =>
      wrapperType
        ? generateAutoSaveInvestPathPath({
            wrapperType: getPathSegmentForWrapperType(wrapperType),
          })
        : autoSaveInvestBasePath,
  },
  [ActionType.SetupMfa]: {
    title: 'Protect your account',
    description: 'Set up two-step verification to help secure your account.',
    prompt: 'Protect your account',
    path: actionsSetupMfa,
  },
  [ActionType.SetupRegularDeposit]: {
    title: 'Set up a regular deposit',
    description:
      'Make it easier to build your portfolio by setting up a regular deposit?',
    prompt: 'Set up a regular deposit',
    path: ({ wrapperType }) =>
      wrapperType
        ? generateAutoSaveInvestSubPath({
            wrapperType: getPathSegmentForWrapperType(wrapperType),
            action: 'create-deposit',
          })
        : autoSaveInvestBasePath,
  },
  [ActionType.StartTransfer]: {
    title: ({ wrapperType }) =>
      wrapperType
        ? `Consolidate your ${getShortNameForWrapperType(wrapperType)}s`
        : 'Consolidate your accounts',
    description: ({ wrapperType }) =>
      wrapperType
        ? `Transfer other ${getShortNameForWrapperType(wrapperType)}s to TILLIT`
        : 'Transfer other accounts to TILLIT',
    prompt: ({ wrapperType }) =>
      wrapperType
        ? `Transfer a ${getShortNameForWrapperType(wrapperType)}`
        : 'Transfer an account',
    path: ({ actionId }) =>
      generateActionsTypePath({ actionType: 'transfer', actionId }),
  },
  // [ActionType.ReviewAmountCashYoureHolding]: {
  //   title: "You're holding a lot of cash",
  //   description:
  //     'You are holding a lot of cash in your account - and that is fine - but...',
  //   prompt: 'Read more',
  // },
};
