import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { SetTotpMfaMethodSuccessStep } from './steps/SetTotpMfaMethodSuccessStep';
import { TotpQrCodeStep } from './steps/TotpQrCodeStep';

interface SetupTotpMfaFormProps {
  onProceed: () => void;
  onSkip?: () => void;
  totpSecret: string;
  proceedBtnText?: string;
}

enum SetupTotpMfaFormSteps {
  QrCode = 'QrCode',
  Success = 'Success',
}

export function SetupTotpMfaForm({
  totpSecret,
  onProceed,
  proceedBtnText = 'Continue',
}: SetupTotpMfaFormProps) {
  const [activeStep, setActiveStep] = useState(SetupTotpMfaFormSteps.QrCode);
  const [switchedFromSms, setSwitchedFromSms] = useState(false);

  const handleTotpVerified = (wasUsingSms: boolean) => {
    setActiveStep(SetupTotpMfaFormSteps.Success);
    setSwitchedFromSms(wasUsingSms);
  };

  return (
    <AnimatePresence>
      <>
        {activeStep === SetupTotpMfaFormSteps.QrCode && (
          <TotpQrCodeStep
            onProceed={handleTotpVerified}
            totpSecret={totpSecret}
          />
        )}
        {activeStep === SetupTotpMfaFormSteps.Success && (
          <SetTotpMfaMethodSuccessStep
            proceedBtnText={proceedBtnText}
            switchedFromSms={switchedFromSms}
            onProceed={onProceed}
          />
        )}
      </>
    </AnimatePresence>
  );
}
