import { Box } from '@material-ui/core';
import { CustomButton, LinkCustomButton } from 'components/Button/CustomButton';
import { ButtonsWrapper } from 'components/ButtonsWrapper';
import { Loading } from 'components/Loading';
import { QueryState } from 'components/QueryState';
import { OnboardingFeedback } from 'components/Rating/OnboardingFeedback/OnboardingFeedback';
import { UploadDocumentByRequestForm } from 'components/feature/uploadDocumentsByRequest/UploadDocumentByRequestForm';
import { colors } from 'constants/colors';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  AccountStatus,
  AccountType,
  ActionType,
  DocumentUploadRequestStatus,
  OnboardingStatus,
  OnboardingStatusQuery,
  Scalars,
  UserProfileQuery,
  WrapperType,
  useAccountEligibilityQuery,
  useAccountsQuery,
  useActionsQuery,
  useDocumentUploadRequestsQuery,
  useOnboardingStatusQuery,
  useUserProfileOnboardingQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { getNameForAccountType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import {
  actionsPath,
  dashboardPath,
  fundListPath,
  generateActionsTypePath,
} from 'paths';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

export enum Action {
  Continue = 'Continue',
  NeedMoreTime = 'NeedMoreTime',
}

export interface IDVStepOnProceed {
  action?: Action;
  createdAccountId?: Scalars['ID'];
  createdAccountType?: WrapperType;
}

export interface IDVStepProps {
  proceedBtnText: string;
  onProceed: (props?: IDVStepOnProceed) => void;
}

export function IDVStep({ onProceed, proceedBtnText }: IDVStepProps) {
  const [waitingForKyc, setWaitingForKyc] = useState(true);
  const [waitingForAccount, setWaitingForAccount] = useState(true);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [initialKycStatusLoaded, setInitialKycStatusLoaded] = useState(false);

  const queryClient = useQueryClient();

  const userProfile = queryClient.getQueryData<UserProfileQuery>(
    useUserProfileOnboardingQuery.getKey()
  )?.userProfile!;

  const kycStatusQuery = useOnboardingStatusQuery<
    OnboardingStatusQuery,
    { error: string }
  >(undefined, {
    refetchInterval: 5000,
    enabled: waitingForKyc,
    onSettled: () => setInitialKycStatusLoaded(true),
  });

  const accountEligibilityQuery = useAccountEligibilityQuery(
    {
      type: userProfile.initialAccountType.toString() as WrapperType,
    },
    {
      refetchInterval: 5000,
      enabled: waitingForAccount && !waitingForKyc,
    }
  );

  useEffect(() => {
    const timeout =
      waitingForKyc || waitingForAccount
        ? setTimeout(() => {
            setWaitingForKyc(false);
            setWaitingForAccount(false);

            trackGa({
              event: GaEventNames.onboarding,
              onboardingStep: OnboardingStepNames.onboardingResult,
              onboardingResult: 'Delay',
            });
          }, 90000)
        : undefined;

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [waitingForAccount, waitingForKyc]);

  useEffect(() => {
    const failingKycStates = [
      OnboardingStatus.SupportNeeded,
      OnboardingStatus.FurtherInformationNeeded,
    ];

    if (!kycStatusQuery.isFetching) {
      if (
        kycStatusQuery.data?.userProfile?.onboardingDetails?.status !==
        OnboardingStatus.Processing
      ) {
        setWaitingForKyc(false);
      }

      // If we've failed KYC, we're done anyway - terminal state reached, no point waiting
      // for an account that's not going to appear
      if (
        failingKycStates.includes(
          kycStatusQuery.data?.userProfile?.onboardingDetails?.status ??
            OnboardingStatus.Processing
        )
      ) {
        setWaitingForAccount(false);
      }
    }

    if (
      waitingForAccount &&
      !waitingForKyc &&
      !accountEligibilityQuery.isFetching
    ) {
      const stillWaiting =
        accountEligibilityQuery.data?.accountEligibility?.accountStatus !==
        AccountStatus.Active;

      setWaitingForAccount(stillWaiting);
    }

    if (
      waitingForAccount &&
      accountEligibilityQuery.data?.accountEligibility?.accountStatus ===
        AccountStatus.Active
    ) {
      queryClient.invalidateQueries(useUserProfileQuery.getKey());
      queryClient.invalidateQueries(useUserProfileOnboardingQuery.getKey());
    }
  }, [
    accountEligibilityQuery.data?.accountEligibility?.accountStatus,
    accountEligibilityQuery.isFetching,
    kycStatusQuery.data?.userProfile?.onboardingDetails?.status,
    kycStatusQuery.isFetching,
    waitingForAccount,
    waitingForKyc,
    queryClient,
  ]);

  useEffect(() => {
    if (
      kycStatusQuery.data?.userProfile?.onboardingDetails?.status ===
      OnboardingStatus.Completed
    ) {
      trackGa({
        event: GaEventNames.onboarding,
        onboardingStep: OnboardingStepNames.onboardingResult,
        onboardingResult: 'Success',
      });
    } else if (
      kycStatusQuery.data?.userProfile?.onboardingDetails?.status ===
      OnboardingStatus.SupportNeeded
    ) {
      trackGa({
        event: GaEventNames.onboarding,
        onboardingStep: OnboardingStepNames.onboardingResult,
        onboardingResult: 'Fail',
      });
    }
  }, [kycStatusQuery.data?.userProfile?.onboardingDetails?.status]);

  if (!initialKycStatusLoaded) {
    return <Loading />;
  } else if (
    accountEligibilityQuery.data?.accountEligibility?.accountStatus ===
    AccountStatus.Active
  ) {
    return (
      <Success
        onProceed={onProceed}
        accountType={userProfile.initialAccountType}
        proceedBtnText={proceedBtnText}
      />
    );
  } else if (
    kycStatusQuery.data?.userProfile?.onboardingDetails?.status ===
      OnboardingStatus.SupportNeeded &&
    kycStatusQuery.data?.userProfile?.pendingDocumentUploadRequestCount === 1 &&
    !uploadCompleted
  ) {
    return <DocumentUpload onComplete={() => setUploadCompleted(true)} />;
  } else if (
    kycStatusQuery.data?.userProfile?.onboardingDetails?.status ===
      OnboardingStatus.SupportNeeded ||
    (!waitingForKyc && !waitingForAccount)
  ) {
    return <NeedMoreTime onProceed={onProceed} />;
  } else {
    return <InProgress />;
  }
}

function InProgress() {
  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Verification in progress</StepTitle>
        <StepIntroduction>
          <StepIntroductionTypography>
            Please wait while we verify your identity. This typically takes a
            minute or two.
          </StepIntroductionTypography>
        </StepIntroduction>
        <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
          <Loading inline color={colors.magenta} />
        </Box>
      </StepContent>
    </StepContainer>
  );
}

function Success({
  onProceed,
  accountType,
  proceedBtnText,
}: IDVStepProps & { accountType: AccountType }) {
  const [actionsEnabled] = useToggle('global-actions');
  const accountsQuery = useAccountsQuery();

  const createdAccount = accountsQuery.data?.accounts?.find(
    (acc) => acc.wrapperType.toString() === accountType.toString()
  );

  const actionQuery = useActionsQuery(undefined, {
    enabled: actionsEnabled?.enabled,
  });

  const fundAccountAction = actionQuery.data?.actions?.find(
    ({ type }) => type === ActionType.FundAccount
  );

  const handleContinue = useCallback(() => {
    onProceed({
      action: Action.Continue,
      createdAccountId: createdAccount?.id || undefined,
      createdAccountType: createdAccount?.wrapperType || undefined,
    });
  }, [onProceed, createdAccount?.id, createdAccount?.wrapperType]);

  const canDeposit = createdAccount?.capabilities.deposit.enabled;

  return (
    <StepContainer>
      <QueryState {...actionQuery}>
        {() => (
          <QueryState {...accountsQuery}>
            {() => (
              <>
                <StepContent>
                  <StepTitle>Your account is ready!</StepTitle>
                  <StepIntroduction>
                    <StepIntroductionTypography>
                      We have completed our verification process and your new{' '}
                      {getNameForAccountType(accountType)} is now open. Add cash
                      to your account to start investing.
                    </StepIntroductionTypography>
                  </StepIntroduction>
                </StepContent>

                <StepActions>
                  {actionsEnabled?.enabled && (
                    <LinkCustomButton
                      to={
                        fundAccountAction
                          ? generateActionsTypePath({
                              actionType: 'fund-account',
                              actionId: fundAccountAction.id,
                            })
                          : actionsPath
                      }
                      className="magenta"
                    >
                      Continue
                    </LinkCustomButton>
                  )}
                  {!actionsEnabled?.enabled && canDeposit && (
                    <StepButton
                      type="button"
                      className="magenta"
                      onClick={handleContinue}
                    >
                      {proceedBtnText}
                    </StepButton>
                  )}
                  {!actionsEnabled?.enabled && !canDeposit && (
                    <ButtonsWrapper $isHorizontal>
                      <LinkCustomButton to={dashboardPath} className="richBlue">
                        Dashboard
                      </LinkCustomButton>
                      <LinkCustomButton to={fundListPath} className="magenta">
                        Browse funds
                      </LinkCustomButton>
                    </ButtonsWrapper>
                  )}
                </StepActions>
              </>
            )}
          </QueryState>
        )}
      </QueryState>
    </StepContainer>
  );
}

function NeedMoreTime({ onProceed }: Omit<IDVStepProps, 'proceedBtnText'>) {
  const handleBrowseAllFunds = () => {
    onProceed({ action: Action.NeedMoreTime });
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>We need to run some extra checks</StepTitle>
        <StepIntroduction $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            In order to complete setting up your account, we need to run a few
            extra checks. This is fairly common and nothing to worry about. A
            member of our team will be in touch shortly with more information.
          </StepIntroductionTypography>
          <StepIntroductionTypography>
            In the meantime, why not check out our Universe of funds?
          </StepIntroductionTypography>
        </StepIntroduction>
      </StepContent>

      <StepActions>
        <OnboardingFeedback>
          <CustomButton
            className="richBlue"
            type="button"
            fullWidth
            onClick={handleBrowseAllFunds}
          >
            Browse funds
          </CustomButton>
        </OnboardingFeedback>
      </StepActions>
    </StepContainer>
  );
}

interface DocumentUploadProps {
  onComplete: () => void;
}

function DocumentUpload({ onComplete }: DocumentUploadProps) {
  const [requestId, setRequestId] = useState<string | null>(null);

  const documentUploadRequestsQuery = useDocumentUploadRequestsQuery(
    undefined,
    {
      onSuccess: (data) => {
        const foundRequest = data.documentUploadRequests.find(
          (x) => x.status === DocumentUploadRequestStatus.Pending
        );

        if (foundRequest?.id) {
          setRequestId(foundRequest.id);
        } else {
          onComplete();
        }
      },
      onError: () => onComplete(),
    }
  );

  if (requestId) {
    return (
      <UploadDocumentByRequestForm
        requestId={requestId}
        onCancel={onComplete}
        onComplete={onComplete}
        skipConfirmation={true}
        promoteReason={true}
      />
    );
  } else if (documentUploadRequestsQuery.isLoading) {
    return <InProgress />;
  } else {
    return null;
  }
}
