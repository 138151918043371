import { PerformanceKindSwitch } from 'components/Graphs/PerformanceKindSwitch';
import { PlaceholderGraph } from 'components/Graphs/PlaceholderGraph';
import { PositionPerformanceGraph } from 'components/Graphs/PositionPerformanceGraph';
import { TimeHorizonTabs } from 'components/design-system/Tabs/TimeHorizonTabs';
import {
  AccountQuery,
  PerformanceComparisonPeriod,
  PerformanceSeriesKind,
} from 'generated/graphql';
import React, { useMemo, useState } from 'react';
import { TabTitle } from './HoldingCard.styles';
import {
  BlurredContainer,
  NoDataMessage,
  Overlay,
  PerformanceContainer,
} from './PerformanceSection.styles';

type Position = Exclude<
  AccountQuery['account'],
  null | undefined
>['positions'][number];

export interface PerformanceSectionProps {
  accountId: string;
  position: Position;
}

export function PerformanceSection({
  accountId,
  position,
}: PerformanceSectionProps) {
  const [performanceKind, setPerformanceKind] = useState(
    PerformanceSeriesKind.ClosingValue
  );

  const [performanceTimeHorizon, setPerformanceTimeHorizon] = useState(
    PerformanceComparisonPeriod.AllTime
  );

  const reportableTimeHorizons = position?.reportableTimeHorizons;

  // Don't use placeholder while still loading
  const hasData = (reportableTimeHorizons?.length ?? 1) > 0;

  const placeholderHorizons = useMemo(
    () => [
      {
        label: 'All time',
        value: PerformanceComparisonPeriod.AllTime,
      },
      {
        label: '5 years',
        value: PerformanceComparisonPeriod.FiveYears,
      },
      {
        label: '3 years',
        value: PerformanceComparisonPeriod.ThreeYears,
      },
      {
        label: '1 year',
        value: PerformanceComparisonPeriod.OneYear,
      },
      {
        label: '6 months',
        value: PerformanceComparisonPeriod.SixMonths,
      },
      {
        label: '3 months',
        value: PerformanceComparisonPeriod.ThreeMonths,
      },
    ],
    []
  );

  const handleChangeHorizon = (event: React.ChangeEvent<{}>, newValue: any) => {
    setPerformanceTimeHorizon(newValue);
  };

  return (
    <PerformanceContainer>
      <TabTitle>Performance</TabTitle>
      {hasData ? (
        <>
          {reportableTimeHorizons.length > 1 && (
            <TimeHorizonTabs
              trackingContext="dashboard - fund"
              tabs={reportableTimeHorizons || []}
              activeValue={performanceTimeHorizon}
              onChange={handleChangeHorizon}
            />
          )}
          <PerformanceKindSwitch
            value={performanceKind}
            onChange={setPerformanceKind}
          />
          <PositionPerformanceGraph
            accountId={accountId}
            isin={position?.isin!}
            period={performanceTimeHorizon}
            kind={performanceKind}
          />
        </>
      ) : (
        <>
          <BlurredContainer>
            <TimeHorizonTabs
              trackingContext="dashboard - fund"
              tabs={placeholderHorizons}
              activeValue={performanceTimeHorizon}
              onChange={() => {}}
            />
            <PerformanceKindSwitch
              value={performanceKind}
              onChange={() => {}}
            />
            <PlaceholderGraph />
          </BlurredContainer>
          <Overlay>
            <NoDataMessage>
              There is not sufficient performance data available at this time.
              Please come back later.
            </NoDataMessage>
          </Overlay>
        </>
      )}
    </PerformanceContainer>
  );
}
