import {
  AccountsQuery,
  useAccountsQuery,
  WrapperType,
} from 'generated/graphql';
import sortBy from 'lodash/sortBy';
import numeral from 'numeral';
import { GetAssetGroupString } from './breakdownGroupingHelpers';
import { IncludeAssetType } from './includeAssetHelpers';

type Position = Exclude<
  AccountsQuery['accounts'],
  null | undefined
>[number]['positions'][number];

const isCash = (position: Position) => {
  return position?.instrument === null && position?.isin === null;
};

export interface CurrentBreakdownType {
  name: string;
  region: string;
  percentage: number;
  amount: number;
  growthProportion: number | null;
}

const assetClassSortOrder: string[] = [
  'Equities',
  'Bonds',
  'Property',
  'Alternative assets',
  'Multi-asset',
];

const regionSortOrder: string[] = [
  'Global',
  'UK',
  'US',
  'Europe',
  'Japan',
  'Asia',
  'Asia ex-Japan',
  'Emerging markets',
  'Frontier markets',
  'UK, Europe',
  'Unknown',
];

export const useGetCurrentBreakdown = (
  accountType: WrapperType,
  getAssetGroupString: GetAssetGroupString,
  includeAsset?: IncludeAssetType
) => {
  const accountsQuery = useAccountsQuery();
  const currentAccount = accountsQuery.data?.accounts?.find(
    (acc) => acc.wrapperType === accountType
  );
  const marketValue = currentAccount?.valuationSummary?.marketValue;
  // const pendingBuyValue = currentAccount?.pendingBuyValue || 0;
  // const pendingBuyValuePercentage =
  //   marketValue && pendingBuyValue ? (pendingBuyValue / marketValue) * 100 : 0;

  const cashBreakdowns: any = {};

  const cashPosition = currentAccount?.positions.find(isCash);
  if (cashPosition !== undefined && !includeAsset) {
    cashBreakdowns['Cash'] = {
      percentage: numeral(cashPosition?.allocation ?? 0)
        .multiply(100)
        .value(),
      amount: cashPosition?.currentValue,
    };
  }

  // if (pendingBuyValue > 0 && !includeAsset) {
  //   cashBreakdowns['Pending buys'] = {
  //     percentage: pendingBuyValuePercentage,
  //     amount: pendingBuyValue,
  //   };
  // }

  /** TD: IDK what I'm doing */
  const calculateRelativePositionAllocation = (position: Position) => {
    if (!includeAsset) {
      return undefined;
    }

    const positionsToInclude = currentAccount!.positions.filter((p) =>
      includeAsset(p.instrument?.asset!)
    );

    const totalPositionsValue = positionsToInclude.reduce(
      (total, position) => total + position.currentValue,
      0
    );

    const relativeAllocation = numeral(position.currentValue)
      .divide(totalPositionsValue)
      .multiply(100)
      .value();

    return relativeAllocation;
  };

  const accountLevelAllocation = (position: Position) => {
    return numeral(position.currentValue)
      .divide(marketValue ?? 1)
      .multiply(100)
      .value();
  };

  const breakdownsObj: {
    [key: string]: CurrentBreakdownType;
  } = currentAccount?.positions
    .filter(
      (position) =>
        !isCash(position) &&
        (!includeAsset || includeAsset(position?.instrument?.asset))
    )
    .reduce<any>((value, position) => {
      const group = getAssetGroupString(position?.instrument?.asset);

      const existingAllocation = value[group]?.percentage || 0;

      return {
        ...value,
        [group]: {
          percentage: numeral(existingAllocation)
            .add(
              includeAsset
                ? calculateRelativePositionAllocation(position)
                : accountLevelAllocation(position)
            )
            .value(),
          amount: position.currentValue + (value[group]?.amount || 0),
          growthProportion: existingAllocation
            ? null
            : position.growthProportion,
        },
      };
    }, cashBreakdowns);

  const sortingSelector = ({ name, percentage }: CurrentBreakdownType) => {
    if (name === 'Cash') {
      return Number.MAX_SAFE_INTEGER;
    } else if (assetClassSortOrder.includes(name)) {
      return assetClassSortOrder.indexOf(name);
    } else if (regionSortOrder.includes(name)) {
      return regionSortOrder.indexOf(name);
    } else {
      return -percentage; // To sort DESC;
    }
  };

  const breakdowns: CurrentBreakdownType[] = breakdownsObj
    ? sortBy(
        Object.keys(breakdownsObj).map((region) => ({
          name: region,
          region,
          percentage: breakdownsObj[region].percentage,
          amount: breakdownsObj[region].amount,
          growthProportion: breakdownsObj[region].growthProportion,
        })),
        sortingSelector
      )
    : [];

  return {
    accountsQuery,
    breakdowns: breakdowns,
    breakdownsObj: breakdownsObj,
    currentAccount,
  };
};
