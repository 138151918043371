import { WrapperType, useUserProfileQuery } from 'generated/graphql';
import { actionsPath, generateActionsAccountPath } from 'paths';
import { Redirect } from 'react-router-dom';

export function SuccessStep() {
  const userProfile = useUserProfileQuery();
  const pensionAccount = userProfile.data?.clientSummary?.accounts?.find(
    (account) => account.wrapperType === WrapperType.Sipp
  );

  const redirectPath = pensionAccount
    ? generateActionsAccountPath({
        wrapperType: 'pension',
        accountId: pensionAccount.id,
      })
    : actionsPath;

  return <Redirect to={redirectPath} />;
}
