import { Alert } from 'components/design-system/Alert/Alert';
import { H6 } from 'components/design-system/Heading/Heading';
import { colors } from 'constants/colors';
import { FaRegCircle, FaRegCircleCheck } from 'react-icons/fa6';
import { HiArrowRight } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const UncheckedIcon = styled(FaRegCircle)`
  width: 1.25rem;
  height: 1.25rem;
  color: #ffffff80;
  grid-area: icon;
`;

export const CheckedIcon = styled(FaRegCircleCheck)`
  width: 1.25rem;
  height: 1.25rem;
  color: ${colors.white};
  grid-area: icon;
`;

export const ArrowRight = styled(HiArrowRight)`
  width: 1.5rem;
  height: 1.5rem;
  color: ${colors.magenta};
  grid-area: action;
`;

export const Wrapper = styled.div`
  background-color: ${colors.richBlue};
  width: 100%;

  ${(p) => p.theme.breakpoints.up('md')} {
    max-width: 24rem;
    padding: 1.5rem;
  }
`;

export const ListLayout = styled.div`
  display: flex;
  flex-direction: column;
`;

const actionItemStyles = css`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'icon h2 action' 'icon text action';
  padding: 1rem 0;
  gap: 0 1rem;
  align-items: center;

  > h2 {
    grid-area: h2;
  }
  > p {
    grid-area: text;
  }

  text-align: inherit;
  border: none;
  background: none;
  text-decoration: none;
  border-top: 1px solid ${colors['richBlue-400']};

  &:first-child {
    border-top: none;
  }
`;

export const ActionItemActive = styled(Link)`
  ${actionItemStyles}
`;

export const ActionItemCompleted = styled.div`
  ${actionItemStyles}
`;

export const ActionItemHeading = styled(H6)`
  color: ${colors.white};
  margin: 0;
  font-size: 1.125rem;
  font-weight: 400;
`;

export const ErrorAlert = styled(Alert)`
  margin: 1rem 0;
`;

export const PopOverContainer = styled.div`
  position: fixed;
  width: 24rem;
  height: auto;
  right: 1.25rem;
  bottom: 5rem;
  border-radius: 0.5rem;
  overflow: hidden;
  z-index: ${({ theme }) => theme.zIndex.popup};
`;

export const TeaserContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  padding-right: 4rem;
  align-items: center;
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  svg {
    color: ${colors.white};
  }
`;
