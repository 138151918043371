/* The codes below all fail the spell check - disabling it for this file. */
/* cSpell:disable */

import keyBy from 'lodash/keyBy';

const rawData = [
  {
    code: 'ACFI',
    name: 'Fixed Income Morocco',
    isoImpliedByCode: '',
    isoImpliedByName: 'MA',
    noIsoReason: '',
  },
  {
    code: 'ACFI',
    name: "Fixed Income Cote D'Ivoire",
    isoImpliedByCode: '',
    isoImpliedByName: 'CI',
    noIsoReason: '',
  },
  {
    code: 'ACFI',
    name: 'Fixed Income Zambia',
    isoImpliedByCode: '',
    isoImpliedByName: 'ZM',
    noIsoReason: '',
  },
  {
    code: 'ACFI',
    name: 'Fixed Income Benin',
    isoImpliedByCode: '',
    isoImpliedByName: 'BJ',
    noIsoReason: '',
  },
  {
    code: 'ACFI',
    name: 'Fixed Income Namibia',
    isoImpliedByCode: '',
    isoImpliedByName: 'NA',
    noIsoReason: '',
  },
  {
    code: 'ACFI',
    name: 'Fixed Income Gabon',
    isoImpliedByCode: '',
    isoImpliedByName: 'GA',
    noIsoReason: '',
  },
  {
    code: 'ACFI',
    name: 'Fixed Income Rwanda',
    isoImpliedByCode: '',
    isoImpliedByName: 'RW',
    noIsoReason: '',
  },
  {
    code: 'ACFI',
    name: 'Fixed Income Senegal',
    isoImpliedByCode: '',
    isoImpliedByName: 'SN',
    noIsoReason: '',
  },
  {
    code: 'ACFI',
    name: 'Fixed Income Ghana',
    isoImpliedByCode: '',
    isoImpliedByName: 'GH',
    noIsoReason: '',
  },
  {
    code: 'ACFI',
    name: 'Fixed Income Kenya',
    isoImpliedByCode: '',
    isoImpliedByName: 'KE',
    noIsoReason: '',
  },
  {
    code: 'ADFI',
    name: 'Developed Asia Bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'AEEQ',
    name: 'Equity Colombia',
    isoImpliedByCode: '',
    isoImpliedByName: 'CO',
    noIsoReason: '',
  },
  {
    code: 'AEEQ',
    name: 'Equity Puerto Rico',
    isoImpliedByCode: '',
    isoImpliedByName: 'PR',
    noIsoReason: '',
  },
  {
    code: 'AEEQ',
    name: 'Equity Costa Rica',
    isoImpliedByCode: '',
    isoImpliedByName: 'CR',
    noIsoReason: '',
  },
  {
    code: 'AEEQ',
    name: 'Equity Argentina',
    isoImpliedByCode: '',
    isoImpliedByName: 'AU',
    noIsoReason: '',
  },
  {
    code: 'AEEQ',
    name: 'Equity Panama',
    isoImpliedByCode: '',
    isoImpliedByName: 'PA',
    noIsoReason: '',
  },
  {
    code: 'AEEQ',
    name: 'Equity Bahamas',
    isoImpliedByCode: '',
    isoImpliedByName: 'BS',
    noIsoReason: '',
  },
  {
    code: 'AEEQ',
    name: 'Equity Bermuda',
    isoImpliedByCode: '',
    isoImpliedByName: 'BM',
    noIsoReason: '',
  },
  {
    code: 'AEEQ',
    name: 'South American Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'AEEQ',
    name: 'Equity Cayman Islands',
    isoImpliedByCode: '',
    isoImpliedByName: 'KY',
    noIsoReason: '',
  },
  {
    code: 'AEEQ',
    name: 'American Emerging Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'AEFI',
    name: 'Cayman Islands Bonds',
    isoImpliedByCode: '',
    isoImpliedByName: 'KY',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Guatemala',
    isoImpliedByCode: '',
    isoImpliedByName: 'GT',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Cayman Islands',
    isoImpliedByCode: '',
    isoImpliedByName: 'KY',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Uruguay',
    isoImpliedByCode: '',
    isoImpliedByName: 'UY',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Colombia',
    isoImpliedByCode: '',
    isoImpliedByName: 'CO',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Ecuador',
    isoImpliedByCode: '',
    isoImpliedByName: 'EC',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Costa Rica',
    isoImpliedByCode: '',
    isoImpliedByName: 'CR',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Dominican Republic',
    isoImpliedByCode: '',
    isoImpliedByName: 'DO',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Bermuda',
    isoImpliedByCode: '',
    isoImpliedByName: 'BM',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'American Emerging Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Honduras',
    isoImpliedByCode: '',
    isoImpliedByName: 'HN',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Paraguay',
    isoImpliedByCode: '',
    isoImpliedByName: 'PY',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income El Salvador',
    isoImpliedByCode: '',
    isoImpliedByName: 'SV',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Panama',
    isoImpliedByCode: '',
    isoImpliedByName: 'PA',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Suriname',
    isoImpliedByCode: '',
    isoImpliedByName: 'SR',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Barbados',
    isoImpliedByCode: '',
    isoImpliedByName: 'BB',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Jamaica',
    isoImpliedByCode: '',
    isoImpliedByName: 'JM',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Trinidad and Tobago',
    isoImpliedByCode: '',
    isoImpliedByName: 'TT',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Argentina',
    isoImpliedByCode: '',
    isoImpliedByName: 'AR',
    noIsoReason: '',
  },
  {
    code: 'AEFI',
    name: 'Fixed Income Bolivia',
    isoImpliedByCode: '',
    isoImpliedByName: 'BO',
    noIsoReason: '',
  },
  {
    code: 'AEHY',
    name: 'American Emerging High Yield Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'AENG',
    name: 'American Emerging Corporate Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'AFEQ',
    name: 'Equity Namibia',
    isoImpliedByCode: '',
    isoImpliedByName: 'NA',
    noIsoReason: '',
  },
  {
    code: 'AFEQ',
    name: 'African Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'AFEQ',
    name: 'Equity Zambia',
    isoImpliedByCode: '',
    isoImpliedByName: 'ZM',
    noIsoReason: '',
  },
  {
    code: 'AFFI',
    name: 'Fixed Income Lebanon',
    isoImpliedByCode: '',
    isoImpliedByName: 'LB',
    noIsoReason: '',
  },
  {
    code: 'AFFI',
    name: 'Fixed Income Turkey',
    isoImpliedByCode: '',
    isoImpliedByName: 'TR',
    noIsoReason: '',
  },
  {
    code: 'AFFI',
    name: 'Fixed Income Jordan',
    isoImpliedByCode: '',
    isoImpliedByName: 'JO',
    noIsoReason: '',
  },
  {
    code: 'AFFI',
    name: 'Fixed Income Egypt',
    isoImpliedByCode: '',
    isoImpliedByName: 'EG',
    noIsoReason: '',
  },
  {
    code: 'AFHY',
    name: 'Middle East & African High Yield Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'ALAS',
    name: 'Alternatives',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'ALAS',
    name: 'Alternative Assets',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'ALIS',
    name: 'Equity Swaps',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'ALIS',
    name: 'Credit Default Swaps & Indices',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'ALIS',
    name: 'Derivatives',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'ALIS',
    name: 'Alternative Investment Strategies',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'AMEQ',
    name: 'American Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'APNG',
    name: 'Asia Pacific Corporate Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'AREQ',
    name: 'Equity Saudi Arabia',
    isoImpliedByCode: 'SA',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'ASEQ',
    name: 'Equity Austria',
    isoImpliedByCode: 'AT',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'ASFI',
    name: 'Fixed Income Austria',
    isoImpliedByCode: 'AT',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'AUEQ',
    name: 'Equity Australia',
    isoImpliedByCode: 'AU',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'AUFI',
    name: 'Fixed Income Australia',
    isoImpliedByCode: 'AU',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'AUNG',
    name: 'Australian Corporate Fixed Interest',
    isoImpliedByCode: 'AU',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'AXFI',
    name: 'Fixed Income Papua New Guinea',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'AXHY',
    name: 'Asia Pacific ex Japan High Yield Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'BEEQ',
    name: 'Equity Belgium',
    isoImpliedByCode: 'BE',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'BEFI',
    name: 'Fixed Income Belgium',
    isoImpliedByCode: 'BE',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'BREQ',
    name: 'Equity Brazil',
    isoImpliedByCode: 'BR',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'BRFI',
    name: 'Fixed Income Brazil',
    isoImpliedByCode: 'BR',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'CAEQ',
    name: 'Equity Canada',
    isoImpliedByCode: 'CA',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'CAFI',
    name: 'Fixed Income Canada',
    isoImpliedByCode: 'CA',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'CAGO',
    name: 'Canadian Government Fixed Interest',
    isoImpliedByCode: 'CA',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'CANG',
    name: 'Canada Corporate Fixed Interest',
    isoImpliedByCode: 'CA',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX India',
    isoImpliedByCode: '',
    isoImpliedByName: 'IN',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and near cash',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Switzerland',
    isoImpliedByCode: '',
    isoImpliedByName: 'CH',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Finland',
    isoImpliedByCode: '',
    isoImpliedByName: 'FI',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Denmark',
    isoImpliedByCode: '',
    isoImpliedByName: 'DK',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Australia',
    isoImpliedByCode: '',
    isoImpliedByName: 'AU',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX United Kingdom',
    isoImpliedByCode: '',
    isoImpliedByName: 'GB',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Singapore',
    isoImpliedByCode: '',
    isoImpliedByName: 'SG',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Hong Kong',
    isoImpliedByCode: '',
    isoImpliedByName: 'HK',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Indonesia',
    isoImpliedByCode: '',
    isoImpliedByName: 'ID',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Floating Rate',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Netherlands',
    isoImpliedByCode: '',
    isoImpliedByName: 'NL',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Brazil',
    isoImpliedByCode: '',
    isoImpliedByName: 'BR',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Saudi Arabia',
    isoImpliedByCode: '',
    isoImpliedByName: 'SA',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX None',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CASH',
    name: 'Cash and FX United States',
    isoImpliedByCode: '',
    isoImpliedByName: 'US',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Uninvested Cash',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Philippines',
    isoImpliedByCode: '',
    isoImpliedByName: 'PH',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Hungary',
    isoImpliedByCode: '',
    isoImpliedByName: 'HU',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Curacao',
    isoImpliedByCode: '',
    isoImpliedByName: 'CW',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Net Liquid Assets',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CASH',
    name: 'Cash and FX France',
    isoImpliedByCode: '',
    isoImpliedByName: 'FR',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Czech Republic',
    isoImpliedByCode: '',
    isoImpliedByName: 'CZ',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'United Kingdom CASH',
    isoImpliedByCode: '',
    isoImpliedByName: 'GB',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Korea (South), Republic of',
    isoImpliedByCode: '',
    isoImpliedByName: 'KR',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Colombia',
    isoImpliedByCode: '',
    isoImpliedByName: 'CO',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Liquid Assets',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CASH',
    name: 'Cash and FX China',
    isoImpliedByCode: '',
    isoImpliedByName: 'CN',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Israel',
    isoImpliedByCode: '',
    isoImpliedByName: 'IL',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Mexico',
    isoImpliedByCode: '',
    isoImpliedByName: 'MX',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX South Africa',
    isoImpliedByCode: '',
    isoImpliedByName: 'ZA',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX United Arab Emirates',
    isoImpliedByCode: '',
    isoImpliedByName: 'AE',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and Derivatives',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Poland',
    isoImpliedByCode: '',
    isoImpliedByName: 'PL',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Austria',
    isoImpliedByCode: '',
    isoImpliedByName: 'AT',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Taiwan (Republic of China)',
    isoImpliedByCode: '',
    isoImpliedByName: 'TW',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Japan',
    isoImpliedByCode: '',
    isoImpliedByName: 'JP',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Turkey',
    isoImpliedByCode: '',
    isoImpliedByName: 'TR',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Luxembourg',
    isoImpliedByCode: '',
    isoImpliedByName: 'LU',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Egypt',
    isoImpliedByCode: '',
    isoImpliedByName: 'EG',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Malaysia',
    isoImpliedByCode: '',
    isoImpliedByName: 'MY',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Supranational',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Thailand',
    isoImpliedByCode: '',
    isoImpliedByName: 'TH',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Canada',
    isoImpliedByCode: '',
    isoImpliedByName: 'CA',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Kuwait',
    isoImpliedByCode: '',
    isoImpliedByName: 'KW',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Belgium',
    isoImpliedByCode: '',
    isoImpliedByName: 'BE',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX European Union',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Ireland',
    isoImpliedByCode: '',
    isoImpliedByName: 'IE',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Germany',
    isoImpliedByCode: '',
    isoImpliedByName: 'DE',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Sweden',
    isoImpliedByCode: '',
    isoImpliedByName: 'SE',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash and FX Norway',
    isoImpliedByCode: '',
    isoImpliedByName: 'NO',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Money Market',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CASH',
    name: 'Cash & Derivatives',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CASH',
    name: 'Cash and FX New Zealand',
    isoImpliedByCode: '',
    isoImpliedByName: 'NZ',
    noIsoReason: '',
  },
  {
    code: 'CASH',
    name: 'Cash',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CHEQ',
    name: 'CHINA STOCKS LISTED IN US Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CHEQ',
    name: 'China Equities',
    isoImpliedByCode: 'CN',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'CHFI',
    name: 'Equity China',
    isoImpliedByCode: 'CN',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'CLEQ',
    name: 'Equity Chile',
    isoImpliedByCode: 'CL',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'CLFI',
    name: 'Fixed Income Chile',
    isoImpliedByCode: 'CL',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'COEN',
    name: 'Commodity & Energy',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'COEN',
    name: 'Commodities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CSHE',
    name: 'Cash & Cash Equivalents',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CSHE',
    name: 'Cash and Equivalents',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CSHE',
    name: 'Cash & cash equivalents',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CSHE',
    name: 'Cash & equivalents',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'CZEQ',
    name: 'Equity Czech Republic',
    isoImpliedByCode: 'CZ',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'CZFI',
    name: 'Fixed Income Czech Republic',
    isoImpliedByCode: 'CZ',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'DEEQ',
    name: 'Equity Germany',
    isoImpliedByCode: 'DE',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'DEFI',
    name: 'Fixed Income Germany',
    isoImpliedByCode: 'DE',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'DEFI',
    name: 'German Fixed Interest',
    isoImpliedByCode: 'DE',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'DENG',
    name: 'German Corporate Fixed Interest',
    isoImpliedByCode: 'DE',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'DENG',
    name: 'Germany (& Aust.) Corporate Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'DMEQ',
    name: 'Equity Denmark',
    isoImpliedByCode: 'DK',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'DMFI',
    name: 'Fixed Income Denmark',
    isoImpliedByCode: 'DK',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'DMNG',
    name: 'Danish Corporate Fixed Interest',
    isoImpliedByCode: 'DK',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'EAEQ',
    name: 'African and Middle Eastern Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'EAEQ',
    name: 'Equity Jordan',
    isoImpliedByCode: '',
    isoImpliedByName: 'JO',
    noIsoReason: '',
  },
  {
    code: 'EEEQ',
    name: 'European Emerging Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'EEFI',
    name: 'Fixed Income Ukraine',
    isoImpliedByCode: '',
    isoImpliedByName: 'UA',
    noIsoReason: '',
  },
  {
    code: 'EEFI',
    name: 'Fixed Income Romania',
    isoImpliedByCode: '',
    isoImpliedByName: 'RO',
    noIsoReason: '',
  },
  {
    code: 'EEFI',
    name: 'Fixed Income Bulgaria',
    isoImpliedByCode: '',
    isoImpliedByName: 'BG',
    noIsoReason: '',
  },
  {
    code: 'EEFI',
    name: 'Fixed Income Azerbaijan',
    isoImpliedByCode: '',
    isoImpliedByName: 'AZ',
    noIsoReason: '',
  },
  {
    code: 'EEFI',
    name: 'Fixed Income Serbia, Republic of',
    isoImpliedByCode: '',
    isoImpliedByName: 'RS',
    noIsoReason: '',
  },
  {
    code: 'EEFI',
    name: 'Fixed Income Georgia',
    isoImpliedByCode: '',
    isoImpliedByName: 'GE',
    noIsoReason: '',
  },
  {
    code: 'EEFI',
    name: 'Fixed Income Montenegro, Republic of',
    isoImpliedByCode: '',
    isoImpliedByName: 'ME',
    noIsoReason: '',
  },
  {
    code: 'EEFI',
    name: 'Fixed Income Armenia',
    isoImpliedByCode: '',
    isoImpliedByName: 'AM',
    noIsoReason: '',
  },
  {
    code: 'EGEQ',
    name: 'Equity Egypt',
    isoImpliedByCode: 'EG',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'EIEQ',
    name: 'Equity Ireland',
    isoImpliedByCode: 'IE',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'EIFI',
    name: 'Fixed Income Ireland',
    isoImpliedByCode: 'IE',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'EQUI',
    name: 'Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'EUEQ',
    name: 'Equity Cyprus',
    isoImpliedByCode: '',
    isoImpliedByName: 'CY',
    noIsoReason: '',
  },
  {
    code: 'EUEQ',
    name: 'Equity Monaco',
    isoImpliedByCode: '',
    isoImpliedByName: 'MC',
    noIsoReason: '',
  },
  {
    code: 'EUEQ',
    name: 'European Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EUEQ',
    name: 'European Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EUEQ',
    name: 'Europe Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EUEQ',
    name: 'Equity Gibraltar',
    isoImpliedByCode: '',
    isoImpliedByName: 'GI',
    noIsoReason: '',
  },
  {
    code: 'EUEQ',
    name: 'Continental Europe Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EUFI',
    name: 'Fixed Income Latvia',
    isoImpliedByCode: '',
    isoImpliedByName: 'LV',
    noIsoReason: '',
  },
  {
    code: 'EUFI',
    name: 'Fixed Income Lithuania',
    isoImpliedByCode: '',
    isoImpliedByName: 'LT',
    noIsoReason: '',
  },
  {
    code: 'EUFI',
    name: 'Europe Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EUFI',
    name: 'European Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EUFI',
    name: 'Fixed Income Iceland',
    isoImpliedByCode: '',
    isoImpliedByName: 'IS',
    noIsoReason: '',
  },
  {
    code: 'EUFI',
    name: 'Europe Bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EUNG',
    name: 'C.I.S / Eastern Europe Corporate Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EUNG',
    name: 'European Corporate Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EUNG',
    name: 'European corporate bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EUNG',
    name: 'Benelux Corporate Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EUNG',
    name: 'Other Europe Corporate Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EUNG',
    name: 'Mediterranean Corporate Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EUXH',
    name: 'Europe ex UK High Yield Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EXEQ',
    name: 'Europe ex-UK equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EXEQ',
    name: 'Europe (ex UK) Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EXEQ',
    name: 'Europe ex UK Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'EXEQ',
    name: 'Europe Ex UK Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'FEDQ',
    name: 'Developed Asia Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'FEDQ',
    name: 'Developed Asia Pacific Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'FEDQ',
    name: 'Developed Asia Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'FEEQ',
    name: 'Asian Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'FEEQ',
    name: 'Australasian Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'FEEQ',
    name: 'Asia Pacific Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'FEEQ',
    name: 'Australasia Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'FEEQ',
    name: 'Asia Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'FEFI',
    name: 'Developed Asia Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'FEFI',
    name: 'Fixed Income Uzbekistan',
    isoImpliedByCode: '',
    isoImpliedByName: 'UZ',
    noIsoReason: '',
  },
  {
    code: 'FESH',
    name: 'Asia Pacific Property Shares',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'FIEQ',
    name: 'Equity Finland',
    isoImpliedByCode: 'FI',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'FIEQ',
    name: 'Finnish Equities',
    isoImpliedByCode: 'FI',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'FIFI',
    name: 'Fixed Income Finland',
    isoImpliedByCode: 'FI',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'FOEX',
    name: 'Foreign Exchange',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'FOEX',
    name: 'FX / Derivative P&L',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'FREQ',
    name: 'Equity France',
    isoImpliedByCode: 'FR',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'FRFI',
    name: 'Fixed Income France',
    isoImpliedByCode: 'FR',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'FRNG',
    name: 'French Corporate Fixed Interest',
    isoImpliedByCode: 'FR',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'FUTR',
    name: 'Bond Future',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'FWDS',
    name: 'Forwards',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'FXJE',
    name: 'Asia ex-Japan equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'FXJE',
    name: 'Asia Pacific ex Japan Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'FXNG',
    name: 'Corporate Bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'GCFI',
    name: 'Fixed Income Saudi Arabia',
    isoImpliedByCode: '',
    isoImpliedByName: 'SA',
    noIsoReason: '',
  },
  {
    code: 'GCFI',
    name: 'Fixed Income Bahrain',
    isoImpliedByCode: '',
    isoImpliedByName: 'BH',
    noIsoReason: '',
  },
  {
    code: 'GCFI',
    name: 'Fixed Income Oman',
    isoImpliedByCode: '',
    isoImpliedByName: 'OM',
    noIsoReason: '',
  },
  {
    code: 'GCFI',
    name: 'Fixed Income United Arab Emirates',
    isoImpliedByCode: '',
    isoImpliedByName: 'AE',
    noIsoReason: '',
  },
  {
    code: 'GCFI',
    name: 'Fixed Income Kuwait',
    isoImpliedByCode: '',
    isoImpliedByName: 'KW',
    noIsoReason: '',
  },
  {
    code: 'GCFI',
    name: 'Fixed Income Qatar',
    isoImpliedByCode: '',
    isoImpliedByName: 'QA',
    noIsoReason: '',
  },
  {
    code: 'GHYI',
    name: 'High Yield Corporate Bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'GHYI',
    name: 'High Yield Credit',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'GIGI',
    name: 'Global Investment Grade Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'GIGI',
    name: 'Investment Grade Corporate Bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'GLBQ',
    name: 'Global Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'GLEQ',
    name: 'Global Em Markets Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'GLEQ',
    name: 'Emerging Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'GLEQ',
    name: 'Emerging Markets Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'GLEQ',
    name: 'Global Emerging Market Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'GLEQ',
    name: 'Emerging Markets Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'GLEQ',
    name: 'Emerging markets equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'GLFI',
    name: 'Emerging Market Bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'GLFI',
    name: 'Emerging Markets Bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'GLFI',
    name: 'Global Emerging Market Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'GLFI',
    name: 'Emerging Markets Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'GREQ',
    name: 'Equity Greece',
    isoImpliedByCode: 'GR',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'GRFI',
    name: 'Fixed Income Greece',
    isoImpliedByCode: 'GR',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'HGEQ',
    name: 'Equity Hungary',
    isoImpliedByCode: 'HU',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'HGFI',
    name: 'Fixed Income Hungary',
    isoImpliedByCode: 'HU',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'HKEQ',
    name: 'Equity Hong Kong',
    isoImpliedByCode: 'HK',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'HKFI',
    name: 'Fixed Income Hong Kong',
    isoImpliedByCode: 'HK',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'IAEQ',
    name: 'Equity Indonesia',
    isoImpliedByCode: 'ID',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'IAFI',
    name: 'Fixed Income Indonesia',
    isoImpliedByCode: 'ID',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'IDEQ',
    name: 'Equity India',
    isoImpliedByCode: 'IN',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'IDFI',
    name: 'Fixed Income India',
    isoImpliedByCode: 'IN',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'INEQ',
    name: 'Listed Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INEQ',
    name: 'International Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INEQ',
    name: 'Public Equity with Private Terms',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INEQ',
    name: 'Listed Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INEQ',
    name: 'Unquoted Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INEQ',
    name: 'UNLISTED Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INEQ',
    name: 'Other Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INEQ',
    name: 'Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INEQ',
    name: 'Others Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INEQ',
    name: 'Equity Kyrgyzstan',
    isoImpliedByCode: '',
    isoImpliedByName: 'KG',
    noIsoReason: '',
  },
  {
    code: 'INEQ',
    name: 'Global Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INEQ',
    name: 'Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INEQ',
    name: 'Overseas Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'INEQ',
    name: 'Other Locations Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'INEQ',
    name: 'Continental Shares',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'INEQ',
    name: 'OTHER STOCKS LISTED IN HK Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'HK',
  },
  {
    code: 'INFI',
    name: 'Credit - hard currency',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INFI',
    name: 'Fixed Income',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INFI',
    name: 'Overseas Fixed',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INFI',
    name: 'Loans',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INFI',
    name: 'Global Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INFI',
    name: 'Overseas Bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'INFI',
    name: 'Credit - local currency',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INFI',
    name: 'Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INFI',
    name: 'Global bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INFI',
    name: 'Fixed Rate',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INGO',
    name: 'Government bonds - hard currency',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INGO',
    name: 'Securitised',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INGO',
    name: 'Euro area government bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'INGO',
    name: 'Government bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INGO',
    name: 'Government bonds - local currency',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INGO',
    name: 'Government Bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INGO',
    name: 'Government',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INGO',
    name: 'Global Government Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INIL',
    name: 'Global Index Linked',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INNG',
    name: 'Corporate',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INNG',
    name: 'Global Corporate Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INNG',
    name: 'Asset Backed Securities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'INNG',
    name: 'Asset or Mortgage Backed',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'ISEQ',
    name: 'Equity Israel',
    isoImpliedByCode: 'IL',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'ITEQ',
    name: 'Equity Italy',
    isoImpliedByCode: 'IT',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'ITFI',
    name: 'Fixed Income Italy',
    isoImpliedByCode: 'IT',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'ITLC',
    name: 'Large Cap',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'ITMC',
    name: 'Mid Cap',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'ITNG',
    name: 'Italian Corporate Fixed Interest',
    isoImpliedByCode: 'IT',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'JPEQ',
    name: 'Equity Japan',
    isoImpliedByCode: 'JP',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'JPFI',
    name: 'Fixed Income Japan',
    isoImpliedByCode: 'JP',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'JPGO',
    name: 'Japan government bonds',
    isoImpliedByCode: 'JP',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'JPNG',
    name: 'Japan Corporate Fixed Interest',
    isoImpliedByCode: 'JP',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'KUEQ',
    name: 'Equity Kuwait',
    isoImpliedByCode: 'KW',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'KZEQ',
    name: 'Equity Kazakhstan',
    isoImpliedByCode: 'KZ',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'KZFI',
    name: 'Fixed Income Kazakhstan',
    isoImpliedByCode: 'KZ',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'LXEQ',
    name: 'Equity Luxembourg',
    isoImpliedByCode: 'LU',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'LXFI',
    name: 'Fixed Income Luxembourg',
    isoImpliedByCode: 'LU',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'LXNG',
    name: 'Luxembourg Corporate Fixed Interest',
    isoImpliedByCode: 'LU',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'MAEQ',
    name: 'Equity Malaysia',
    isoImpliedByCode: 'MY',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'MAFI',
    name: 'Fixed Income Malaysia',
    isoImpliedByCode: 'MY',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'MUFU',
    name: 'Mutual Funds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'MXEQ',
    name: 'Equity Mexico',
    isoImpliedByCode: 'MX',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'MXFI',
    name: 'Fixed Income Mexico',
    isoImpliedByCode: 'MX',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'NAEQ',
    name: 'North America Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'NAEQ',
    name: 'North American Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'NAEQ',
    name: 'North America equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'NAEQ',
    name: 'North American Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'NAEQ',
    name: 'North America Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'NAFI',
    name: 'North America Bonds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'NAFI',
    name: 'North America Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'NAHY',
    name: 'North American High Yield Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'NEEQ',
    name: 'Equity Netherlands',
    isoImpliedByCode: 'NL',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'NEFI',
    name: 'Fixed Income Netherlands',
    isoImpliedByCode: 'NL',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'NENG',
    name: 'Dutch Corporate Fixed Interest',
    isoImpliedByCode: 'NL',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'NOEQ',
    name: 'Equity Norway',
    isoImpliedByCode: 'NO',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'NOFI',
    name: 'Fixed Income Norway',
    isoImpliedByCode: 'NO',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'NONG',
    name: 'Norwegian Corporate Fixed Interest',
    isoImpliedByCode: 'NO',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'NZEQ',
    name: 'Equity New Zealand',
    isoImpliedByCode: 'NZ',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'NZFI',
    name: 'Fixed Income New Zealand',
    isoImpliedByCode: 'NZ',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None Germany',
    isoImpliedByCode: '',
    isoImpliedByName: 'DE',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None Taiwan (Republic of China)',
    isoImpliedByCode: '',
    isoImpliedByName: 'TW',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'Overseas Infrastructure',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'OTHR',
    name: 'None United Kingdom',
    isoImpliedByCode: '',
    isoImpliedByName: 'GB',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None Canada',
    isoImpliedByCode: '',
    isoImpliedByName: 'CA',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'Unquoteds',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: '???',
  },
  {
    code: 'OTHR',
    name: 'Infrastructure',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'OTHR',
    name: 'Cash & Others',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'OTHR',
    name: 'None Ireland',
    isoImpliedByCode: '',
    isoImpliedByName: 'IE',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None United States',
    isoImpliedByCode: '',
    isoImpliedByName: 'US',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'Fund',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'OTHR',
    name: 'Others',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'OTHR',
    name: 'Emerging Markets',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'OTHR',
    name: 'None Uruguay',
    isoImpliedByCode: '',
    isoImpliedByName: 'UY',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'Other',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'OTHR',
    name: 'Mixed Assets',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'OTHR',
    name: 'None Luxembourg',
    isoImpliedByCode: '',
    isoImpliedByName: 'LU',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None European Union',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'OTHR',
    name: 'None Norway',
    isoImpliedByCode: '',
    isoImpliedByName: 'NO',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None Sweden',
    isoImpliedByCode: '',
    isoImpliedByName: 'SE',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None China',
    isoImpliedByCode: '',
    isoImpliedByName: 'CN',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None Japan',
    isoImpliedByCode: '',
    isoImpliedByName: 'JP',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None Singapore',
    isoImpliedByCode: '',
    isoImpliedByName: 'SG',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None Denmark',
    isoImpliedByCode: '',
    isoImpliedByName: 'DK',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'Private Markets',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'OTHR',
    name: 'None New Zealand',
    isoImpliedByCode: '',
    isoImpliedByName: 'NZ',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None Israel',
    isoImpliedByCode: '',
    isoImpliedByName: 'IL',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None Hong Kong',
    isoImpliedByCode: '',
    isoImpliedByName: 'HK',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'Special Opportunities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'OTHR',
    name: 'None Switzerland',
    isoImpliedByCode: '',
    isoImpliedByName: 'CH',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None Netherlands',
    isoImpliedByCode: '',
    isoImpliedByName: 'NL',
    noIsoReason: '',
  },
  {
    code: 'OTHR',
    name: 'None None',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'OTHR',
    name: 'Rounding adjustment',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'OTHR',
    name: 'None Australia',
    isoImpliedByCode: '',
    isoImpliedByName: 'AU',
    noIsoReason: '',
  },
  {
    code: 'PEEQ',
    name: 'Asia Pacific Emerging Equities',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'PEFI',
    name: 'Fixed Income Mongolia',
    isoImpliedByCode: '',
    isoImpliedByName: 'MN',
    noIsoReason: '',
  },
  {
    code: 'PEFI',
    name: 'Fixed Income Tajikistan',
    isoImpliedByCode: '',
    isoImpliedByName: 'TJ',
    noIsoReason: '',
  },
  {
    code: 'PEFI',
    name: 'Fixed Income Pakistan',
    isoImpliedByCode: '',
    isoImpliedByName: 'PK',
    noIsoReason: '',
  },
  {
    code: 'PEFI',
    name: 'Fixed Income Sri Lanka',
    isoImpliedByCode: '',
    isoImpliedByName: 'LK',
    noIsoReason: '',
  },
  {
    code: 'PGEQ',
    name: 'Equity Portugal',
    isoImpliedByCode: 'PT',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'PGFI',
    name: 'Fixed Income Portugal',
    isoImpliedByCode: 'PT',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'PHEQ',
    name: 'Equity Philippines',
    isoImpliedByCode: 'PH',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'PHFI',
    name: 'Fixed Income Philippines',
    isoImpliedByCode: 'PH',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'POEQ',
    name: 'Equity Poland',
    isoImpliedByCode: 'PL',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'POFI',
    name: 'Fixed Income Poland',
    isoImpliedByCode: 'PL',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'PREF',
    name: 'Preferred',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'PREF',
    name: 'Preference Shares',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'PREQ',
    name: 'Equity Peru',
    isoImpliedByCode: 'PE',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'PRFI',
    name: 'Fixed Income Peru',
    isoImpliedByCode: 'PE',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'PROP',
    name: 'UK Direct Property',
    isoImpliedByCode: '',
    isoImpliedByName: 'GB',
    noIsoReason: '',
  },
  {
    code: 'PROP',
    name: 'Property',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'PRVE',
    name: 'Private Equity',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'QAEQ',
    name: 'Qatari Equities',
    isoImpliedByCode: 'QA',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SAEQ',
    name: 'Equity South Africa',
    isoImpliedByCode: 'ZA',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SAFI',
    name: 'Fixed Income South Africa',
    isoImpliedByCode: 'ZA',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SCEQ',
    name: 'Equity Faroe Islands',
    isoImpliedByCode: '',
    isoImpliedByName: 'FO',
    noIsoReason: '',
  },
  {
    code: 'SCNG',
    name: 'Scandinavian Corporate Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'SCNG',
    name: 'Scandinavia Corporate Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Region',
  },
  {
    code: 'SDEQ',
    name: 'Equity Sweden',
    isoImpliedByCode: 'SE',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SDFI',
    name: 'Fixed Income Sweden',
    isoImpliedByCode: 'SE',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SIEQ',
    name: 'Equity Singapore',
    isoImpliedByCode: 'SG',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SIFI',
    name: 'Fixed Income Singapore',
    isoImpliedByCode: 'SG',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SKEQ',
    name: 'Equity Korea (South), Republic of',
    isoImpliedByCode: 'KR',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SKFI',
    name: 'Fixed Income Korea (South), Republic of',
    isoImpliedByCode: 'KR',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SLEQ',
    name: 'Slovenian Equity',
    isoImpliedByCode: 'SI',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SLFI',
    name: 'Fixed Income Slovenia',
    isoImpliedByCode: 'SI',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SPEQ',
    name: 'Equity Spain',
    isoImpliedByCode: 'ES',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SPFI',
    name: 'Fixed Income Spain',
    isoImpliedByCode: 'ES',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SPNG',
    name: 'Spanish Corporate Fixed Interest',
    isoImpliedByCode: 'ES',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'STRC',
    name: 'Structured Finance',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'SUFI',
    name: 'Fixed Income Supranational',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'SUFI',
    name: 'Supranational Fixed Interest',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
  {
    code: 'SZEQ',
    name: 'Equity Switzerland',
    isoImpliedByCode: 'CH',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SZFI',
    name: 'Fixed Income Switzerland',
    isoImpliedByCode: 'CH',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'SZNG',
    name: 'Switzerland Corporate Fixed Interest',
    isoImpliedByCode: 'CH',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'TAEQ',
    name: 'Equity Taiwan (Republic of China)',
    isoImpliedByCode: 'TW',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'TAFI',
    name: 'Fixed Income Taiwan (Republic of China)',
    isoImpliedByCode: 'TW',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'THEQ',
    name: 'Equity Thailand',
    isoImpliedByCode: 'TH',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'THFI',
    name: 'Fixed Income Thailand',
    isoImpliedByCode: 'TH',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'TUEQ',
    name: 'Turkish Equities',
    isoImpliedByCode: 'TR',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'UAEQ',
    name: 'Equity United Arab Emirates',
    isoImpliedByCode: 'AE',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'UKEQ',
    name: 'UK Equities',
    isoImpliedByCode: 'GB',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'UKEQ',
    name: 'Equity Isle of Man',
    isoImpliedByCode: '',
    isoImpliedByName: 'IM',
    noIsoReason: '',
  },
  {
    code: 'UKFI',
    name: 'UK Fixed Interest',
    isoImpliedByCode: 'GB',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'UKGO',
    name: 'UK Gilts',
    isoImpliedByCode: 'GB',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'UKHY',
    name: 'UK High Yield Fixed Interest',
    isoImpliedByCode: 'GB',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'UKIG',
    name: 'UK Index-Linked Gilts',
    isoImpliedByCode: 'GB',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'UKIL',
    name: 'UK index-linked bonds',
    isoImpliedByCode: 'GB',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'UKNG',
    name: 'UK Corporate Fixed Interest',
    isoImpliedByCode: 'GB',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'USEQ',
    name: 'US Equities',
    isoImpliedByCode: 'US',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'USFI',
    name: 'US Fixed Interest',
    isoImpliedByCode: 'US',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'USGO',
    name: 'US Government Fixed Interest',
    isoImpliedByCode: 'US',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'USNG',
    name: 'US Corporate Fixed Interest',
    isoImpliedByCode: 'US',
    isoImpliedByName: '',
    noIsoReason: '',
  },
  {
    code: 'WNTS',
    name: 'Warrant',
    isoImpliedByCode: '',
    isoImpliedByName: '',
    noIsoReason: 'Category',
  },
];

export const codeMapping = keyBy(
  rawData.filter(({ isoImpliedByCode }) => isoImpliedByCode),
  'code'
);

export const nameMapping = keyBy(
  rawData.filter(({ isoImpliedByName }) => isoImpliedByName),
  'name'
);
