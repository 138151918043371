import { useMediaQuery, useTheme } from '@material-ui/core';
import { Pill } from 'components/design-system/Pill/Pill';
import { CustomSwipeableDrawer } from 'components/design-system/SwipeableDrawer/SwipeableDrawer';
import { FontSize, FontWeight, Text } from 'components/design-system/Text/Text';
import { useState } from 'react';
import { BiPurchaseTagAlt } from 'react-icons/bi';
import styled from 'styled-components';

interface TagsWrapperProps {
  centeredOnLarge?: boolean;
}
const TagsWrapper = styled.div<TagsWrapperProps>`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1.5rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: center;
    margin: 1.5rem 0;
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    justify-content: ${({ centeredOnLarge }) =>
      centeredOnLarge ? 'center' : 'flex-start'};
    margin: 1.5rem 0;
  }
`;

const TagsButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
`;

interface TagToRender {
  name: string;
  isActive: boolean;
  display: boolean;
  categoryCode: string;
  code: string;
  isImportant: boolean;
}

interface HeaderTagsProps {
  tags: TagToRender[];
  centeredOnLarge?: boolean;
}

export const HeaderTags = ({ tags, centeredOnLarge }: HeaderTagsProps) => {
  const theme = useTheme();
  const isLarge = useMediaQuery(theme.breakpoints.up('md'));
  const [isExpanded, setIsExpanded] = useState(false);

  if (!tags || tags.length === 0) {
    return null;
  }

  return isLarge ? (
    <TagsWrapper centeredOnLarge={centeredOnLarge}>
      {tags.map(
        (tag, index) =>
          tag && (
            <Pill
              $color={tag.isActive ? 'purple' : 'vapor'}
              key={`${tag.code}-${index}`}
            >
              {tag.name}
            </Pill>
          )
      )}
    </TagsWrapper>
  ) : (
    <>
      <TagsButtonWrapper
        onClick={() => {
          setIsExpanded(true);
        }}
      >
        <BiPurchaseTagAlt />
        <Text
          $noMargin
          $fontSize={FontSize.small}
          $fontWeight={FontWeight.normal}
        >
          {tags.length} tags
        </Text>
      </TagsButtonWrapper>
      {isExpanded && (
        <CustomSwipeableDrawer
          onClose={() => {
            setIsExpanded(false);
          }}
          title="Fund tags"
          height="short"
        >
          <TagsWrapper>
            {tags.map(
              (tag, index) =>
                tag && (
                  <Pill
                    $color={tag.isActive ? 'purple' : 'vapor'}
                    key={`${tag.code}-${index}`}
                  >
                    {tag.name}
                  </Pill>
                )
            )}
          </TagsWrapper>
        </CustomSwipeableDrawer>
      )}
    </>
  );
};
