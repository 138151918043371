import { StyledLink } from 'components/design-system/Link';
import { Form } from 'components/Form/Form';
import { FormState } from 'components/Form/FormState';
import { MobileNumberField } from 'components/Form/MobileNumberInputField';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';

import { PhoneNumberType, useAddPhoneNumberMutation } from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import { PhoneNumber } from 'libphonenumber-js/mobile';
import * as Yup from 'yup';
import { TestFunction } from 'yup';
import {
  SkipButton,
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepFormContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';

const validMobileNumber: TestFunction = (value: PhoneNumber) =>
  value && !!value.countryCallingCode.length && value.isValid();

const mobileNumberFormSchema = Yup.object().shape({
  mobileNumber: Yup.mixed<PhoneNumber>()
    .label('Phone number')
    .nullable()
    .test(
      'valid-mobile-number',
      'Please enter a valid phone number with country code',
      validMobileNumber
    ),
});

type EnterMobileNumberFormValues = Yup.Asserts<typeof mobileNumberFormSchema>;

interface EnterMobileNumberStepProps {
  onSkip?: () => void;
  onCodeSent?: (number: string) => void;
  title: string;
}

export function EnterMobileNumberStep({
  onSkip,
  onCodeSent,
  title,
}: EnterMobileNumberStepProps) {
  const { mutateAsync } = useAddPhoneNumberMutation();
  const [allowTotp] = useToggle('global-allow-totp');

  const defaultValues = mobileNumberFormSchema.getDefault();

  const onSubmit = async (data: EnterMobileNumberFormValues) => {
    let type = PhoneNumberType.Unknown;
    switch (data.mobileNumber!.getType()) {
      case 'MOBILE':
        type = PhoneNumberType.Mobile;
        break;
      case 'FIXED_LINE':
        type = PhoneNumberType.Landline;
        break;
    }

    const number = data.mobileNumber!.number.toString();

    await mutateAsync({
      input: {
        number,
        type,
      },
    });

    trackGa({
      event: GaEventNames.onboarding,
      onboardingStep: OnboardingStepNames.mobileMfa,
    });

    onCodeSent?.(number);
  };

  return (
    <StepContainer>
      <Form<EnterMobileNumberFormValues>
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        schema={mobileNumberFormSchema}
      >
        <StepContent>
          <StepTitle>{title ? title : 'Secure your account'}</StepTitle>

          <StepIntroduction mb={2} $width={StepIntroductionWidth.extraWide}>
            <StepIntroductionTypography>
              {allowTotp?.enabled ? (
                <>
                  Enter your mobile phone number to receive a one-time code.
                  We'll use this to register your phone number as your two-step
                  verification method.
                </>
              ) : (
                <>
                  To keep your account secure, we encourage all of our customers
                  to use two-step verification when logging in.{' '}
                  <StyledLink
                    href="https://knowledge.tillitinvest.com/faqs#how-does-two-step-verification-help-secure-my-account"
                    target="_blank"
                  >
                    What is this?
                  </StyledLink>
                </>
              )}
            </StepIntroductionTypography>
          </StepIntroduction>
          <StepFormContainer>
            <MobileNumberField
              name="mobileNumber"
              label="Mobile number"
              autoFocus
            />
          </StepFormContainer>
        </StepContent>
        <StepActions>
          <FormState>
            {({ isSubmitting }) => (
              <StepButton
                type="submit"
                className="magenta"
                disabled={isSubmitting}
              >
                Continue
              </StepButton>
            )}
          </FormState>
          {onSkip && (
            <SkipButton
              className="richBlue"
              variant="outlined"
              onClick={onSkip}
            >
              Skip
            </SkipButton>
          )}
        </StepActions>
      </Form>
    </StepContainer>
  );
}
