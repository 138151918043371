import { TransferPrompt } from 'components/TransferPrompt/TransferPrompt';
import {
  LinkStepButton,
  StepActions,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { GaEventNames } from 'constants/gaConstants';
import {
  ActionType,
  WrapperType,
  useActionsQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { useToggle } from 'hooks/useFeatureToggle';
import {
  actionsPath,
  generateActionsAccountPath,
  generateActionsTypePath,
  generateDashboardAccountAddCashPath,
} from 'paths';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { TransferPromptContainer } from './SecondAccount.styles';

interface SuccessStepProps {
  accountType: WrapperType;
  onClose: () => void;
}

const accountTypeFriendlyNameLookup: Partial<Record<WrapperType, string>> = {
  [WrapperType.Gia]: 'General Investment Account',
  [WrapperType.Isa]: 'Stocks & Shares ISA',
  [WrapperType.Sipp]: 'Pension',
};

export function SuccessStep({ accountType, onClose }: SuccessStepProps) {
  const history = useHistory();

  const [actionsEnabled] = useToggle('global-actions');
  const actionQuery = useActionsQuery(undefined, {
    enabled: actionsEnabled?.enabled,
  });
  const userProfileQuery = useUserProfileQuery();
  const account = userProfileQuery.data?.clientSummary?.accounts.find(
    (account) => account.wrapperType === accountType
  );

  const fundAccountAction = actionQuery.data?.actions?.find(
    (action) =>
      action.type === ActionType.FundAccount && action.accountId === account?.id
  );

  const title = accountTypeFriendlyNameLookup[accountType] ?? accountType;

  useEffect(() => {
    history.push(`/dashboard/${accountType.toLowerCase()}`);
  }, [history, accountType]);

  const handleBrowseFunds = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: 'Browse funds',
    });
    onClose();
  };

  const handleContinueFunds = () => {
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'cta button',
      item_id: 'Continue to actions',
    });
    onClose();
  };

  const handleAddCash = () => {
    trackGa({
      event: GaEventNames.viewItem,
      orderType: 'cash deposit',
      ecommerce: {
        items: [
          {
            item_id: 'cash',
            item_name: 'Cash deposit',
            affiliation: 'Tillit',
            currency: 'GBP',
            item_variant: accountType,
          },
        ],
      },
    });
    onClose();
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>Your new {title} is ready!</StepTitle>
        <StepIntroduction $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            We've sent you an email with your account details.
          </StepIntroductionTypography>
        </StepIntroduction>
      </StepContent>

      {actionsEnabled?.enabled ? (
        <StepActions $isHorizontal>
          <LinkStepButton
            $minWidth="narrow"
            to={
              fundAccountAction
                ? generateActionsTypePath({
                    actionType: 'fund-account',
                    actionId: fundAccountAction.id,
                  }) + '?source=account'
                : account
                ? generateActionsAccountPath({
                    wrapperType: getPathSegmentForWrapperType(accountType),
                    accountId: account?.id,
                  })
                : actionsPath
            }
            className="magenta"
            onClick={handleContinueFunds}
          >
            Continue
          </LinkStepButton>
        </StepActions>
      ) : (
        <>
          <StepActions $isHorizontal>
            <LinkStepButton
              $minWidth="narrow"
              to="/"
              className="richBlue"
              onClick={handleBrowseFunds}
            >
              Browse funds
            </LinkStepButton>
            <LinkStepButton
              $minWidth="narrow"
              to={generateDashboardAccountAddCashPath({
                wrapperType: getPathSegmentForWrapperType(accountType),
              })}
              className="magenta"
              onClick={handleAddCash}
            >
              Add cash
            </LinkStepButton>
          </StepActions>
          <TransferPromptContainer>
            <TransferPrompt
              source="Second account dialog"
              wrapperType={accountType}
              onClose={onClose}
            />
          </TransferPromptContainer>
        </>
      )}
    </StepContainer>
  );
}
