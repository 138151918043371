import { InstrumentSelectorAlignment } from 'components/InstrumentSelector/InstrumentSelector';
import { Instrument } from 'generated/graphql';
import { useToggle } from 'hooks/useFeatureToggle';
import { AssetQueryAsset } from 'types/graphqlTypes';
import { ShareClassesBox } from '../ShareClassesBox';
import { AssetClassChartSlice } from '../Slices/AssetClassChartSlice';
import { HoldingsBubbleChartSlice } from '../Slices/HoldingsBubbleChartSlice';
import { LifePathRegionMapSlice } from '../Slices/LifePathRegionMapSlice/LifePathRegionMapSlice';
import { RegionMapSlice } from '../Slices/RegionMapSlice';
import { SectorsTreeMapSlice } from '../Slices/SectorsTreeMapSlice';
import { BreakdownAttribution } from '../Slices/_shared/BreakdownAttribution';
import { processInstrumentHoldings } from '../Slices/_shared/breakdownAttributionHelpers';
import {
  BreakdownAttributionWrapper,
  BreakdownContainer,
  Title,
} from '../Styles/FundDetails.style';

interface BreakdownTabProps {
  asset: AssetQueryAsset;
  selectedIsin: string;
  assetClassName: string;
  handleInstrumentChange: (isin: string) => void;
}

const DEFAULT_PENSION_ASSET_ID = process.env
  .REACT_APP_DEFAULT_PENSION_TDF_INVESTMENT_ASSET_ID!;

export function BreakdownTab({
  asset,
  selectedIsin,
  assetClassName,
  handleInstrumentChange,
}: BreakdownTabProps) {
  const [lifePathRegionToggle] = useToggle('global-life-path-region');

  const currentInstrument = asset?.instruments?.nodes?.find(
    (i) => i.isin === selectedIsin
  ) as Instrument | undefined;

  if (!currentInstrument) {
    return null;
  }

  const {
    holdingsAttributionText,
    sectorAndRegionAttributionText,
    isValid,
  } = processInstrumentHoldings(currentInstrument as Instrument);

  const isMultiAsset = assetClassName === 'Multi-asset';

  return (
    <BreakdownContainer>
      <Title>Breakdown</Title>

      {!asset.isTargetDateFund && (
        <ShareClassesBox
          $align={InstrumentSelectorAlignment.center}
          instruments={asset?.instruments.nodes as Instrument[]}
          value={selectedIsin}
          onChange={handleInstrumentChange}
          $isWide={false}
        />
      )}

      {asset.id === parseInt(DEFAULT_PENSION_ASSET_ID) &&
      lifePathRegionToggle?.enabled ? (
        <LifePathRegionMapSlice instrument={currentInstrument} />
      ) : (
        <RegionMapSlice instrument={currentInstrument} />
      )}

      {isMultiAsset && <AssetClassChartSlice instrument={currentInstrument} />}
      <SectorsTreeMapSlice instrument={currentInstrument} />
      <HoldingsBubbleChartSlice instrument={currentInstrument} />

      {isValid && (
        <BreakdownAttributionWrapper>
          <div>
            <BreakdownAttribution
              holdingsAttributionText={holdingsAttributionText}
              sectorAndRegionAttributionText={sectorAndRegionAttributionText}
              alignCenter
            />
          </div>
        </BreakdownAttributionWrapper>
      )}
    </BreakdownContainer>
  );
}
