import { InfoPopover } from 'components/Popover';
import { currencyFull, date } from 'formatting';
import { CashTransactionStatus } from 'generated/graphql';
import {
  BodyContent,
  ComponentContainer,
  Description,
  Details,
  Head,
} from './CashTransaction.style';

interface CashTransactionsProps {
  transactionLinkedAsset?: string;
  transactionDescription: string;
  transactionAmount: number;
  transactionDate: string;
  transactionStatus: CashTransactionStatus;
}

export function CashTransactions({
  transactionLinkedAsset,
  transactionDescription,
  transactionAmount,
  transactionDate,
  transactionStatus,
}: CashTransactionsProps) {
  return (
    <ComponentContainer>
      <Head>
        <span>{transactionDescription}</span>
        {transactionStatus === CashTransactionStatus.Pending && (
          <InfoPopover content="In progress" color="primary" size="small" />
        )}
      </Head>
      <BodyContent>
        {transactionLinkedAsset && (
          <Description>{transactionLinkedAsset}</Description>
        )}
        <Details>
          <span>{currencyFull(transactionAmount)}</span>
          <span>{date(transactionDate)}</span>
        </Details>
      </BodyContent>
    </ComponentContainer>
  );
}
