import { colors } from 'constants/colors';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

interface SwiperProps {
  $pagination?: boolean;
}

export const SwiperCardContainer = styled(Swiper)<SwiperProps>`
  width: 100%;
  padding-bottom: 30px;
  overflow: visible;
  .swiper-pagination {
    bottom: 0px !important;
    .swiper-pagination-bullet {
      width: 8px !important;
      height: 8px !important;
      margin: 0 3px !important;
      opacity: 0.5;
      background-color: ${colors.magenta};
      &.swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
  .swiper-wrapper {
    padding-bottom: ${({ $pagination }) => ($pagination ? '1.5rem' : '0')};
    .swiper-slide {
      width: 100%;
      height: auto;
      opacity: 1;
      box-sizing: border-box;
      transition: transform 0.4s ease-in-out;
      > div {
        height: 100%;
      }
      &.swiper-slide-active {
        opacity: 1;
      }
      &.swiper-slide-prev,
      &.swiper-slide-next {
        opacity: 1;
      }
    }
  }
`;
