import { Button, ButtonProps } from '@material-ui/core';
import { colors } from 'constants/colors';
import { Link, LinkProps } from 'react-router-dom';
import Styled from 'styled-components';

/**
 * @deprecated - CustomButtonV2 should now be used instead
 *
 *  Custom Button Styles
 *  How to use?
 *
 * Import this file instead of the Button from MUI and pass all usual
 * props to it, to change the look of the button just add in a className
 * to it to extend it's appearance
 */

export const CustomButton = Styled(Button)`
  color: ${(p) => (p.variant === 'outlined' ? colors.richBlack : colors.white)};
  background-color:  ${(p) =>
    p.variant === 'outlined' ? colors.vapor : colors.richBlack};
  padding: ${(p) => p.theme.spacing(1.25, 3.75)};
  box-shadow: none;
  border-radius: 10px;
  white-space: nowrap;
  transform: translateY(0);
  transition: transform 0.25s ease-in-out;

  &:not(.small) {
    line-height: 1.056rem;
    ${(p) => p.theme.breakpoints.up('md')} {
      line-height: 1.531rem;
    }
  }

  &:hover {
    color: ${(p) =>
      p.variant === 'outlined' ? colors.richBlack : colors.white};
    background-color:  ${(p) =>
      p.variant === 'outlined' ? 'transparent' : colors.richBlack};
    box-shadow: none;

    ${(p) => p.theme.breakpoints.up('lg')} {
      &:not(.no-hover) {
        transform: translateY(-5px);
      }
    }
  }
  &:active {
    box-shadow: none;
  }

  &.small {
    padding: ${(p) => p.theme.spacing(0.25, 1.25)};
    border-radius: 7px;
  }

  &.white {
    color: ${(p) =>
      p.variant === 'outlined' ? colors.white : colors.richBlack};
    ${(p) => p.variant === 'outlined' && `border-color: ${colors.white};`}
    background-color:  ${(p) =>
      p.variant === 'outlined' ? 'transparent' : colors.white};
  }

  &.richBlack {
    &[disabled] {
      color: ${(p) =>
        p.variant === 'outlined' ? colors.richBlack : colors.white};
    }
  }

  &.richBlue {

    color: ${(p) =>
      p.variant === 'outlined' ? colors.richBlue : colors.white};
    ${(p) => p.variant === 'outlined' && `border-color: ${colors.richBlue};`}
    background-color:  ${(p) =>
      p.variant === 'outlined' ? 'transparent' : colors.richBlue};

    &:hover, &.active {
      color: ${(p) =>
        p.variant === 'outlined' ? colors.richBlue : colors.white};
      background-color:  ${(p) =>
        p.variant === 'outlined' ? 'transparent' : colors.richBlue};
    }

  }

  &.seaBlue {

    color: ${(p) => (p.variant === 'outlined' ? colors.seaBlue : colors.white)};
    ${(p) => p.variant === 'outlined' && `border-color: ${colors.seaBlue};`}
    background-color:  ${(p) =>
      p.variant === 'outlined' ? 'transparent' : colors.seaBlue};

    &:hover, &.active {
      color: ${(p) =>
        p.variant === 'outlined' ? colors.seaBlue : colors.white};
      background-color:  ${(p) =>
        p.variant === 'outlined' ? 'transparent' : colors.seaBlue};
    }

  }

  &.purple {

    color: ${(p) => (p.variant === 'outlined' ? colors.purple : colors.white)};
    ${(p) => p.variant === 'outlined' && `border-color: ${colors.purple};`}
    background-color:  ${(p) =>
      p.variant === 'outlined' ? 'transparent' : colors.purple};

    &:hover, &.active {
      color: ${(p) =>
        p.variant === 'outlined' ? colors.purple : colors.white};
      background-color:  ${(p) =>
        p.variant === 'outlined' ? 'transparent' : colors.purple};
    }

  }

  &.magenta {

    color: ${(p) =>
      p.variant === 'outlined' ? colors.magentaSafe : colors.white};
    ${(p) => p.variant === 'outlined' && `border-color: ${colors.magentaSafe};`}
    background-color:  ${(p) =>
      p.variant === 'outlined' ? 'transparent' : colors.magentaSafe};

    &:hover, &.active {
      color: ${(p) =>
        p.variant === 'outlined' ? colors.magentaSafe : colors.white};
      background-color:  ${(p) =>
        p.variant === 'outlined' ? 'transparent' : colors.magentaSafe};
    }

  }

  &.mauve {

    color: ${(p) => (p.variant === 'outlined' ? colors.mauve : colors.white)};
    ${(p) => p.variant === 'outlined' && `border-color: ${colors.mauve};`}
    background-color:  ${(p) =>
      p.variant === 'outlined' ? 'transparent' : colors.mauve};

      * {
        color: ${(p) =>
          p.variant === 'outlined' ? colors.mauve : colors.white};
      }

    &:hover, &.active {
      color: ${(p) => (p.variant === 'outlined' ? colors.mauve : colors.white)};
      background-color:  ${(p) =>
        p.variant === 'outlined' ? 'transparent' : colors.mauve};
    }

  }

  // TODO: Re-consider
  &.remove {
    background-color: ${(p) => p.theme.palette.error.main};
    color: ${(p) => p.theme.palette.error.contrastText};
    &:hover, &.active: {
      background-color: ${(p) => p.theme.palette.error.dark};
    }
    margin-bottom: ${(p) => p.theme.spacing(1)};
  }

  &[disabled] {
    opacity: 0.5;
  }
`;

type LinkCustomButtonProps = ButtonProps & LinkProps;
/**
 * LinkCustomButton
 *
 * Combines the CustomButton with react-router-dom's Link Component.
 *
 * This should be used for internal links when a link needs to be styled as a button.
 *
 * Implementations notes -
 *   1 - The react-router-dom's Link component is used - this is for links within the app.
 *   2 - MUI & styled-components both have a prop that allows you to change the type base element
 *        - styled-components has an 'as' prop (https://styled-components.com/docs/api#as-polymorphic-prop)
 *        - MUI has a 'component' prop (https://mui.com/material-ui/react-button/#upload-button)
 *       Here we need to use the MUI component prop - we still want styled-components to use the
 *       MUI Button as the base component.
 *
 * @example ```
 *   import { openAccountPath } from 'paths';
 *   // ...
 *   <LinkCustomButton className="magenta" to={openAccountPath} />
 * ```
 *
 * @todo - add example that allows tracking on click.
 *
 * @param props - ButtonProps & LinkProps
 * @returns
 */
export const LinkCustomButton = (props: LinkCustomButtonProps) => (
  <CustomButton component={Link} {...props} />
);

export const CustomMobileNav = Styled(Button)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  padding: ${(p) => p.theme.spacing(1.25, 2.5)};
  box-shadow: none;
  white-space: nowrap;
  color: ${colors.richBlack};
  border: 0;
  border-bottom: 1px ${colors.magenta} solid;
  border-radius: 0;
  background-color: transparent;
  margin: ${(p) => p.theme.spacing(2.5)}px auto;

  &:hover {
    color: ${colors.richBlack};
    background-color: transparent;
  }

  &.active {
    color: ${colors.richBlack};
  }
`;
