import { OptionCard } from 'components/design-system/Card/OptionCard';
import { StyledLink } from 'components/design-system/Link';
import { WrapperType } from 'generated/graphql';
import { getPathSegmentForWrapperType } from 'helpers/accountHelpers';
import {
  fundListPath,
  generateAutoSaveInvestSubPath,
  generateDashboardAccountAddCashPath,
  generateStartTransferPath,
} from 'paths';
import { useHistory } from 'react-router-dom';
import {
  StepActions,
  StepContainer,
  StepContent,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from '../../../../design-system/StepComponents/StepComponents';
import { NextStepsWrapper } from './NextSteps.styles';

export function NextStepsStep() {
  const history = useHistory();

  const handleAddCash = () => {
    history.push(
      generateDashboardAccountAddCashPath({
        wrapperType: getPathSegmentForWrapperType(WrapperType.Sipp),
      })
    );
  };

  const handleSetUpRegularDeposit = () => {
    history.push({
      pathname: generateAutoSaveInvestSubPath({
        wrapperType: getPathSegmentForWrapperType(WrapperType.Sipp),
        action: 'create-deposit',
      }),
      state: { from: 'open-sipp-next-step' },
    });
  };

  const handleTransferAccount = () => {
    history.push(generateStartTransferPath({ accountType: 'pension' }));
  };

  return (
    <StepContainer>
      <StepContent>
        <StepTitle>
          Congratulations, your TILLIT Pension is now ready!
        </StepTitle>

        <StepIntroduction mb={2} $width={StepIntroductionWidth.normal}>
          <StepIntroductionTypography>
            Choose how to fund your new account or browse the TILLIT Universe of
            funds.
          </StepIntroductionTypography>
        </StepIntroduction>
      </StepContent>
      <StepActions>
        <NextStepsWrapper>
          <OptionCard
            title="Add cash"
            buttonLabel="Add cash"
            onClick={() => handleAddCash()}
          >
            <>Easily deposit cash via your banking app.</>
          </OptionCard>

          <OptionCard
            title="Start a regular deposit"
            buttonLabel="Set up regular deposit"
            onClick={() => handleSetUpRegularDeposit()}
          >
            <>
              Set up a regular deposit from your banking app to top up your
              account each month.
            </>
          </OptionCard>

          <OptionCard
            title="Consolidate your pensions"
            buttonLabel="Transfer a pension"
            onClick={() => handleTransferAccount()}
          >
            <>Transfer from other providers into your TILLIT Pension.</>
          </OptionCard>
        </NextStepsWrapper>
        <StyledLink href={fundListPath}>Browse funds</StyledLink>
      </StepActions>
    </StepContainer>
  );
}
