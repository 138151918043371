import { AccordionDetails, AccordionSummary } from '@material-ui/core';
import { FontWeight, Text } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import { HiChevronDown } from 'react-icons/hi';
import styled from 'styled-components';

/**
 * @file - Text Accordion
 *
 * @description - A custom accordion component that uses the MUI Accordion
 *   component, styled with a simple line to divide the row
 *
 *
 * @todo
 *  - Add stories
 *  - Refactor to avoid using the component that uses React.Children.toArray / clone
 *  - Move to design-system folder
 */

const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0;
  justify-content: space-between !important;
  .Mui-expanded {
    margin-right: -12px;
  }
  .MuiAccordionSummary-content {
    margin: 0;
    padding: 0.75rem 0;
  }
`;

interface TextAccordionAccordionSummary {
  label: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const TextAccordionSummary = ({
  label,
  onClick,
}: TextAccordionAccordionSummary) => {
  return (
    <StyledAccordionSummary
      IconButtonProps={{
        disableRipple: true,
      }}
      expandIcon={
        <HiChevronDown size={20} style={{ color: colors.darkBlue }} />
      }
      onClick={onClick}
    >
      <Text $fontWeight={FontWeight.medium} $noMargin>
        {label}
      </Text>
    </StyledAccordionSummary>
  );
};

export const TextAccordionDetails = styled(AccordionDetails)`
  padding: 0;
  flex-direction: column;

  ul {
    padding-left: 20px;
  }
`;
