import cardHexBackground from 'assets/card_background_white.png';
import * as format from 'formatting';
import { WrapperType } from 'generated/graphql';
import React from 'react';
import { ClosedActions } from './AccountActions';
import { AccountData } from './AccountCard';
import {
  WhiteCardTitle,
  WhiteCardDescription,
  ContentContainer,
  WhiteLabel,
  StyledButtonsWrapper,
  WhiteValue,
  ValueContainer,
} from './AccountCard.style';

interface PureAccountClosedCardProps {
  accountType: WrapperType;
  accountData?: AccountData;
  onWithdraw: () => void;
  onReOpen: () => void;
}

export function PureAccountClosedCard({
  accountType,
  accountData,
  onWithdraw,
  onReOpen,
}: PureAccountClosedCardProps) {
  return (
    <>
      <WhiteCardTitle accountType={accountType} />
      {accountData && (
        <>
          <ContentContainer vAlign="center" imgUrl={cardHexBackground}>
            {accountData?.uninvestedCash! > 0 && (
              <ValueContainer>
                <WhiteValue size="small">
                  {format.currencyFull(accountData?.uninvestedCash ?? 0)}
                </WhiteValue>
                <WhiteLabel size="small">Available cash</WhiteLabel>
              </ValueContainer>
            )}
            <WhiteCardDescription>This account is closed.</WhiteCardDescription>
          </ContentContainer>
          <StyledButtonsWrapper>
            <ClosedActions
              accountType={accountType}
              value={accountData.uninvestedCash}
              onReOpen={onReOpen}
              onWithdrawCash={onWithdraw}
            />
          </StyledButtonsWrapper>
        </>
      )}
    </>
  );
}
