import { StyledA } from 'components/design-system/Link';
import { Text } from 'components/design-system/Text/Text';
import {
  ContentContainerInner,
  Title,
  ViewContainer,
} from '../Styles/FundDetails.style';

export function EmployerExclusiveExplainerSlice() {
  return (
    <ContentContainerInner maxWidth="lg">
      <ViewContainer>
        <Title>Employer Exclusive</Title>
        <Text>
          This fund is available to you through an exclusive agreement with your
          employer. It is only available to you and your fellow colleagues and
          you will only see it when you are logged into your account.
        </Text>
        <Text>
          Because it was made available on request by your employer, it is part
          of our Dark Universe which means there is limited information
          available about the fund. There is no TILLIT View for this fund and
          our team does not perform any of our standard due diligence. However,
          basic information such as fees, performance, top holdings and key
          documents is available where applicable.
        </Text>
        <Text>
          If you have questions about Employer Exclusive funds, please contact
          us at{' '}
          <StyledA href="mailto:pensions@tillitinvest.com">
            pensions@tillitinvest.com
          </StyledA>
          .
        </Text>
      </ViewContainer>
    </ContentContainerInner>
  );
}
