import { colors } from 'constants/colors';
import { Typography, IconButton } from '@material-ui/core';
import { OrderDirection } from 'generated/graphql';
import styled from 'styled-components';

interface directionProp {
  direction: OrderDirection;
}

export const NoteContainer = styled.div<directionProp>`
  width: 100%;
  position: relative;
  background-color: ${(p) =>
    p.direction === OrderDirection.Buy ? colors.magenta : colors.richBlue};
  color: ${colors.white};
  padding: ${(p) => p.theme.spacing(1.25)}px;
  border-radius: 5px;
`;

export const Content = styled(Typography)`
  font-size: 1rem;
  color: ${colors.white};
  font-weight: 500;
  margin: ${(p) => p.theme.spacing(0, 0, 1.25, 0)};
  span {
    font-size: 0.875rem;
    font-weight: 300;
  }
`;

export const Pill = styled.div<directionProp>`
  display: inline-block;
  background-color: ${colors.white};
  padding: ${(p) => p.theme.spacing(0.5, 2)};
  border-radius: ${(p) => p.theme.spacing(4)}px;
  color: ${(p) =>
    p.direction === OrderDirection.Buy ? colors.magenta : colors.richBlue};
`;

export const Download = styled(IconButton)`
  color: ${colors.white};
`;
