import { HeaderShim } from 'components/design-system/Header';
import { DepositForm } from 'components/Dialogs/Deposit/DepositForm';
import { DepositFormVariant } from 'components/Dialogs/Deposit/DepositFormVariant';
import { QueryState } from 'components/QueryState';
import { AccountType, useActionsQuery } from 'generated/graphql';
import { generateActionsTypePath } from 'paths';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ActionsQueryAction } from 'types/graphqlTypes';

interface AddCashStepProps {
  accountType?: AccountType;
  onProceed: (action: ActionsQueryAction) => void;
}

const Wrapper = styled.div`
  margin: 2rem auto 0 auto;
  max-width: 40rem;
  .MuiDialogContent-root {
    padding: 0.5rem 0;
  }
  .MuiDialogActions-root {
    justify-content: center;
    padding: 0.5rem 0;
    flex-direction: column;
  }
  .trueLayer__selectProviderStep.MuiDialogContent-root {
    padding: 0.5rem 1rem;
  }
`;

export function AddCashStep({ onProceed }: AddCashStepProps) {
  const location = useLocation();
  const { search } = location;

  const queryParams = new URLSearchParams(search);
  const paymentId = queryParams.has('paymentId')
    ? (queryParams.get('paymentId') as string)
    : undefined;

  const { actionId } = useParams<{ actionId: string }>();
  const actionsQuery = useActionsQuery();
  const addCashAction = actionsQuery.data?.actions?.find(
    ({ id }) => id === actionId
  );

  const redirectToAccountActionsList =
    queryParams.has('source') && queryParams.get('source') === 'account';

  const returnPath =
    process.env.REACT_APP_BASE_URL +
    generateActionsTypePath({ actionType: 'add-cash', actionId }) +
    (redirectToAccountActionsList ? '?source=account' : '');

  return (
    <QueryState {...actionsQuery}>
      {() => {
        return (
          <Wrapper>
            <HeaderShim />
            {addCashAction && addCashAction.accountId && (
              <DepositForm
                accountId={addCashAction.accountId}
                variant={DepositFormVariant.Actions}
                returnPath={returnPath}
                urlPaymentId={paymentId}
                defaultValue={addCashAction.amount || null}
                isCheckout
                onClose={() => onProceed(addCashAction)}
                proceedOnComplete
              />
            )}
          </Wrapper>
        );
      }}
    </QueryState>
  );
}
