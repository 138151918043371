import { colors } from 'constants/colors';

export function useSelectAssetColor(name: string) {
  switch (name) {
    case 'Equities':
      return colors.purple;

    case 'Bonds':
      return colors.richBlue;

    case 'Property':
      return colors.magenta;

    case 'Alternative assets':
      return colors.seaBlue;

    case 'Multi-asset':
      return colors.mauve;

    default:
      return colors.richBlack;
  }
}
