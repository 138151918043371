import { Alert, Severity } from 'components/design-system/Alert/Alert';
import {
  H6Underline,
  TextAlign as HeadingAlign,
} from 'components/design-system/Heading/Heading';
import {
  StepActions,
  StepButton,
  StepContainer,
  StepContent,
  StepContentWidth,
  StepIntroduction,
  StepIntroductionTypography,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { TextAlign, TextNormal } from 'components/design-system/Text/Text';
import { colors } from 'constants/colors';
import {
  ActionType,
  useActionsQuery,
  useDismissActionMutation,
  useUserProfileQuery,
} from 'generated/graphql';
import { getShortNameForWrapperType } from 'helpers/accountHelpers';
import { useForm } from 'react-hook-form';
import { FaCheck } from 'react-icons/fa6';
import { useParams } from 'react-router-dom';
import { ActionsQueryAction } from 'types/graphqlTypes';
import { CardActionOption, Wrapper } from './FundAccount.styles';

interface FundAccountProps {
  onProceed: (action: ActionsQueryAction) => void;
}

interface FormData {
  addCash: string;
  transfer: string;
  regularDeposit: string;
}

export const FundAccount = ({ onProceed }: FundAccountProps) => {
  const { mutateAsync, isError, isLoading } = useDismissActionMutation({
    onSuccess: () => {},
  });

  const methods = useForm<FormData>();
  const { handleSubmit, register, watch } = methods;

  const onSubmit = async (data: FormData) => {
    const replacementActionTypes = [];
    if (data.addCash === 'true') {
      replacementActionTypes.push(ActionType.AddCash);
    }
    if (data.transfer === 'true') {
      replacementActionTypes.push(ActionType.StartTransfer);
    }
    if (data.regularDeposit === 'true') {
      replacementActionTypes.push(ActionType.SetupRegularDeposit);
    }
    try {
      await mutateAsync({
        id: fundAccountAction!.id,
        replacementActionTypes,
      });
      onProceed(fundAccountAction!);
    } catch {
      // Error is handled state
    }
  };

  const { actionId } = useParams<{ actionId: string }>();
  const actionsQuery = useActionsQuery();
  const fundAccountAction = actionsQuery.data?.actions?.find(
    ({ id }) => id === actionId
  );

  const userProfile = useUserProfileQuery();
  const accounts = userProfile.data?.clientSummary?.accounts ?? [];
  const account = fundAccountAction?.accountId
    ? accounts.find(({ id }) => id === fundAccountAction.accountId)
    : undefined;

  const watchAddCash = watch('addCash');
  const watchTransfer = watch('transfer');
  const watchRegularDeposit = watch('regularDeposit');

  return (
    <StepContainer>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'contents' }}>
        <StepContent>
          <StepTitle $textAlign={HeadingAlign.center}>
            How would you like to fund your account?
          </StepTitle>
          <StepIntroduction mb={2}>
            <StepIntroductionTypography>
              Select the ways in which you'd like to fund your account
            </StepIntroductionTypography>
          </StepIntroduction>
        </StepContent>
        <StepContent width={StepContentWidth.extraWide}>
          <Wrapper>
            <CardActionOption
              forwardedAs="label"
              fullCardPointer
              $isActive={watchAddCash}
            >
              <FaCheck
                size="2rem"
                color={watchAddCash ? colors.magenta : colors['grey-300']}
              />
              <H6Underline $textAlign={HeadingAlign.center} $noMargin>
                Add cash
              </H6Underline>
              <TextNormal $textAlign={TextAlign.center} $noMargin>
                Easily deposit cash via your banking app
              </TextNormal>
              <input
                type="checkbox"
                name="addCash"
                value="true"
                ref={register}
                style={{ display: 'none' }}
              />
            </CardActionOption>
            <CardActionOption
              forwardedAs="label"
              fullCardPointer
              $isActive={watchTransfer}
            >
              <FaCheck
                size="2rem"
                color={watchTransfer ? colors.magenta : colors['grey-300']}
              />
              <H6Underline $textAlign={HeadingAlign.center} $noMargin>
                Transfer
              </H6Underline>
              <TextNormal $textAlign={TextAlign.center} $noMargin>
                Transfer other{' '}
                {account
                  ? `${getShortNameForWrapperType(account.wrapperType)}s`
                  : 'accounts'}{' '}
                to TILLIT
              </TextNormal>
              <input
                type="checkbox"
                name="transfer"
                value="true"
                ref={register}
                style={{ display: 'none' }}
              />
            </CardActionOption>
            <CardActionOption
              forwardedAs="label"
              fullCardPointer
              $isActive={watchRegularDeposit}
            >
              <FaCheck
                size="2rem"
                color={
                  watchRegularDeposit ? colors.magenta : colors['grey-300']
                }
              />
              <H6Underline $textAlign={HeadingAlign.center} $noMargin>
                Regular deposit
              </H6Underline>
              <TextNormal $textAlign={TextAlign.center} $noMargin>
                Make it easier to build your portfolio by setting up a regular
                deposit
              </TextNormal>
              <input
                type="checkbox"
                name="regularDeposit"
                value="true"
                ref={register}
                style={{ display: 'none' }}
              />
            </CardActionOption>
          </Wrapper>
        </StepContent>
        <StepActions>
          <StepButton type="submit" className="magenta" disabled={isLoading}>
            Continue
          </StepButton>

          {isError && (
            <Alert severity={Severity.error}>Failed to dismiss action</Alert>
          )}
        </StepActions>
      </form>
    </StepContainer>
  );
};
