import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { TextField } from 'components/Form/TextField';
import { QueryState } from 'components/QueryState';
import { StepActions } from 'components/StepContainer';
import { Alert, Severity } from 'components/design-system/Alert/Alert';
import {
  ButtonWrapper,
  CustomButtonV2,
} from 'components/design-system/Button/CustomButtonV2';
import { H4 } from 'components/design-system/Heading/Heading';
import { StyledLink } from 'components/design-system/Link';
import {
  StepButton,
  StepFormContainer,
} from 'components/design-system/StepComponents/StepComponents';
import { Text, TextSmall } from 'components/design-system/Text/Text';
import {
  ButtonContainer,
  Pill,
  PillContainer,
  Section,
} from 'components/feature/PortfolioBuilder/AddToBasket/AddToBasketDialog.style';
import { LocalRecurringDeposits } from 'components/feature/autoSaveInvest/AutoSaveInvestContext';
import {
  MIN_CONTRIBUTION_AMOUNT,
  MIN_CONTRIBUTION_AMOUNT_SIPP,
} from 'components/feature/autoSaveInvest/autoSaveInvestConstant';
import { GaEventNames } from 'constants/gaConstants';
import { currencyFull } from 'formatting';
import {
  WrapperType,
  useDepositDetailsByAccountQuery,
  useRecurringTransactionsQuery,
  useSetRecurringDepositsMutation,
} from 'generated/graphql';
import {
  getPathSegmentForWrapperType,
  getShortNameForWrapperType,
} from 'helpers/accountHelpers';
import { trackGa } from 'helpers/track';
import { amount } from 'helpers/yupExtensions';
import { useToggle } from 'hooks/useFeatureToggle';
import max from 'lodash/max';
import {
  generateAutoSaveInvestPathPath,
  generateAutoSaveInvestSubPath,
} from 'paths';
import { FormProvider, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import {
  AccountsQueryAccount,
  RecurringTransactionsQueryRecurringTransactionsDeposit,
  UserProfileQueryClientSummaryAccount,
} from 'types/graphqlTypes';
import * as Yup from 'yup';
import Reference from 'yup/lib/Reference';
import { SetupAutoInvestButton } from '../_shared/SetupAutoInvestButton';

export interface RegularDepositEditDialogFormValues {
  dayOfTheMonth: number;
  amount: number;
  autoInvest: boolean;
}

const depositSchema = Yup.object().shape({
  dayOfTheMonth: Yup.number()
    .label('Day of the month')
    .typeError('Please enter a valid number')
    .nullable()
    .required()
    .min(1)
    .max(28),
  amount: amount()
    .label('Amount')
    .typeError('Please enter a valid number')
    .nullable()
    .required()
    .min(Yup.ref('$minAmount') as Reference<number>)
    .max(Yup.ref('$maxAmount') as Reference<number>),
});

interface RegularDepositEditFormProps {
  onComplete: () => void;
  deposit:
    | RecurringTransactionsQueryRecurringTransactionsDeposit
    | LocalRecurringDeposits;
  index: number;
  account: AccountsQueryAccount | UserProfileQueryClientSummaryAccount;
  onDepositChange?: (
    deposit:
      | RecurringTransactionsQueryRecurringTransactionsDeposit
      | LocalRecurringDeposits
  ) => void;
  variant?: 'dashboard' | 'autoSaveInvest';
  showNoDepositsMessage?: boolean;
}

function isLocalRecurringDeposits(
  deposit:
    | RecurringTransactionsQueryRecurringTransactionsDeposit
    | LocalRecurringDeposits
): deposit is LocalRecurringDeposits {
  return Object.keys(deposit).includes('isNew');
}

export function RegularDepositEditForm({
  onComplete,
  deposit,
  index,
  account,
  onDepositChange,
  variant,
  showNoDepositsMessage,
}: RegularDepositEditFormProps) {
  const [setupRegularOrderToggle] = useToggle('global-setup-regular-order');
  const [removeNewKeepCashToggle] = useToggle(
    'global-auto-invest-remove-new-keep-cash'
  );

  const { showNewMessages } = useIntercom();
  const recurringTransactionsQuery = useRecurringTransactionsQuery(
    {
      accountId: account.id as string,
    },
    { enabled: typeof account.id === 'string' }
  );
  const { data } = recurringTransactionsQuery;

  const setRecurringDepositsMutation = useSetRecurringDepositsMutation();

  const depositDetailsByAccountQuery = useDepositDetailsByAccountQuery(
    {
      id: account.id as string,
    },
    { enabled: typeof account.id === 'string' }
  );
  const depositDetailsByAccount =
    depositDetailsByAccountQuery.data?.depositDetailsByAccount;
  const validRange = depositDetailsByAccount?.validRange;
  const minAmount = max([
    validRange?.minimumPaymentAmount,
    account.wrapperType === WrapperType.Sipp
      ? MIN_CONTRIBUTION_AMOUNT_SIPP
      : MIN_CONTRIBUTION_AMOUNT,
  ]);

  const methods = useForm<RegularDepositEditDialogFormValues>({
    resolver: yupResolver(depositSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    context: {
      minAmount,
      maxAmount: validRange?.maximumPaymentAmount,
    },
  });

  const showAutoInvestCheckbox =
    !removeNewKeepCashToggle?.enabled || !deposit?.autoInvest;

  const onSubmit = async (formData: RegularDepositEditDialogFormValues) => {
    if (onDepositChange) {
      const autoInvest = showAutoInvestCheckbox ? formData.autoInvest : true;

      onDepositChange({
        autoInvest,
        paymentDate: formData.dayOfTheMonth,
        amount: formData.amount,
        isEdited: true,
        isNew: isLocalRecurringDeposits(deposit) ? deposit.isNew : false,
      });
      onComplete();
      return;
    }

    if (!data?.recurringTransactions?.deposits || !account.id) {
      return;
    }
    try {
      await setRecurringDepositsMutation.mutateAsync({
        input: {
          accountId: account.id,
          deposits: [
            ...data?.recurringTransactions?.deposits.slice(0, index),
            {
              autoInvest: formData.autoInvest,
              paymentDate: formData.dayOfTheMonth,
              amount: formData.amount,
            },
            ...data?.recurringTransactions?.deposits.slice(index + 1),
          ],
        },
      });
      recurringTransactionsQuery.refetch();
      onComplete();
    } catch (e) {
      //error handled by state
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <QueryState {...depositDetailsByAccountQuery}>
          {() => {
            if (!minAmount) {
              return;
            }
            return (
              <div>
                <PillContainer>
                  <Pill $filled $color="standingOrder">
                    Regular deposit
                  </Pill>
                  <Pill>{getShortNameForWrapperType(account.wrapperType)}</Pill>
                </PillContainer>
                <H4>Edit regular deposit</H4>
                {showNoDepositsMessage && (
                  <>
                    <TextSmall>
                      Your regular deposit isn't set up for auto investment.
                    </TextSmall>
                    <TextSmall>
                      If you want to set up auto investment, you need to turn on
                      auto invest for this deposit or{' '}
                      <StyledLink
                        as={Link}
                        to={generateAutoSaveInvestSubPath({
                          wrapperType: getPathSegmentForWrapperType(
                            account.wrapperType
                          ),
                          action: 'create-deposit',
                        })}
                      >
                        setup a new regular deposit
                      </StyledLink>
                      .
                    </TextSmall>
                  </>
                )}
                <Section>
                  <StepFormContainer>
                    <TextField
                      name="dayOfTheMonth"
                      label="Day of the month"
                      defaultValue={deposit.paymentDate}
                      helpText={`Please note the day of the month must be between the 1st and the 28th of every month.`}
                    />
                    <TextField
                      name="amount"
                      label="Amount"
                      defaultValue={deposit.amount}
                      startAdornment={<Text $noMargin>£</Text>}
                      inputProps={{
                        inputMode: 'decimal',
                      }}
                      helpText={`Minimum deposit: ${currencyFull(minAmount)}`}
                    />
                    {showAutoInvestCheckbox && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="autoInvest"
                            defaultChecked={deposit?.autoInvest}
                            inputRef={methods.register}
                            disabled={
                              variant === 'autoSaveInvest'
                                ? false
                                : !deposit?.autoInvest
                            }
                          />
                        }
                        label={
                          <>
                            Auto invest{' '}
                            {variant === 'dashboard' && (
                              <>
                                {setupRegularOrderToggle?.enabled ? (
                                  <SetupAutoInvestButton
                                    to={{
                                      pathname: generateAutoSaveInvestPathPath({
                                        wrapperType: getPathSegmentForWrapperType(
                                          account.wrapperType
                                        ),
                                      }),
                                      search: `?setUpAutoInvestForDeposit=${index}`,
                                    }}
                                    onClick={() => {
                                      trackGa({
                                        event: GaEventNames.selectContent,
                                        content_type: 'cta button',
                                        item_id: 'setup auto invest',
                                      });
                                    }}
                                    as={Link}
                                  >
                                    {deposit.autoInvest
                                      ? 'Manage auto invest'
                                      : 'Set up auto invest'}
                                  </SetupAutoInvestButton>
                                ) : (
                                  <SetupAutoInvestButton
                                    type="button"
                                    onClick={() => {
                                      trackGa({
                                        event: GaEventNames.selectContent,
                                        item_id: 'talk to support',
                                        reason: `regular deposit - edit form${
                                          deposit.autoInvest && '- auto invest'
                                        }`,
                                      });
                                      showNewMessages(
                                        deposit.autoInvest
                                          ? "I'd like to change my auto invest settings for my regular deposit"
                                          : "I'd like to set up auto invest for my regular deposit"
                                      );
                                    }}
                                    as="button"
                                  >
                                    {deposit.autoInvest
                                      ? 'Manage regular invest'
                                      : 'Set up regular invest'}
                                  </SetupAutoInvestButton>
                                )}
                              </>
                            )}
                          </>
                        }
                      />
                    )}
                  </StepFormContainer>
                </Section>
                {setRecurringDepositsMutation.error && (
                  <div>
                    <Alert severity={Severity.error}>
                      <Text $noMargin>Something went wrong</Text>
                    </Alert>
                  </div>
                )}
                {onDepositChange ? (
                  <ButtonContainer>
                    <StepButton
                      type="submit"
                      className="magenta"
                      disabled={setRecurringDepositsMutation.isLoading}
                    >
                      Continue
                    </StepButton>
                  </ButtonContainer>
                ) : (
                  <StepActions>
                    <ButtonWrapper>
                      <CustomButtonV2
                        $isWide
                        $size="small"
                        type="submit"
                        disabled={setRecurringDepositsMutation.isLoading}
                      >
                        Update
                      </CustomButtonV2>
                      <CustomButtonV2
                        $isWide
                        $size="small"
                        $color="richBlack"
                        disabled={setRecurringDepositsMutation.isLoading}
                        onClick={() => {
                          onComplete();
                        }}
                      >
                        Cancel
                      </CustomButtonV2>
                    </ButtonWrapper>
                  </StepActions>
                )}
              </div>
            );
          }}
        </QueryState>
      </form>
    </FormProvider>
  );
}
