import { yupResolver } from '@hookform/resolvers/yup';
import { QueryState } from 'components/QueryState';
import { StyledLink } from 'components/design-system/Link';
import {
  GoBackButton,
  SkipButton,
  StepActions,
  StepButton,
  StepContainer,
  StepIntroduction,
  StepIntroductionTypography,
  StepIntroductionWidth,
  StepTitle,
} from 'components/design-system/StepComponents/StepComponents';
import { GaEventNames, OnboardingStepNames } from 'constants/gaConstants';
import {
  useIllustrationQuery,
  useReferralCodeQuery,
  useUserProfileOnboardingQuery,
  useUserProfileQuery,
} from 'generated/graphql';
import { trackGa } from 'helpers/track';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useIntercom } from 'react-use-intercom';
import * as Yup from 'yup';
import { ConfirmReferral } from './ConfirmReferral';
import { StyledTextField } from './YourEmployerStep.styles';

function useInvalidateMutatedQueries() {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries(useUserProfileQuery.getKey());
    queryClient.invalidateQueries(useUserProfileOnboardingQuery.getKey());
  };
}

const YourEmployerFormSchema = Yup.object({
  myEmployerReferralCode: Yup.string()
    .max(12, 'Your referral code cannot be longer than 12 characters')
    .required('You must type in a valid referral code.'),
});

type YourEmployerFormValues = Yup.InferType<typeof YourEmployerFormSchema>;

interface YourEmployerStepProps {
  source?: 'openAccountStep';
  onProceed: (employerAdded?: boolean) => void;
  onGoBack: () => void;
}

interface YourEmployerStepFormProps extends YourEmployerStepProps {}

function YourEmployerStepForm({
  source,
  onProceed,
  onGoBack,
}: YourEmployerStepFormProps) {
  const { showNewMessages } = useIntercom();
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const invalidateQueries = useInvalidateMutatedQueries();

  const defaultValues: YourEmployerFormValues = {
    myEmployerReferralCode: '',
  };

  const methods = useForm<YourEmployerFormValues>({
    defaultValues,
    resolver: yupResolver(YourEmployerFormSchema),
  });

  const {
    handleSubmit,
    formState: { isDirty, isSubmitting },
    register,
    setError,
    clearErrors,
  } = methods;

  const {
    isLoading: referralDataLoading,
    data: referralData,
  } = useReferralCodeQuery(
    {
      code: referralCode!,
    },
    {
      enabled: !!referralCode,
      onSuccess: (data) => {
        if (!data.referralCode) {
          setReferralCode(null);
          setError('myEmployerReferralCode', {
            message:
              'This referral code is not valid, please try another code or contact our support team.',
          });
        } else {
          clearErrors('myEmployerReferralCode');
        }
      },
    }
  );

  const onSubmit: SubmitHandler<YourEmployerFormValues> = async (data) => {
    try {
      if (isDirty) {
        const code = data.myEmployerReferralCode;
        const pattern = /^[a-zA-Z0-9-]+$/;

        if (!pattern.test(code)) {
          setError('myEmployerReferralCode', {
            type: 'manual',
            message:
              'Your referral code can only contain alphanumeric characters.',
          });
          return;
        }

        if (code !== referralCode) {
          clearErrors('myEmployerReferralCode');
          setReferralCode(code);
        }
      }
    } catch (e) {
      // intentionally empty
    }
  };

  const handleSkip = () => {
    onProceed();
  };

  const handleCodeSuccess = (employerName?: string) => {
    if (source === 'openAccountStep') {
      trackGa({
        event: GaEventNames.onboarding,
        onboardingStep: OnboardingStepNames.yourEmployerPension,
      });
    }
    invalidateQueries();
    onProceed(!!employerName);
  };

  const handleSupport = () => {
    showNewMessages('I need help linking my TILLIT account to my employer.');
    trackGa({
      event: GaEventNames.selectContent,
      content_type: 'talk to support',
      item_id: `Pension onboarding  - cannot link employer`,
    });
  };

  const handleBackToReferral = () => {
    setReferralCode(null);
  };

  return (
    <>
      {referralData && referralCode ? (
        <ConfirmReferral
          referralCode={referralCode}
          onProceed={handleCodeSuccess}
          onGoBack={handleBackToReferral}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...methods}>
            <StepContainer>
              <StepTitle>Your employer</StepTitle>
              <>
                <StepIntroduction $width={StepIntroductionWidth.extraWide}>
                  <StepIntroductionTypography>
                    Are you expecting contribution from your employer? If we we
                    need to know who they are - enter code below.
                  </StepIntroductionTypography>
                  <StepIntroductionTypography>
                    Don't have a code? Your employer not setup with TILLIT? We'd
                    be happy to be introduced to them,{' '}
                    <StyledLink onClick={handleSupport}>
                      chat with us
                    </StyledLink>
                    .
                  </StepIntroductionTypography>
                </StepIntroduction>

                <StyledTextField
                  name="myEmployerReferralCode"
                  id="employerReferralCode"
                  label="Employer referral code"
                  register={register}
                  $fullWidth
                />
              </>

              <StepActions>
                <StepButton
                  type="submit"
                  className="magenta"
                  disabled={isSubmitting || referralDataLoading}
                >
                  Continue
                </StepButton>
                <SkipButton
                  type="button"
                  className="richBlue"
                  variant="outlined"
                  onClick={handleSkip}
                  disabled={isSubmitting || referralDataLoading}
                >
                  Skip
                </SkipButton>
                <GoBackButton
                  onClick={onGoBack}
                  disabled={isSubmitting || referralDataLoading}
                />
              </StepActions>
            </StepContainer>
          </FormProvider>
        </form>
      )}
    </>
  );
}

export function YourEmployerStep(props: YourEmployerStepProps) {
  const illustrationQuery = useIllustrationQuery();
  const monthlyEmployerContribution =
    illustrationQuery.data?.illustration?.monthlyEmployerContribution?.amount;
  const monthlyEmployeeViaPayrollContribution =
    illustrationQuery.data?.illustration?.monthlyEmployeeViaPayrollContribution
      ?.amount;

  useEffect(() => {
    const hasEmployerContributions =
      monthlyEmployerContribution !== 0 &&
      monthlyEmployeeViaPayrollContribution !== 0;
    if (!hasEmployerContributions) props.onProceed();
  }, [
    monthlyEmployeeViaPayrollContribution,
    monthlyEmployerContribution,
    props,
  ]);

  return (
    <QueryState {...illustrationQuery}>
      {() => <YourEmployerStepForm {...props} />}
    </QueryState>
  );
}
